export const GET_STOCK_FILTER = 'stock/GET_STOCK_FILTER';
export const MUTATE_DATA_STOCK = 'stock/MUTATE_DATA_STOCK';
export const ACTION_DATA_STOCK = 'stock/ACTION_DATA_STOCK';
export const DOWNLOAD_EXCEL_STOCK = 'stock/DOWNLOAD_EXCEL_STOCK';
export const MUTATION_LINK = 'stock/MUTATION_LINK';
export const GET_LINK = 'stock/GET_LINK';
export const PATCH_EMPLOYEE_COVERAGE_COLABORATOR =
  'stock/PATCH_EMPLOYEE_COVERAGE_COLABORATOR';
export const DELETE_EMPLOYEE_COVERAGE = 'stock/DELETE_EMPLOYEE_COVERAGE';
export const PATCH_EMPLOYEE_DEPENDENT = 'stock/PATCH_EMPLOYEE_DEPENDENT';
export const DELETE_EMPLOYEE_LOAD = 'stock/DELETE_EMPLOYEE_LOAD';
export const PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE =
  'stock/PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE';
export const MUTATE_EMPLOYEE_COVERAGE = 'stock/MUTATE_EMPLOYEE_COVERAGE';
export const GET_EMPLOYEE_COVERAGE = 'stock/GET_EMPLOYEE_COVERAGE';
export const FETCH_CURRENT_EMPLOYEE_COVERAGE =
  'stock/FETCH_CURRENT_EMPLOYEE_COVERAGE';
export const MUTATE_CURRENT_EMPLOYEE_COVERAGE =
  'stock/MUTATE_CURRENT_EMPLOYEE_COVERAGE';
export const ACTION_CURRENT_EMPLOYEE_COVERAGE =
  'stock/ACTION_CURRENT_EMPLOYEE_COVERAGE';
export const GET_CURRENT_EMPLOYEE_COVERAGE =
  'stock/GET_CURRENT_EMPLOYEE_COVERAGE';

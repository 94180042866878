import {
  getFileDependentsIncorporationFormat,
} from '../../../../network';

import {
  SET_FILE_DEPENDENTS_INCORPORATION_URL,
  FETCH_FILE_DEPENDENTS_INCORPORATION_URL,
  GET_FILE_DEPENDENTS_INCORPORATION_URL

} from './types';

const store = {};

const getDefaultState = () => ({
  tickets: [],
  altaIndividual: [],
  companies: [],
  insurances: [],
  docs: [],
  form: {},
  url: '',
  ticketDetail: {},
  ticketDetailFile: {},
  dependentsUrl: ''
});

store.state = getDefaultState();

store.getters = {
  [GET_FILE_DEPENDENTS_INCORPORATION_URL]: (state) => state.dependentsUrl,
};

store.mutations = {
  [SET_FILE_DEPENDENTS_INCORPORATION_URL](state, payload) {
    state.dependentsUrl = payload;
  },
};

store.actions = {
  async [FETCH_FILE_DEPENDENTS_INCORPORATION_URL]({ commit }, payload) {
    console.log('Se ha llamado a ejecutar FETCH_FILE_DEPENDENTS_INCORPORATION_URL con payload: ', payload);
    const response = await getFileDependentsIncorporationFormat(
      payload.companyId,
      payload.insuranceCompanyId
    );
    console.log(SET_FILE_DEPENDENTS_INCORPORATION_URL, response);
    commit(SET_FILE_DEPENDENTS_INCORPORATION_URL, response);
  },
};

export default store;
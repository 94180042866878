<template>
  <page-header title="Ticket alta masiva" :breadcrumRoutes="routes">
    <template #tags>
      <tags color="processing"> ID: {{ ticketId }} </tags>
    </template>

    <template #buttons v-if="showcancelAndEjecutar">
      <a-button :loading="loading" :disabled="loading" @click="handlePreCancel"
        >Cancelar</a-button
      >
      <a-button
        :loading="loading"
        :disabled="loading"
        type="primary"
        class="ml-2"
        @click="handlePreSuccess"
        >Ejecutar</a-button
      >
    </template>
  </page-header>

  <lista-tickets-masivos
    title="Listado de altas"
    defaultRedirect="detalle_alta"
    :data="altasData"
    :loading="loadingTicketMasivo"
  />

  <a-card class="mt-4">
    <a-row :gutter="16">
      <a-col :span="12">
        <datos-ticket
          :data="tableData"
          title="Datos ticket"
          :loadTicket="loadTicket"
        />
        <mensaje-aseguradora :message="insuranceMessage" />
      </a-col>

      <a-col :span="12">
        <documentos-adjuntos
          title="Documentos adjuntos"
          :data="attachedFiles"
          :loading="loadingDocument"
        />

        <comments
          :comments="comments"
          ticketType="masive"
          :idToTicket="ticketId"
          @new_comment="getNewCommment"
          class="mt-3"
        />
      </a-col>
    </a-row>
  </a-card>

  <a-modal
    v-model:visible="visible"
    :title="modalStyle.title"
    @ok="handleOk"
    centered
    :maskClosable="false"
  >
    <a-typography-title class="text-gray-4 fw-regular mb-4" :level="4">
      {{ modalStyle.text }}
    </a-typography-title>
    <a-row :gutter="16" align="middle">
      <a-col :span="3" class="mr-2">
        <info-circle-outlined
          v-if="modalStyle.contentText !== 'Sin casos pendientes'"
          :style="{ fontSize: '21px', color: '#FAAD14' }"
        />
        <check-circle-outlined
          v-if="modalStyle.contentText === 'Sin casos pendientes'"
          :style="{ fontSize: '21px', color: '#00DC92' }"
        />
      </a-col>
      <a-col :span="20">
        <a-typography-title class="text-gray-4 fw-regular ml-2" :level="5">
          {{ modalStyle.contentText }}
        </a-typography-title>
      </a-col>
    </a-row>
    <template #footer>
      <a-row type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button @click="handleOk">Volver atrás</a-button>
            <a-button
              v-if="modalStyle.mode === 'cancelTicket'"
              key="submit"
              type="danger"
              :loading="loading"
              @click="handleCancel"
            >
              Sí, cancelar
            </a-button>
            <a-button
              v-if="modalStyle.mode === 'successTicket'"
              key="submit"
              type="primary"
              :loading="loading"
              @click="handleSuccess"
            >
              Confirmar
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
  </a-modal>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';
// Store
import {
  GET_AM_DETAIL,
  FETCH_AM_DETAIL,
  GET_AM_DETAIL_FILE,
  FETCH_AM_DETAIL_FILE,
  PRECANCEL_ALTA_MASIVA,
  PRESUCCESS_ALTA_MASIVA,
  SUCCESS_ALTA_MASIVA,
  CANCEL_ALTA_MASIVA,
  FETCH_MASIVE_COMMENTS,
  GET_LOGGED_USER,
  FETCH_USER_BY_ID
} from '@/store/types';
// Components
import Tags from '@/components/Tags.vue';
import PageHeader from '@/components/PageHeader.vue';
import DatosTicket from '@/components/Tickets/TicketData.vue';
import MensajeAseguradora from '@/components/Tickets/MensajeAseguradora.vue';
import DocumentosAdjuntos from '@/components/Tickets/DocumentosAdjuntos.vue';
import ListaTicketsMasivos from '@/components/Tickets/ListaTicketsMasivos.vue';
import Comments from '@/components/Tickets/Comments.vue';
// Utils
import openNotificationWithIcon from '@/utils/notifications';
import parseDate from '@/utils/dateFormatter';
import rutHelper from '@/utils/Ruts';

export default {
  name: 'AltaMasiva',
  components: {
    'datos-ticket': DatosTicket,
    'mensaje-aseguradora': MensajeAseguradora,
    'documentos-adjuntos': DocumentosAdjuntos,
    'lista-tickets-masivos': ListaTicketsMasivos,
    'info-circle-outlined': InfoCircleOutlined,
    'check-circle-outlined': CheckCircleOutlined,
    'page-header': PageHeader,
    tags: Tags,
    comments: Comments
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const ticketId = ref(route.params.id);
    // const broker_id = store.getters[GET_LOGGED_USER].id;

    // * Rutas
    const routes = ref([
      {
        path: '/altas',
        breadcrumbName: 'Altas'
      },
      {
        path: '#',
        breadcrumbName: 'Masiva'
      },
      {
        breadcrumbName: `#${ticketId.value}`
      }
    ]);

    //* Get Data
    const ticketData = ref({});
    const loadTicket = ref(false);
    const loadingTicketMasivo = ref(false);
    const router = useRouter();
    const loadingDocument = ref(false);
    const getTicketData = async () => {
      loadTicket.value = true;
      loadingTicketMasivo.value = true;
      await store
        .dispatch(FETCH_AM_DETAIL, ticketId.value)
        .catch(() => router.push({ name: 'Missing' }));
      ticketData.value = await store.getters[GET_AM_DETAIL];
      loadTicket.value = false;
      loadingTicketMasivo.value = false;
    };
    getTicketData().then(() => {
      fillTableData();
      getBroker();
      fillIndividualTable();
    });

    const attachedFiles = ref([]);
    const getAttachedFiles = async () => {
      loadingDocument.value = true;
      try {
        await store
          .dispatch(FETCH_AM_DETAIL_FILE, ticketId.value)
          .catch((err) =>
            openNotificationWithIcon(
              'error',
              'Error',
              `Error al cargar archivo del Ticket. ${err.response.data.message}`
            )
          );
        attachedFiles.value = await store.getters[GET_AM_DETAIL_FILE];
        loadingDocument.value = false;
      } catch (error) {
        console.log('error', error);
        loadingDocument.value = false;
      }
    };
    getAttachedFiles();

    const tableData = reactive([
      { key: 'Broker', value: '' },
      { key: 'Aseguradora', value: '' },
      { key: 'Holding', value: '' },
      { key: 'Subholding', value: '' },
      { key: 'Empresa', value: '' },
      { key: 'RUT Empresa', value: '' }
    ]);
    const insuranceMessage = ref('');

    const fillTableData = () => {
      tableData[1].value = ticketData.value.object.insuranceCompany;
      tableData[2].value = ticketData.value.object.holding;
      tableData[3].value = ticketData.value.object.subHolding;
      tableData[4].value = ticketData.value.object.company;
      tableData[5].value = rutHelper.formatRut(
        ticketData.value.object.rutCompany
      );

      insuranceMessage.value = ticketData.value.message;
    };
    const getBroker = async () => {
      // const response = await getOneUserById(ticketData.value.object.brokerId);
      const response = await store.dispatch(
        FETCH_USER_BY_ID,
        ticketData.value.object.brokerId
      );
      tableData[0].value = response.data.body.name;
    };

    const showcancelAndEjecutar = computed(() =>
      altasData.value.some((d) => {
        if (payloadObject.value !== null) {
          if (d.ticketState == 3 || d.ticketState == 4) {
            return false;
          }
          loading.value = false;
          return true;
        }
      })
    );

    const altasData = ref([]);
    const fillIndividualTable = () => {
      altasData.value = ticketData.value.ticketGroups.map((a) => {
        return {
          key: a.id,
          id: a.id,
          group: a.groupCompanies.map((g) => g.name).join(', '),
          employeeName: `${a.employee.firstName} ${a.employee.firstSurname}`,
          employeeRut: rutHelper.formatRut(a.employee.rut),
          ticketState: a.states[0].state.id,
          pendingCases: a.cases === 0 ? 'No' : 'Sí'
        };
      });
    };

    const payloadObject = ref({});
    const loading = ref(false);
    const visible = ref(false);
    const modalStyle = reactive({
      title: '',
      text: '',
      contentText: '',
      mode: ''
    });

    const showModal = () => {
      visible.value = true;
      loading.value = false;
    };

    const handleOk = () => {
      visible.value = false;
      loading.value = false;
    };

    const handlePreCancel = async () => {
      let cantTickets = null;
      let casesCount = 0;
      await store
        .dispatch(PRECANCEL_ALTA_MASIVA, ticketId.value)
        .then((res) => {
          cantTickets = res.ticketCancels.length;
          if (cantTickets === 0) {
            openNotificationWithIcon(
              'error',
              'Error',
              'No se ha podido cargar la información para cancelar el ticket. No existe ningún ticket para cancelar'
            );
          } else {
            res.ticketCancels.map((a) => {
              if (a.case.length > 0) {
                casesCount++;
              }
            });
            modalStyle.mode = 'cancelTicket';
            modalStyle.title = 'Cancelar ticket de alta masiva';
            modalStyle.text = `¿Estás seguro de que deseas cancelar ${cantTickets} tickets de alta masiva?`;
            if (cantTickets === 1) {
              modalStyle.text = `¿Estás seguro de que deseas cancelar ${cantTickets} ticket de alta masiva?`;
            }
            modalStyle.contentText =
              casesCount > 0
                ? 'Tickets no ejecutados y casos pendientes asociados pasarán a cancelados'
                : 'Sin casos pendientes';
            payloadObject.value = { objectReturn: res };
            showModal();
          }
        })
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `No se ha podido cargar la información para cancelar el ticket. ${err.response.data.message}.`
          )
        );
    };

    const handleCancel = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(CANCEL_ALTA_MASIVA, {
            idAlta: ticketId.value,
            payload: payloadObject.value
          })
          .then(() => {
            openNotificationWithIcon(
              'success',
              'Correcto',
              `Ticket ${ticketId.value} cancelado exitosamente`
            );
            getTicketData().then(() => {
              fillTableData();
              getBroker();
              fillIndividualTable();
            });
            loading.value = false;
            visible.value = false;
          });
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido cancelar el ticket ${ticketId.value}. ${error.response.data.message}`
        );
      }
    };

    const handlePreSuccess = async () => {
      let cantTickets = null;
      let pendingCount = 0;
      await store
        .dispatch(PRESUCCESS_ALTA_MASIVA, ticketId.value)
        .then((res) => {
          cantTickets = res.ticketSuccess.length;
          if (cantTickets === 0) {
            openNotificationWithIcon(
              'error',
              'Error',
              'No se ha podido cargar la información para ejecutar el ticket. No existe ningún ticket para ejecutar.'
            );
          } else {
            res.ticketPending.map(() => {
              pendingCount++;
            });
            modalStyle.mode = 'successTicket';
            modalStyle.title = 'Ejecutar ticket de alta masiva';
            modalStyle.text = `¿Estás seguro de que deseas ejecutar ${cantTickets} tickets de alta masiva?`;
            if (cantTickets === 1) {
              modalStyle.text = `¿Estás seguro de que deseas ejecutar ${cantTickets} ticket de alta masiva?`;
            }
            modalStyle.contentText =
              pendingCount > 0
                ? `No se están considerando ${pendingCount} tickets pendientes con casos asociados.`
                : 'Sin casos pendientes';
            if (pendingCount === 1) {
              modalStyle.contentText = `No se está considerando ${pendingCount} ticket pendiente con casos asociados.`;
            }
            payloadObject.value = { result: res };
            showModal();
          }
        })
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `No se ha podido cargar la información para ejecutar el ticket. ${err.response.data.message}.`
          )
        );
    };

    const handleSuccess = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(SUCCESS_ALTA_MASIVA, {
            idAlta: ticketId.value,
            payload: payloadObject.value
          })
          .then(() => {
            openNotificationWithIcon(
              'success',
              'Correcto',
              `Ticket ${ticketId.value} ejecutado exitosamente`
            );
            getTicketData().then(() => {
              fillTableData();
              getBroker();
              fillIndividualTable();
            });
            loading.value = false;
            visible.value = false;
          });
      } catch (error) {
        loading.value = false;
        openNotificationWithIcon(
          'error',
          'Error',
          `No se ha podido ejecutar el ticket ${ticketId.value}. ${error.response.data.message}`
        );
      }
    };

    // * Comentarios
    // rol de  usuario
    const user_role = store.getters[GET_LOGGED_USER].roleName;

    // Obtencion de comentarios

    const comments = ref([]);
    const getComments = async () => {
      comments.value = [];
      const resp = await store.dispatch(FETCH_MASIVE_COMMENTS, ticketId.value);
      for (let i = 0; i < resp.length; i++) {
        const element = resp[i];
        const broker_name = await getBrokerName(element.brokerId);
        comments.value.push({
          description: parseDate(element.createdAt),
          title: element.message,
          author: broker_name
        });
      }
    };

    const getBrokerName = async (id) => {
      const response = await store.dispatch(FETCH_USER_BY_ID, id);
      return response.data.body.name;
    };

    getComments();

    const getNewCommment = async (value) => {
      const { description } = value;
      const { brokerId } = value;
      const brokerName = await getBrokerName(brokerId);

      comments.value.push({
        title: value.title,
        description: parseDate(description),
        author: brokerName
      });
    };

    return {
      // Variables
      loading,
      visible,
      modalStyle,
      altasData,
      routes,
      ticketId,
      tableData,
      insuranceMessage,
      attachedFiles,
      ticketData,
      comments,
      user_role,
      // Methods
      showcancelAndEjecutar,
      handlePreCancel,
      handleCancel,
      handlePreSuccess,
      handleSuccess,
      handleOk,
      getNewCommment,
      loadTicket,
      loadingTicketMasivo,
      loadingDocument
    };
  }
};
</script>

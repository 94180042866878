import {
  excelEmployeeCompany,
  patchEmployeeCoverage,
  deleteEmployeeCoverage,
  updateEmployeeDependent,
  deleteEmployeeLoads,
  patchEmployeeCoverageRequestDate,
  currentEmployeePoliciesAndCoverages
} from '../../../network';
import {
  GET_STOCK_FILTER,
  MUTATE_DATA_STOCK,
  ACTION_DATA_STOCK,
  DOWNLOAD_EXCEL_STOCK,
  MUTATION_LINK,
  GET_LINK,
  PATCH_EMPLOYEE_COVERAGE_COLABORATOR,
  PATCH_EMPLOYEE_DEPENDENT,
  DELETE_EMPLOYEE_COVERAGE,
  DELETE_EMPLOYEE_LOAD,
  PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE,
  MUTATE_EMPLOYEE_COVERAGE,
  GET_EMPLOYEE_COVERAGE,
  FETCH_CURRENT_EMPLOYEE_COVERAGE,
  MUTATE_CURRENT_EMPLOYEE_COVERAGE,
  GET_CURRENT_EMPLOYEE_COVERAGE,
  ACTION_CURRENT_EMPLOYEE_COVERAGE
} from './types';

const store = {};

// * STATE
store.state = {
  dataStock: [],
  link: '',
  employee_coverage: {},
  current_employee_coverages: []
};

// * GETTERS
store.getters = {
  [GET_STOCK_FILTER]: (state) => state.dataStock,
  [GET_LINK]: (state) => state.link,
  [GET_EMPLOYEE_COVERAGE]: (state) => state.employee_coverage,
  [GET_CURRENT_EMPLOYEE_COVERAGE]: (state) => state.current_employee_coverages
};

// * MUTATIONS
store.mutations = {
  [MUTATE_DATA_STOCK](state, payload) {
    state.dataStock = payload;
  },
  [MUTATION_LINK](state, payload) {
    state.link = payload;
  },
  [MUTATE_EMPLOYEE_COVERAGE](state, payload) {
    state.employee_coverage = payload;
  },
  [MUTATE_CURRENT_EMPLOYEE_COVERAGE](state, payload) {
    state.current_employee_coverages = payload;
  }
};

// * ACTIONS
store.actions = {
  [ACTION_DATA_STOCK]({ commit }, payload) {
    commit(MUTATE_DATA_STOCK, payload);
  },
  async [DOWNLOAD_EXCEL_STOCK]({ commit }, payload) {
    const data = excelEmployeeCompany(payload);
    commit(MUTATION_LINK, data);
  },
  [PATCH_EMPLOYEE_COVERAGE_COLABORATOR](_, { idEmployeeCoverage, payload }) {
    return new Promise((resolve, reject) => {
      patchEmployeeCoverage(idEmployeeCoverage, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [DELETE_EMPLOYEE_COVERAGE](_, idEmployeeCoverage) {
    return new Promise((resolve, reject) => {
      deleteEmployeeCoverage(idEmployeeCoverage)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_EMPLOYEE_DEPENDENT](_, { employeeId, dependentId, payload }) {
    return new Promise((resolve, reject) => {
      updateEmployeeDependent(employeeId, dependentId, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async [DELETE_EMPLOYEE_LOAD](_, { employeeId, dependentId }) {
    return new Promise((resolve, reject) => {
      deleteEmployeeLoads(employeeId, dependentId)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PATCH_EMPLOYEE_COVERAGE_REQUEST_DATE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      patchEmployeeCoverageRequestDate(id, payload)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_CURRENT_EMPLOYEE_COVERAGE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const {
        id,
        companyId,
        insuranceCompanyId,
        policyStatus,
        coverageStatus
      } = payload;
      currentEmployeePoliciesAndCoverages(
        id,
        companyId,
        insuranceCompanyId,
        policyStatus,
        coverageStatus
      )
        .then((resp) => {
          commit(MUTATE_CURRENT_EMPLOYEE_COVERAGE, resp.data);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ACTION_CURRENT_EMPLOYEE_COVERAGE]({ commit }, payload) {
    commit(MUTATE_CURRENT_EMPLOYEE_COVERAGE, payload);
  }
};

export default store;

<template>
  <div>
    <PageHeader
      :breadcrumRoutes="routes"
      title="Formulario de traspaso de póliza"
    />

    <a-form
      name="form"
      layout="vertical"
      ref="form_ref"
      :model="form_data"
      :rules="form_rules"
    >
      <a-row :gutter="12">
        <a-col :span="12">
          <a-card>
            <a-row type="flex">
              <a-form-item label="Envío de información a">
                <a-radio-group value="1">
                  <a-radio value="1">Compañía</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <a-card>
            <a-form-item required name="startDate" label="Inicio de traspaso">
              <a-date-picker
                placeholder="DD-MM-AAAA"
                v-model:value="form_data.startDate"
                :format="format_date"
              />
            </a-form-item>
          </a-card>
        </a-col>
      </a-row>

      <a-card class="mt-2">
        <a-typography-title class="text-gray-8" :level="4">
          Datos póliza de origen
        </a-typography-title>
        <a-row :gutter="16" type="flex">
          <a-col :span="5">
            <a-form-item ref="rut" label="RUT" name="rut" has-feedback>
              <a-input
                @change="(e) => onSelectChange(e, 'rut')"
                placeholder="RUT"
                maxLength="12"
                v-model:value="form_data.rut"
                @keyup="formatRuts"
                allowClear
              />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originCompany"
              label="Empresa"
              name="originCompany"
              has-feedback
            >
              <a-select
                @change="(e) => onSelectChange(e, 'originCompany')"
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.originCompany"
                :options="origin_company_option"
                :disabled="disableCompanyInput"
                :loading="company_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item
              ref="originInsuranceCompanyId"
              label="Aseguradora"
              name="originInsuranceCompanyId"
              has-feedback
            >
              <a-select
                @change="(e) => onSelectChange(e, 'originInsuranceCompanyId')"
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.originInsuranceCompanyId"
                :options="origin_insurance_options"
                :loading="insurance_loading"
                :disabled="disableCompanyInput || insurance_loading"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="9">
            <a-form-item
              label="Pólizas"
              name="currentOriginPolicies"
              has-feedback
            >
              <a-select
                :disabled="disablePolicyInput || policies_loading"
                :loading="policies_loading"
                v-model:value="form_data.currentOriginPolicies"
                :options="origin_policy_number_options"
                mode="multiple"
                @change="handleAddOriginPolicy"
                allowClear
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row
          v-for="(policy, index) in form_data.originPolicies"
          :key="index"
          align="middle"
          :gutter="16"
        >
          <a-col :span="8">
            <a-form-item
              :name="['originPolicies', index, 'groupCompanies']"
              :label="`Grupos de póliza ${policy.numberPolicy}`"
              style="display: flex"
            >
              <a-select
                v-model:value="policy.groupCompanies"
                :options="policy.groupOptions"
                mode="multiple"
                allowClear
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-button danger @click="handleDeleteOriginPolicy(policy.policyId)">
              <template #icon>
                <DeleteOutlined />
              </template>
            </a-button>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Datos póliza de destino
        </a-typography-title>
        <a-row :gutter="16" type="flex">
          <a-col :span="6">
            <a-form-item
              ref="newCompany"
              label="Empresa"
              name="newCompany"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                v-model:value="form_data.newCompany"
                :options="destiny_company_options"
                @change="(e) => onSelectChange(e, 'newCompany')"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item
              ref="newInsuranceCompanyId"
              label="Aseguradora"
              name="newInsuranceCompanyId"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                allowClear
                :loading="destiny_insurance_loading"
                v-model:value="form_data.newInsuranceCompanyId"
                :options="destiny_insurance_options"
                @change="(e) => onSelectChange(e, 'newInsuranceCompanyId')"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              ref="currentNewPolicies"
              label="Pólizas"
              name="currentNewPolicies"
              has-feedback
            >
              <a-select
                placeholder="Seleccionar"
                show-search
                optionFilterProp="label"
                mode="multiple"
                allowClear
                v-model:value="form_data.currentNewPolicies"
                :options="destiny_policy_number_options"
                :disabled="disableCurrentNewPolicies || destiny_policy_loading"
                :loading="destiny_policy_loading"
                @change="handleAddNewPolicy"
              >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row
          v-for="(policy, i) in form_data.newPolicies"
          :key="i"
          align="middle"
          :gutter="16"
        >
          <a-col :span="24">
            <a-typography-title class="text-gray-8" :level="5">
              Coberturas de póliza {{ policy.numberPolicy }}
            </a-typography-title>
          </a-col>
          <a-col v-for="(group, j) in policy.groupCompanies" :key="j" :span="8">
            <a-form-item
              :name="['newPolicies', i, 'groupCompanies', j]"
              :label="`Grupo ${group.name}`"
              style="display: flex"
            >
              <a-select
                v-model:value="policy.groupCompanies[j].selectedCoverages"
                :options="policy.groupCompanies[j].coverages"
                mode="multiple"
              >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col>
            <a-button danger @click="handleDeleteNewPolicy(policy.id)"
              ><template #icon>
                <DeleteOutlined /> </template
            ></a-button>
          </a-col>
        </a-row>
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <a-form-item name="optional_files" label="Archivos opcionales">
              <a-upload
                :file-list="form_data.optional_files"
                :multiple="true"
                :remove="handleRemoveOptional"
                :before-upload="beforeUploadOptional"
                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
              >
                <a-button>
                  <upload-outlined></upload-outlined>
                  Subir archivo
                </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-row type="flex" class="justify-content-end mt-3">
        <a-col>
          <a-button
            size="large"
            class="px-4"
            type="primary"
            @click="formSubmit"
            block
          >
            Siguiente
          </a-button>
        </a-col>
      </a-row>
    </a-form>

    <TransferCreationResumeModal
      ref="resume_modal_ref"
      :loading="resume_modal_loading"
      @createTransfer="createTransfer"
    />

    <TransferCreationResumeSuccessModal ref="resume_success_modal_ref" />
  </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import {
  POST_TICKET_TRANSFER,
  FETCH_COMPANIES,
  FETCH_EMPLOYEE_DATA_BY_RUT,
  FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT,
  FETCH_COMPANY_POLICIES,
  FETCH_INSURANCES,
  FETCH_POLICIES_COVERAGES,
  FETCH_COMPANY_BY_RUT,
  GET_LOGGED_USER
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import notifications from '@/utils/notifications';
import TransferCreationResumeModal from './TransferCreationResumeModal.vue';
import TransferCreationResumeSuccessModal from './TransferCreationResumeSuccessModal.vue';
import PageHeader from '@/components/PageHeader.vue';
import { DeleteOutlined } from '@ant-design/icons-vue';

const routes = [
  {
    path: '/traspasos',
    breadcrumbName: 'Traspasos de póliza'
  },
  {
    breadcrumbName: 'Formulario'
  }
];

const store = useStore();
const brokerId = store.getters[GET_LOGGED_USER].id;
const employee_name = ref('');
const form_data = reactive({
  brokerId,
  startDate: null,
  rut: null,
  originCompany: null,
  originInsuranceCompanyId: null,
  currentOriginPolicies: [],
  originPolicies: [],
  newCompany: null,
  newInsuranceCompanyId: null,
  currentNewPolicies: [],
  newPolicies: [],
  optional_files: []
});
const checkRut = async (_, value) => {
  if (!value) {
    const error_message = 'Ingrese RUT';
    return Promise.reject(error_message);
  }
  if (rutHelper.validateRut(value)) {
    return Promise.resolve();
  }
  return Promise.reject('RUT no válido');
};

const handleAddOriginPolicy = () => {
  form_data.currentOriginPolicies.forEach((policyId) => {
    const policy = origin_policy_number_options.value.find(
      (p) => p.value == policyId
    );
    const foundPolicy = form_data.originPolicies.find(
      (p) => p.policyId == policyId
    );
    if (!foundPolicy) {
      const newPolicy = {
        numberPolicy: policy.label,
        policyId,
        groupCompanies: [],
        groupOptions: policy.groupOptions
      };
      form_data.originPolicies = [...form_data.originPolicies, newPolicy];
    }
  });
  form_data.originPolicies = form_data.originPolicies.filter((p) =>
    form_data.currentOriginPolicies.includes(p.policyId)
  );
};

const handleDeleteOriginPolicy = (policyId) => {
  form_data.originPolicies = form_data.originPolicies.filter(
    (p) => p.policyId !== policyId
  );
  form_data.currentOriginPolicies = form_data.currentOriginPolicies.filter(
    (p) => p !== policyId
  );
};

const handleAddNewPolicy = async () => {
  destiny_coverages.value = true;
  try {
    for (let i = 0; i < form_data.currentNewPolicies.length; i += 1) {
      const policyId = form_data.currentNewPolicies[i];
      const resp = await store.dispatch(
        FETCH_POLICIES_COVERAGES,
        `?policies=${policyId}`
      );
      const groupedObj = resp.reduce((acc, curr) => {
        // eslint-disable-next-line no-unused-vars
        const { policies, coverageGroupCompanies, ...onlyCoverage } = curr;
        coverageGroupCompanies.forEach((cgc) => {
          const policy = cgc.policyGroupCompany.policies;
          const groupCompany = cgc.policyGroupCompany.groupCompanies;
          acc[policy.id] = acc[policy.id] || {
            ...policy,
            groupCompanies: {}
          };
          acc[policy.id].groupCompanies[groupCompany.id] = acc[policy.id]
            .groupCompanies[groupCompany.id] || {
            ...groupCompany,
            coverages: {}
          };
          acc[policy.id].groupCompanies[groupCompany.id].coverages[cgc.id] =
            acc[policy.id].groupCompanies[groupCompany.id].coverages[
              cgc.id
            ] || {
              ...onlyCoverage,
              label: onlyCoverage.name,
              value: cgc.id,
              disabled: onlyCoverage.required,
              coverageGroupCompanyId: cgc.id
            };
        });
        return acc;
      }, {});
      const groupedArr = Object.values(groupedObj);
      for (let p of groupedArr) {
        p.groupCompanies = Object.values(p.groupCompanies);
        for (let g of p.groupCompanies) {
          g.coverages = Object.values(g.coverages);
          g.selectedCoverages = g.coverages
            .filter((c) => c.required)
            .map((c) => c.coverageGroupCompanyId);
        }
      }
      const policy = groupedArr.find((p) => p.id == policyId);
      const foundPolicy = form_data.newPolicies.find((p) => p?.id == policyId);
      if (!foundPolicy) {
        form_data.newPolicies = [...form_data.newPolicies, policy];
      }
    }
    form_data.newPolicies = form_data.newPolicies.filter((p) =>
      form_data.currentNewPolicies.includes(String(p?.id))
    );
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las coberturas.');
  }
};

const handleDeleteNewPolicy = (policyId) => {
  form_data.newPolicies = form_data.newPolicies.filter((p) => p.id != policyId);
  form_data.currentNewPolicies = form_data.currentNewPolicies.filter(
    (p) => p != policyId
  );
};

const checkOriginPolicies = async () => {
  if (form_data.currentOriginPolicies.length > 0) {
    return Promise.resolve();
  }
  return Promise.reject('Añadir al menos una póliza');
};

const checkNewPolicies = async () => {
  if (form_data.currentNewPolicies.length > 0) {
    return Promise.resolve();
  }
  return Promise.reject('Añadir al menos una póliza');
};

const form_rules = {
  startDate: [
    {
      required: true,
      message: 'Seleccione una fecha',
      trigger: 'change',
      type: 'object'
    }
  ],
  rut: [
    {
      required: true,
      validator: checkRut,
      trigger: 'change'
    }
  ],
  originCompany: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  originInsuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  currentOriginPolicies: [
    {
      required: true,
      validator: checkOriginPolicies,
      trigger: 'change'
    }
  ],
  originPolicies: [
    {
      type: 'array',
      required: true,
      message: 'Seleccione al menos una póliza',
      trigger: 'change'
    }
  ],
  newCompany: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  newInsuranceCompanyId: [
    {
      required: true,
      message: 'Seleccione una opción',
      trigger: 'change',
      type: 'number'
    }
  ],
  currentNewPolicies: [
    {
      required: true,
      validator: checkNewPolicies,
      trigger: 'change'
    }
  ],
  newPolicies: [
    {
      required: true,
      message: 'Seleccione al menos una póliza',
      trigger: 'change',
      type: 'array'
    }
  ]
};
const form_ref = ref();
const format_date = 'DD-MM-YYYY';
const origin_company_option = ref([]);
const origin_insurance_options = ref([]);
const origin_policy_number_options = ref([]);
const destiny_company_options = ref([]);
const destiny_insurance_options = ref([]);
const destiny_policy_number_options = ref([]);
const coverages_options = ref([]);

const handleRemoveOptional = (file) => {
  const index = form_data.optional_files.indexOf(file);
  const newFileList = form_data.optional_files.slice();
  newFileList.splice(index, 1);
  form_data.optional_files = newFileList;
};

const beforeUploadOptional = (file) => {
  if (form_data.optional_files.length < 5) {
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      notifications('error', 'Error', 'El archivo excede el peso máximo 5MB');
    } else {
      form_data.optional_files = [...form_data.optional_files, file];
    }
  } else {
    notifications('error', 'Error', 'Máximo archivos a subir es 5');
  }
  return false;
};

const formatRuts = () => {
  form_data.rut = rutHelper.formatRut(form_data.rut);
};

const formSubmit = () => {
  form_ref.value
    .validate()
    .then((resp) => {
      openResumeModal({
        requestStart: resp.startDate.format('DD-MM-YYYY'),
        name: employee_name.value,
        rut: resp.rut,
        insurance: origin_insurance_options.value.find(
          (obj) => obj.value === resp.originInsuranceCompanyId
        ).label,
        policies: form_data.originPolicies
          .map((p) => p.numberPolicy)
          .join(', '),
        groups: form_data.originPolicies.flatMap((p) =>
          p.groupCompanies.map(
            (g) =>
              `${p.groupOptions.find((g2) => g2.value == g).label} (${
                p.numberPolicy
              })`
          )
        ),
        insuranceDestiny: destiny_insurance_options.value.find(
          (obj) => obj.value === resp.newInsuranceCompanyId
        ).label,
        policiesDestiny: form_data.newPolicies
          .map((p) => p.numberPolicy)
          .join(', '),
        coverages: form_data.newPolicies
          .flatMap((p) =>
            p.groupCompanies.flatMap((g) =>
              g.coverages
                .filter((c) =>
                  g.selectedCoverages
                    .map((g) => Number(g))
                    .includes(c.coverageGroupCompanyId)
                )
                .map((c) => ({
                  name: c.name,
                  groupName: g.name,
                  numberPolicy: p.numberPolicy
                }))
            )
          )
          .filter((el) => el !== ''),
        optionalFiles: form_data.optional_files
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const company_loading = ref(false);
const getEmployeeDataByRut = async (rut) => {
  company_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_EMPLOYEE_DATA_BY_RUT, rut);
    if (resp.data != null) {
      employee_name.value = `${resp.data.firstName} ${resp.data.firstSurname}`;
    }
  } catch (error) {
    employee_name.value = '';
    notifications('error', 'Error', error.response.data.message);
  }
  company_loading.value = false;
};

const getEmployeeCompanies = async (rut) => {
  try {
    const resp = await store.dispatch(FETCH_COMPANY_BY_RUT, rut);
    const values = resp.data;
    origin_company_option.value = values.map((company) => ({
      label: company.businessName,
      value: company.id
    }));
  } catch (error) {
    console.log(error.response.data.message);
    notifications(
      'error',
      'Error',
      'Error al obtener empresas por rut de empleado.'
    );
  }
};

const getAllCompanies = async () => {
  try {
    const resp = await store.dispatch(FETCH_COMPANIES);
    const values = resp.data;
    destiny_company_options.value = values.map((company) => ({
      label: company.businessName,
      value: company.id
    }));
  } catch (error) {
    console.log(error.response.data.message);
    notifications('error', 'Error', 'Error al obtener todas las empresas.');
  }
};

const insurance_loading = ref(false);
const getEmployeeInsuranceCompaniesByRut = async (rut, companies) => {
  insurance_loading.value = true;
  try {
    const resp = await store.dispatch(
      FETCH_EMPLOYEE_INSURANCE_COMPANIES_BY_RUT,
      { rut, companies }
    );
    origin_insurance_options.value = resp.map((insurance) => ({
      label: insurance.businessName,
      value: insurance.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las aseguradoras.');
  }
  insurance_loading.value = false;
};

const getPoliciesByEmployeeAndInsuranceCompany = async (params) => {
  try {
    const resp = await store.dispatch(FETCH_COMPANY_POLICIES, params);
    return resp.data.map((policy) => ({
      label: `${policy.numberPolicy}`,
      value: `${policy.id}`,
      groupOptions: policy.groupCompanies.map((g) => {
        return {
          label: g.name,
          value: g.id.toString()
        };
      })
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las pólizas.');
  }
};

const destiny_insurance_loading = ref(false);
const getDestinyInsuranceCompanies = async () => {
  destiny_insurance_loading.value = true;
  try {
    const resp = await store.dispatch(FETCH_INSURANCES, {
      companyId: form_data.newCompany
    });
    destiny_insurance_options.value = resp.data.map((insurance) => ({
      label: insurance.businessName,
      value: insurance.id
    }));
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener todas las pólizas.');
  }
  destiny_insurance_loading.value = false;
};

const debounce = (cb, delay = 1000) => {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
};

const updateDebouceRut = debounce((rut, company) => {
  const valid_rut = rutHelper.validateRut(rut);
  if (valid_rut) {
    getEmployeeDataByRut(rut);
    getEmployeeInsuranceCompaniesByRut(rut, company);
    getEmployeeCompanies(rut);
  }
}, 500);

// habilitacion y deshabilitacion de inputs
const disableCompanyInput = computed(() => {
  if (rutHelper.validateRut(form_data.rut) && employee_name.value != '') {
    return false;
  }
  return true;
});

const disablePolicyInput = computed(() => {
  if (
    disableCompanyInput.value === false &&
    form_data.originInsuranceCompanyId != null
  ) {
    return false;
  }
  return true;
});

const disableCurrentNewPolicies = computed(() => {
  if (form_data.newCompany != null && form_data.newInsuranceCompanyId != null) {
    return false;
  }
  return true;
});

// Reactividad de inputs
watch(
  () => [form_data.rut, form_data.originCompany],
  ([newRut, newCompany]) => {
    if (newRut != '') {
      const normalized_rut = rutHelper.normalizeRut(newRut);
      updateDebouceRut(normalized_rut, newCompany);
    } else {
      employee_name.value = '';
    }
  }
);

const policies_loading = ref(false);
watch(
  () => [
    form_data.rut,
    form_data.originCompany,
    form_data.originInsuranceCompanyId
  ],
  async ([newRut, newCompany, newInsurance]) => {
    if (rutHelper.validateRut(newRut) && newInsurance) {
      policies_loading.value = true;
      origin_policy_number_options.value =
        await getPoliciesByEmployeeAndInsuranceCompany(
          `?rut=${rutHelper.normalizeRut(
            newRut
          )}&companyId=${newCompany}&insuranceCompanyId=${newInsurance}`
        );
      policies_loading.value = false;
    }
  }
);

const destiny_policy_loading = ref(false);
watch(
  () => [form_data.newCompany, form_data.newInsuranceCompanyId],
  async ([newCompany, newInsuranceDestiny]) => {
    if (newInsuranceDestiny && newCompany) {
      destiny_policy_loading.value = true;
      destiny_policy_number_options.value =
        await getPoliciesByEmployeeAndInsuranceCompany(
          `?companyId=${newCompany}&insuranceCompanyId=${newInsuranceDestiny}&status=Vigente`
        );
      destiny_policy_loading.value = false;
    }
  }
);

watch(
  () => [form_data.newCompany],
  async () => {
    await getDestinyInsuranceCompanies();
  }
);

watch(
  () => form_data.currentNewPolicies,
  (newDestinyPolicyNumber) => {
    if (newDestinyPolicyNumber.length > 0) {
      getPoliciesCoverages(newDestinyPolicyNumber);
    }
  }
);

const destiny_coverages = ref(true);
const getPoliciesCoverages = async (policies) => {
  destiny_coverages.value = true;
  const params = `?${policies.map((p) => `policies=${p}`).join('&')}`;
  try {
    const resp = await store.dispatch(FETCH_POLICIES_COVERAGES, params);
    const groupedObj = resp.reduce((acc, curr) => {
      // eslint-disable-next-line no-unused-vars
      const { policies, coverageGroupCompanies, ...onlyCoverage } = curr;
      coverageGroupCompanies.forEach((cgc) => {
        const policy = cgc.policyGroupCompany.policies;
        const groupCompany = cgc.policyGroupCompany.groupCompanies;
        acc[policy.id] = acc[policy.id] || {
          ...policy,
          groupCompanies: {}
        };
        acc[policy.id].groupCompanies[groupCompany.id] = acc[policy.id]
          .groupCompanies[groupCompany.id] || {
          ...groupCompany,
          coverages: {}
        };
        acc[policy.id].groupCompanies[groupCompany.id].coverages[cgc.id] = acc[
          policy.id
        ].groupCompanies[groupCompany.id].coverages[cgc.id] || {
          ...onlyCoverage,
          coverageGroupCompanyId: cgc.id
        };
      });
      return acc;
    }, {});
    const groupedArr = Object.values(groupedObj);
    for (let p of groupedArr) {
      p.groupCompanies = Object.values(p.groupCompanies);
      for (let g of p.groupCompanies) {
        g.coverages = Object.values(g.coverages);
      }
    }
    coverages_options.value = groupedArr;
  } catch (error) {
    console.log(error);
    notifications('error', 'Error', 'Error al obtener las coberturas.');
  }
  destiny_coverages.value = false;
};

const onSelectChange = (value, name) => {
  const keys = Object.keys(form_data);
  const index = keys.findIndex((key) => key === name) + 1;
  const finish = index < 7 ? 6 : keys.length - 1;

  for (let i = index; i <= finish; i++) {
    if (
      keys[i] === 'newCoverages' ||
      keys[i] === 'newPolicies' ||
      keys[i] === 'currentNewPolicies' ||
      keys[i] === 'currentOriginPolicies' ||
      keys[i] === 'optional_files' ||
      keys[i] === 'originPolicies'
    ) {
      form_data[keys[i]] = [];
    } else {
      form_data[keys[i]] = null;
    }
  }
};

// * Resume modal
const resume_modal_ref = ref();
const resume_modal_loading = ref(false);
const openResumeModal = (values) => {
  resume_modal_ref.value.openModal(values);
};

// * Resume success modal
const resume_success_modal_ref = ref();

// * Post send
const createTransfer = () => {
  resume_modal_loading.value = true;
  try {
    const formData = new FormData();
    formData.append('rut', rutHelper.normalizeRut(form_data.rut));
    formData.append('brokerId', brokerId);
    form_data.originPolicies.forEach((p) => {
      p.groupCompanies.forEach((groupCompanyId) => {
        formData.append(
          'policiesGroupCompanies[]',
          JSON.stringify({
            policyId: p.policyId,
            groupCompanyId
          })
        );
      });
    });
    form_data.newPolicies.forEach((p) => {
      p.groupCompanies.forEach((g) => {
        g.selectedCoverages.forEach((c) => {
          formData.append('newCoverageGroupCompanyIds[]', c);
        });
      });
    });
    formData.append('transferDate', form_data.startDate.format());
    form_data.optional_files.forEach((e) => {
      formData.append('optionalFiles', e);
    });

    store.dispatch(POST_TICKET_TRANSFER, formData).then((resp) => {
      const { id } = resp.data;
      resume_modal_ref.value.closeModal();
      resume_success_modal_ref.value.openSuccessModal(id);
      form_ref.value.resetFields();
    });
  } catch (error) {
    console.log(error);
    const errMsg =
      error.response?.data?.message ||
      'Ha ocurrido un error al crear el traspaso.';
    notifications('error', 'Error', errMsg);
  }
  resume_modal_loading.value = false;
};

// * Carga principal
onMounted(() => {
  getAllCompanies();
  getDestinyInsuranceCompanies();
});
</script>

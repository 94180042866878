import {
  TicketqueryParamsModificacion,
  getModificacionMasivaTickets,
  employeeInsurance,
  ticketModificacionMasiva,
  cancelTicketModificacionMasiva,
  cancelTicketModificacionIndividual,
  ejecutarTicketMOficacionMasiva,
  postTicketModification,
  ejecutarTicketModificacion
} from '../../../network';
import {
  SET_M_INDIVIDUAL,
  GET_M_INDIVIDUAL,
  FETCH_M_INDIVIDUAL,
  SET_M_MASIVA,
  GET_M_MASIVA,
  FETCH_M_MASIVA,
  SET_SELECT_FORM,
  TYPE_FORM,
  GET_TYPE_FORM,
  SET_INSURANCE,
  FETCH_INSURANCE,
  POST_MODIFICACION_MASIVA,
  CANCEL_MODIFICACION_MASIVA,
  CANCEL_MODIFICACION_INDIVIDUAL,
  EJECUTAR_MODIFICACION_MASIVA,
  POST_M_INDIVIDUAL,
  GET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY,
  SET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY,
  POST_EXECUTE_MODIFICATION_INDIVIDUAL
} from './types';

const store = {};

const getDefaultState = () => ({
  modificacionIndividual: [],
  modificacionMasiva: [],
  types_form: 1,
  insurance: [],
  insuranceByEmployeeGroupCompany: []
});

store.state = getDefaultState();

store.getters = {
  [GET_M_INDIVIDUAL]: (state) => state.modificacionIndividual,
  [GET_M_MASIVA]: (state) => state.modificacionMasiva,
  [GET_TYPE_FORM]: (state) => state.types_form,
  [GET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY]: (state) =>
    state.insuranceByEmployeeGroupCompany
};

store.mutations = {
  [SET_SELECT_FORM](state, payload) {
    state.types_form = payload;
  },
  [SET_M_INDIVIDUAL](state, payload) {
    state.modificacionIndividual = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        holding: a.company?.holding
          ? a.company?.holding.businessName
          : a.company.subHolding?.holding.businessName,
        subholding: a
          ? a.company.subHolding
            ? a.company.subHolding.name
            : ''
          : '',
        companyName: a.company.businessName,
        companyRut: a.company.rut,
        employeeName: `${a.employee.firstName} ${a.employee.firstSurname}`,
        employeeRut: a.employee.rut,
        ticketState: a.states.sort((a, b) => {
          return b.id - a.id;
        })[0].state.id,
        createdAt: a.createdAt,
        detail: 'Ver más',
        states: a.states
      };
    });
  },
  [SET_M_MASIVA](state, payload) {
    state.modificacionMasiva = payload.map((a) => {
      return {
        id: a.id,
        key: a.id,
        holding: a.holding ? a.holding : '',
        subholding: a.subHolding ? a.subholding : '',
        companyName: a.businessName,
        companyRut: a.companyRut,
        count: a.count,
        createdAt: a.createdAt,
        detail: 'Ver más'
      };
    });
  },
  [SET_INSURANCE](state, payload) {
    state.insurance = payload;
  },
  [SET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY](state, payload) {
    state.insuranceByEmployeeGroupCompany = payload;
  }
};

store.actions = {
  async [FETCH_M_INDIVIDUAL]({ commit }) {
    return new Promise((resolve, reject) => {
      TicketqueryParamsModificacion().then(
        (resp) => {
          commit(SET_M_INDIVIDUAL, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_M_MASIVA]({ commit }) {
    return new Promise((resolve, reject) => {
      getModificacionMasivaTickets().then(
        (resp) => {
          commit(SET_M_MASIVA, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },

  [FETCH_INSURANCE]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      employeeInsurance(payload, payload).then(
        (resp) => {
          commit(SET_INSURANCE, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_MODIFICACION_MASIVA](_, payload) {
    const formData = new FormData();
    formData.append('insuranceCompanyId', payload.insuranceCompany);
    formData.append('companyId', payload.company);
    formData.append('brokerId', payload.brokerId);
    formData.append('message', payload.message);
    for (let i = 0; i < payload.fileList.length; i += 1) {
      const file = payload.fileList[i];
      formData.append('files', file);
    }
    let error = '';
    const response = await ticketModificacionMasiva(formData).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 200 || response.status === 201)) {
      return response.data;
    }
    throw error;
  },
  async [POST_M_INDIVIDUAL](_, payload) {
    return new Promise((resolve, reject) => {
      postTicketModification(payload).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },

  [TYPE_FORM]({ commit }, payload) {
    commit(SET_SELECT_FORM, payload);
  },
  async [CANCEL_MODIFICACION_INDIVIDUAL](_, payload) {
    let error = '';
    const response = await cancelTicketModificacionIndividual(payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  async [CANCEL_MODIFICACION_MASIVA](_, payload) {
    let error = '';
    const response = await cancelTicketModificacionMasiva(payload).catch(
      (err) => (error = err)
    );
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
  [EJECUTAR_MODIFICACION_MASIVA](_, payload) {
    return new Promise((resolve, reject) => {
      ejecutarTicketMOficacionMasiva(payload).then(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  [POST_EXECUTE_MODIFICATION_INDIVIDUAL](_, payload) {
    return new Promise((resolve, reject) => {
      ejecutarTicketModificacion(payload).then(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
};

export default store;

import {
  getConsentText,
  createCustomerIdentity,
  customIdentity,
  dependent,
  getDependent,
  deleteDependent,
  getTicketId,
  patchDataEmployee,
  patchConsentText,
  saveConsentDate,
  dataTicket,
  getInsuranceDocuments,
  postFileInsuranceCompany
} from '../../../network';
import {
  SET_ANV,
  POST_CARNET,
  SET_REV,
  DELETE_FILE_ANV,
  RESET_STATE,
  FETCH_CARNET,
  POST_USER_LOADS,
  FETCH_USER_LOADS,
  DELETE_USER_LOADS,
  FETCH_TICKET_ID,
  SET_TICKET_ID,
  GET_POLIZAS,
  PATCH_INSURED_EMPLOYEE,
  SET_CONSENT_TEXT,
  FETCH_CONSENT_TEXT,
  PATCH_CONSENT_TEXT,
  SAVE_CONSENT_DATA,
  GET_DATA_TICKET,
  SET_DATA_TICKET,
  FETCH_DATA_TICKET,
  GET_INSURANCE_DOCUMENTS,
  SET_INSURANCE_DOCUMENT,
  FETCH_INSURANCE_DOCUMENTS,
  POST_FILE_INSURANCE_COMPANY
} from '../../types';

const store = {};
const getDefaultState = () => ({
  fileAnv: {
    file: [],
    ticket_id: '',
    name: ''
  },
  fileRev: {
    file: [],
    ticket_id: '',
    name: ''
  },
  tickets: [],
  textConsent: '',
  dataTicket: [],
  insuranceDocument: []
});

store.state = getDefaultState();

store.getters = {
  dniAnverso: (state) => state.fileAnv.file,
  dniReverso: (state) => state.fileRev.file,

  [GET_POLIZAS]: (state) => {
    return [].concat.apply(
      [],
      state.tickets.policies?.map((e) =>
        e.coverages?.map((c) => {
          return {
            id: c.id,
            name: c.name,
            numberPolicy: e.numberPolicy,
            required: c.required,
            groupName: c.groupCompany?.name
          };
        })
      )
    );
  },
  [GET_DATA_TICKET](state) {
    return state.dataTicket;
  },
  [GET_INSURANCE_DOCUMENTS](state) {
    return state.insuranceDocument;
  }
};

store.mutations = {
  [SET_ANV](state, payload) {
    state.fileAnv.file = payload;
    state.fileAnv.ticket_id = payload.ticket_id;
    state.fileAnv.name = payload.name;
  },
  [SET_REV](state, payload) {
    state.fileRev.file = payload;
    state.fileRev.ticket_id = payload.ticket_id;
    state.fileRev.name = payload.name;
  },
  [SET_TICKET_ID](state, payload) {
    state.tickets = payload;
  },
  [DELETE_FILE_ANV](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_CONSENT_TEXT](state, payload) {
    state.textConsent = payload;
  },
  [SET_DATA_TICKET](state, payload) {
    state.dataTicket = payload;
  },
  [SET_INSURANCE_DOCUMENT](state, payload) {
    state.insuranceDocument = payload;
  }
};

store.actions = {
  async [POST_CARNET](_, payload) {
    try {
      const response = await createCustomerIdentity(payload);
      if (response && response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errMsg = error?.response?.data?.message;
      throw new Error(errMsg || 'Ocurrió un error al subir el carnet');
    }
  },
  async [FETCH_CARNET](_, payload) {
    const response = await customIdentity(payload).catch((e) => e.response);
    if (response && response.status === 200) {
      return response;
    }
    throw { mensaje: 'error en la solicitud fetch carnet' };
  },
  [POST_USER_LOADS](_, payload) {
    return new Promise((resolve, reject) => {
      dependent(payload)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async [FETCH_USER_LOADS](_, payload) {
    const response = await getDependent(payload).catch((e) => e.response);
    if (response && response.status === 200) {
      return response.data;
    }
    throw { mensaje: 'error en la solicitud fetch user loads' };
  },
  async [FETCH_TICKET_ID]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getTicketId(payload).then(
        (resp) => {
          // Dejo el usuario como vacio porsiacaso
          commit(SET_TICKET_ID, resp.data);
          commit(SET_CONSENT_TEXT, resp.data);

          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [DELETE_USER_LOADS](_, payload) {
    const response = await deleteDependent(payload).catch((e) => e.response);
    if (response && response.status === 200) {
      return response.data;
    }
    throw { mensaje: 'error en la solicitud delete user loads' };
  },
  [PATCH_INSURED_EMPLOYEE](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      patchDataEmployee(id, payload).then(
        (resp) => {
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  [FETCH_CONSENT_TEXT]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getConsentText(payload).then(
        (resp) => {
          // Dejo el usuario como vacio porsiacaso
          commit(SET_CONSENT_TEXT, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [PATCH_CONSENT_TEXT](_, { payload }) {
    const response = await patchConsentText(payload).catch((e) => e.response);
    if ((response && response.status === 200) || response.status === 201) {
      return response.data;
    }
    throw { mensaje: 'error en la solicitud patch consent text' };
  },
  [RESET_STATE]({ commit }) {
    commit(DELETE_FILE_ANV);
  },
  async [SAVE_CONSENT_DATA](_, payload) {
    return new Promise((resolve, reject) => {
      saveConsentDate(payload).then(
        (resp) => {
          resolve(resp.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_DATA_TICKET]({ commit }, { payload, token }) {
    return new Promise((resolve, reject) => {
      dataTicket(payload, token).then(
        (resp) => {
          // Dejo el usuario como vacio porsiacaso
          commit(SET_DATA_TICKET, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [FETCH_INSURANCE_DOCUMENTS]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getInsuranceDocuments(payload).then(
        (resp) => {
          // Dejo el usuario como vacio porsiacaso
          commit(SET_INSURANCE_DOCUMENT, resp.data);
          resolve(resp);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
  async [POST_FILE_INSURANCE_COMPANY](_, { id, payload }) {
    return new Promise((resolve, reject) => {
      postFileInsuranceCompany(id, payload).then(
        (resp) => {
          resolve(resp.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
};

export default store;

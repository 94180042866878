<template>
  <div>
    <a-skeleton active :loading="loading_data">
      <a-card>
        <a-typography-title :level="4">Contratante</a-typography-title>
        <a-form layout="vertical">
          <a-row :gutter="16" type="flex">
            <a-col :span="12">
              <a-form-item label="Nombre empresa contratante">
                <a-input v-model:value="company.name" disabled />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="RUT contratante">
                <a-input v-model:value="company.rut" disabled />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title :level="4">Pólizas</a-typography-title>
        <div style="display: flex; flex-direction: column; gap: 16px">
          <a-form style="display: flex; flex-direction: column; gap: 16px" v-for="policy in policies.data"
            :key="policy.id" layout="vertical">
            <a-row v-for="groupCompany in policy.groupCompanies" :key="groupCompany.id" :gutter="32">
              <a-col :span="6">
                <p>Nº Póliza</p>
                <a-input v-model:value="policy.numberPolicy" disabled />
              </a-col>
              <a-col :span="6">
                <p>Grupo</p>
                <a-input v-model:value="groupCompany.name" disabled />
              </a-col>
              <a-col :span="6">
                <p>Coberturas obligatorias</p>
                <a-row type="flex" justify="space-between">
                  <a-checkbox v-for="coverage in groupCompany.coverages.filter(
                    (c) => c.required
                  )" v-model:checked="coverage.checked" :key="coverage.id" :disabled="true">
                    {{ coverage.name }}
                  </a-checkbox>
                </a-row>
              </a-col>
              <a-col :span="6">
                <p>Coberturas opcionales</p>
                <a-row type="flex" justify="space-between">
                  <a-checkbox v-for="coverage in groupCompany.coverages.filter(
                    (c) => !c.required
                  )" v-model:checked="coverage.checked" :key="coverage.id">
                    {{ coverage.name }}
                  </a-checkbox>
                </a-row>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-card>

      <a-row type="flex" class="justify-content-end mt-3">
        <a-col>
          <a-button size="large" class="px-4" type="primary" @click="savePolicies(2)" block :loading="loading">
            Siguiente
          </a-button>
        </a-col>
      </a-row>
    </a-skeleton>
  </div>
</template>

<script setup>
import { reactive, onMounted, ref, defineExpose } from 'vue';
import { useStore } from 'vuex';
import {
  ACTION_CHANGE_STEP,
  FETCH_DIGITAL_FORM_COMPANY,
  FETCH_DIGITAL_FORM_POLICIES,
  PUT_DIGITAL_FORM_POLICIES
} from '@/store/types';
import rutHelper from '@/utils/Ruts';
import notification from '@/utils/notifications';

const store = useStore();
const company = reactive({
  rut: '',
  name: ''
});
const policies = reactive({
  data: []
});
const loading_data = ref(false);
const loading = ref();

const getCompany = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_COMPANY)
      .then((resp) => {
        const { businessName, rut } = resp.data;
        company.rut = rutHelper.formatRut(rut);
        company.name = businessName;
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getPolicies = () => {
  return new Promise((resolve, reject) => {
    store
      .dispatch(FETCH_DIGITAL_FORM_POLICIES)
      .then((resp) => {
        const { data } = resp;

        policies.data = data;

        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const savePolicies = (step) => {
  loading.value = true;

  const requiredCoverages = [];
  const notRequiredCoverages = [];
  let hasNotRequiredCoverages = false;
  policies.data.forEach((policy) => {
    const coverages = policy.groupCompanies.flatMap((g) => g.coverages);
    const required = coverages.filter((c) => c.required);
    const notRequired = coverages.filter((c) => !c.required);
    if (notRequired.length > 0) {
      hasNotRequiredCoverages = true;
    }
    requiredCoverages.push(
      ...required.filter((c) => c.checked).map((c) => c.coverageGroupCompanyId)
    );
    notRequiredCoverages.push(
      ...notRequired
        .filter((c) => c.checked)
        .map((c) => c.coverageGroupCompanyId)
    );
  });

  if (notRequiredCoverages.length == 0 && hasNotRequiredCoverages) {
    notification(
      'warning',
      'Advertencia',
      'No ha seleccionado ninguna cobertura opcional. Continuará únicamente con las coberturas financiadas por la empresa.'
    );
  }

  store
    .dispatch(PUT_DIGITAL_FORM_POLICIES, {
      coverageGroupCompanyIds: [...requiredCoverages, ...notRequiredCoverages]
    })
    .then(() => {
      redirectToOwnerData(step);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      loading.value = true;
    });
};

const redirectToOwnerData = (step) => {
  store.dispatch(ACTION_CHANGE_STEP, step);
};

const getData = async () => {
  loading_data.value = true;
  try {
    await getPolicies();
    await getCompany();
  } catch (error) {
    console.error(error.response.data.message);
  }
  loading_data.value = false;
};

onMounted(() => {
  getData();
});

defineExpose({ savePolicies });
</script>

<template>
  <div style="
      background-color: #fff;
      min-height: 100dvh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    ">
    <a-row justify="center">
      <a-col :lg="10">
        <img class="img-fluid" src="@/assets/images/mantención.png" alt="" />
      </a-col>
    </a-row>
    <a-row justify="center">
      <a-col>
        <a-typography-title style="bottom: 0px; font-family: 'Urbanist'; text-align: center">
          Plataforma en mantenimiento
        </a-typography-title>
        <p>
          Disculpen las molestias, estamos trabajando para mejorar el servicio
          de nuestra plataforma.
        </p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const home = () => {
      router.push({ name: 'Dashboard' });
    };

    return {
      home
    };
  }
};
</script>

<style></style>

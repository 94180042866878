export const SET_EMPLOYEE = 'employee/SET_EMPLOYEE';
export const SET_DEPENDENTS = 'employee/SET_DEPENDENTS';
export const SET_EMPLOYEE_SUMMARY = 'employee/SET_EMPLOYEE_SUMMARY';
export const SET_EMPLOYEE_TICKET = 'employee/SET_EMPLOYEE_TICKET';
export const SET_COVERAGE_EMPLOYEE = 'employee/SET_COVERAGE_EMPLOYEE';

export const FETCH_EMPLOYEE = 'employee/FETCH_EMPLOYEE';
export const FETCH_DEPENDENTS = 'employee/FETCH_DEPENDENTS';
export const FETCH_EMPLOYEE_SUMMARY = 'employee/FETCH_EMPLOYEE_SUMMARY';
export const FETCH_EMPLOYEE_TICKET = 'employee/FETCH_EMPLOYEE_TICKET';
export const FETCH_COVERAGE_EMPLOYEE = 'employee/FETCH_COVERAGE_EMPLOYEE';
export const FETCH_EMPLOYEE_COMPANY_RENT =
  'employee/FETCH_EMPLOYEE_COMPANY_RENT';

export const GET_DEPENDENTS = 'employee/GET_DEPENDENTS';
export const GET_EMPLOYEE = 'employee/GET_EMPLOYEE';
export const GET_FULL_NAME = 'employee/GET_FULL_NAME';
export const GET_POLIZA = 'employee/GET_POLIZA';
export const GET_EMPLOYEE_SUMMARY = 'employee/GET_EMPLOYEE_SUMMARY';
export const GET_EMPLOYEE_TICKET = 'employee/GET_EMPLOYEE_TICKET';
export const GET_COVERAGE_EMPLOYEE = 'employee/GET_COVERAGE_EMPLOYEE';

export const PUT_EMPLOYEE_COMPANY_RENT = 'stock/PUT_EMPLOYEE_COMPANY_RENT';

import {
  cancelDependentsIncorporation,
  createEmployeeDependentsIncorporation,
  createInsuranceDependentsIncorporation,
  finishDependentsIncorporation,
  getDependentsIncorporationForm,
  getDependentsOfDependentsIncorporationForm,
  putDependentsOfDependentsIncorporationForm,
  putDocumentOfDependentsIncorporationForm,
  resendDependentsIncorporationForm,
  sendToBrokerDependentsIncorporationForm,
  sendToInsuranceDependentsIncorporation,
  getPoliciesForDependentsIncorporation,
  getCompaniesForDependentsIncorporation,
  postFileDependentsIncorporation,
  // postDependentsIncorporationForm
} from '../../../network';
import { ticketService, dependantIncorporationService } from '@/network';

import {
  POST_EMPLOYEE_DEPENDENTS_INCORPORATION,
  POST_INSURANCE_DEPENDENTS_INCORPORATION,
  POST_RESEND_DEPENDENTS_INCORPORATION_FORM,
  POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM,
  POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION,
  POST_CANCEL_DEPENDENTS_INCORPORATION,
  POST_FINISH_DEPENDENTS_INCORPORATION,
  POST_FILE_DEPENDENTS_INCORPORATION,
  PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION,
  PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION,
  GET_DEPENDENTS_INCORPORATION_FORM,
  GET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM,
  FETCH_DEPENDENTS_INCORPORATION_FORM,
  FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM,
  SET_DEPENDENTS_INCORPORATION_FORM,
  SET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM,
  FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM,
  FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM,
  FETCH_TICKET_DEPENDANTS_INCORPORATIONS,
  SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING,
  SET_TICKET_DEPENDANTS_INCORPORATIONS,
  GET_TICKET_DEPENDANTS_INCORPORATIONS,
  DELETE_TICKET_DEPENDANT_INCORPORATION
} from '@/store/types';

const store = {};
const getDefaultState = () => ({
  dependants: {
    data: [],
    loading: false
  }
});

store.state = getDefaultState();

store.getters = {
  [GET_TICKET_DEPENDANTS_INCORPORATIONS](state) {
    return state.dependants;
  },
  [GET_DEPENDENTS_INCORPORATION_FORM](state) {
    return state.form;
  },
  [GET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM](state) {
    return state.dependents;
  }
};

store.mutations = {
  [SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING](state, loading) {
    state.dependants.loading = loading;
  },
  [SET_TICKET_DEPENDANTS_INCORPORATIONS](state, dependants) {
    state.dependants.data = dependants;
  },
  [SET_DEPENDENTS_INCORPORATION_FORM](state, form) {
    state.form = form;
  },
  [SET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM](state, dependents) {
    state.dependents = dependents;
  }
};

store.actions = {
  async [FETCH_TICKET_DEPENDANTS_INCORPORATIONS]({ commit }, id) {
    commit(SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING, true);
    commit(SET_TICKET_DEPENDANTS_INCORPORATIONS, []);
    try {
      const response = await ticketService.getDependantsIncorporations(id);
      commit(SET_TICKET_DEPENDANTS_INCORPORATIONS, response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching ticket dependants', error);
      console.error('Params:', id);
      throw error;
    } finally {
      commit(SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING, false);
    }
  },
  async [DELETE_TICKET_DEPENDANT_INCORPORATION](
    { commit, dispatch },
    { id, ticketId }
  ) {
    commit(SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING, true);
    try {
      const response =
        await dependantIncorporationService.deleteDependantIncorporation(id);
      return response.data;
    } catch (error) {
      console.error('Error deleting ticket dependant', error);
      console.error('Params:', id);
      throw error;
    } finally {
      commit(SET_TICKET_DEPENDANTS_INCORPORATIONS_LOADING, false);
      dispatch(FETCH_TICKET_DEPENDANTS_INCORPORATIONS, ticketId);
    }
  },
  async [POST_EMPLOYEE_DEPENDENTS_INCORPORATION](_, payload) {
    const response = await createEmployeeDependentsIncorporation(payload);
    return response.data;
  },
  async [POST_INSURANCE_DEPENDENTS_INCORPORATION](_, payload) {
    const response = await createInsuranceDependentsIncorporation(payload);
    return response.data;
  },
  async [FETCH_DEPENDENTS_INCORPORATION_FORM]({ commit }, token) {
    const response = await getDependentsIncorporationForm(token);
    commit(SET_DEPENDENTS_INCORPORATION_FORM, response.data);
    return response.data;
  },
  async [FETCH_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM]({ commit }, token) {
    const response = await getDependentsOfDependentsIncorporationForm(token);
    commit(SET_DEPENDENTS_OF_DEPENDENTS_INCORPORATION_FORM, response.data);
    return response.data;
  },
  async [PUT_DEPENDENTS_OF_DEPENDENTS_INCORPORATION](_, { token, dependents }) {
    const response = await putDependentsOfDependentsIncorporationForm({
      token,
      dependents
    });
    return response.data;
  },
  async [PUT_DOCUMENT_OF_DEPENDENTS_INCORPORATION](_, { token, payload }) {
    const response = await putDocumentOfDependentsIncorporationForm({
      token,
      payload
    });
    return response.data;
  },
  async [POST_RESEND_DEPENDENTS_INCORPORATION_FORM](_, token) {
    const response = await resendDependentsIncorporationForm(token);
    return response.data;
  },
  async [POST_SEND_TO_BROKER_DEPENDENTS_INCORPORATION_FORM](_, token) {
    const response = await sendToBrokerDependentsIncorporationForm(token);
    return response.data;
  },
  async [POST_CANCEL_DEPENDENTS_INCORPORATION](_, id) {
    const response = await cancelDependentsIncorporation(id);
    return response.data;
  },
  async [POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION](_, id) {
    const response = await sendToInsuranceDependentsIncorporation(id);
    return response.data;
  },
  async [POST_FINISH_DEPENDENTS_INCORPORATION](_, id) {
    const response = await finishDependentsIncorporation(id);
    return response.data;
  },
  async [POST_INSURANCE_DEPENDENTS_INCORPORATION](_, id) {
    const response = await createInsuranceDependentsIncorporation(id);
    return response.data;
  },
  async [FETCH_POLICIES_FOR_DEPENDENTS_INCORPORATION_FORM](_, rut) {
    const response = await getPoliciesForDependentsIncorporation(rut);
    return response.data;
  },
  async [FETCH_COMPANIES_FOR_DEPENDENTS_INCORPORATION_FORM](_, rut) {
    const response = await getCompaniesForDependentsIncorporation(rut);
    return response.data;
  },
  async [POST_FILE_DEPENDENTS_INCORPORATION](_, payload) {
    
    console.log('El valor recibido por POST_FILE_DEPENDENTS_INCORPORATION es:', payload);
    console.log('Contenido de fileList en el action:', payload.fileList);
    let response = '';
    let error = '';
    const formData = new FormData();
    formData.append('brokerId', payload.brokerId);
    formData.append('companyId', payload.company);
    formData.append('insuranceCompanyId', payload.insuranceCompany);
    formData.append('file', payload.fileList[0]);
    if (payload.typeForm === '1') {
      formData.append('message', payload.message);
      response = await postFileDependentsIncorporation(formData).catch(
        (err) => (error = err)
      );
    } 
    // else {
    //   payload.docs.forEach((files) => {
    //     formData.append('filesInsurance[]', files);
    //   });
    //   payload.optional_files.forEach((files) => {
    //     formData.append('optionalFiles', files);
    //   });
    //   formData.append('message', payload.messageColaborador);

    //   response = await postDependentsIncorporationForm(formData).catch(
    //     (err) => (error = err)
    //   );
    // }
    if (response && (response.status === 201 || response.status === 200)) {
      return response.data;
    }
    throw error;
  },
};

export default store;

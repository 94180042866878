<template>
  <page-header :breadcrumRoutes="routes" title="Formulario alta de cargas masiva" />
  <a-form
    name="form"
    layout="vertical"
    ref="formRef"
    :model="formState"
    :rules="rules"
    @finish="onSubmit"
  >
    <a-card>
      <a-row>
        <a-form-item required name="typeForm" label="Envío de información por">
          <a-radio-group
            name="radioGroup"
            v-model:value="formState.typeForm"
            @change="handleChangeInsurance"
          >
            <a-radio value="1">Archivo</a-radio>
            <a-radio value="2" disabled>Formulario</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-row>
    </a-card>
    <a-card class="mt-2">
      <a-row class="mb-2">
        <a-col>
          <a-typography-title class="text-gray-8" :level="4">
            Datos corporativos
          </a-typography-title>
        </a-col>
      </a-row>
      <a-row :gutter="16" type="flex">
        <a-col :span="8">
          <a-form-item label="Empresa" has-feedback name="company">
            <a-select
              v-model:value="formState.company"
              placeholder="Selecciona una empresa"
              :options="companiesData"
              @change="handleChangeCompany"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Aseguradora" has-feedback name="insuranceCompany">
            <a-select
              v-model:value="formState.insuranceCompany"
              placeholder="Selecciona una aseguradora"
              :options="insurancesData"
              :disabled="insurancesData.length < 1"
              @change="handleChangeInsurance"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col
          v-if="
            formState.typeForm === '2' &&
            documentsData.length >= 1 &&
            insuranceCompanyFormType === 'PDF_FORM'
          "
          :span="8"
        >
          <a-form-item label="Documentos adjuntos" has-feedback name="docs">
            <a-checkbox-group v-model:value="formState.docs">
              <div v-for="(doc, index) in documentsData" :key="index">
                <a-checkbox :value="doc.value">{{ doc.label }}</a-checkbox>
              </div>
            </a-checkbox-group>
          </a-form-item>
        </a-col>
      </a-row>
    </a-card>
    <a-row :gutter="16" type="flex" class="mt-2">
      <a-col v-if="formState.typeForm === '1'" :span="12">
        <a-card style="height: 340px">
          <a-row class="mb-2">
            <a-col>
              <a-typography-title class="text-gray-8" :level="4">
                Mensaje a compañía aseguradora
              </a-typography-title>
            </a-col>
          </a-row>
          <a-form-item
            has-feedback
            label="Escribe mensaje para compañía aseguradora"
            name="message"
          >
            <a-textarea
              v-model:value="formState.message"
              placeholder="Escribe Aquí..."
              :rows="6"
            />
          </a-form-item>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card>
          <a-row :gutter="18">
            <a-col :span="formState.typeForm == 2 ? 12 : 24">
              <a-typography-title class="text-gray-8" :level="4">
                Adjuntar archivo
              </a-typography-title>

              <a-form-item
                label="Adjunta documento excel con listado de cargas"
                name="fileList"
              >
                <a
                  v-if="formState.insuranceCompany"
                  :href="documentURL"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                Descargar formato
                </a>
                <a-upload-dragger
                  class="mt-3"
                  v-model:fileList="formState.fileList"
                  name="fileList"
                  :remove="handleRemove"
                  :before-upload="beforeUpload"
                  @change="(info) => console.log('Cambio en a-upload-dragger:', info)"
                >
                <p class="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>

                <div class="ant-upload-text fs-6 text-gray-9">
                  Haz click o arrastra aquí el archivo para cargarlo
                </div>
                </a-upload-dragger>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item
                name="optional_files"
                label="Archivos opcionales"
                v-if="formState.typeForm == 2"
              >
                <a-upload
                  :file-list="formState.optional_files"
                  :multiple="true"
                  :remove="handleRemoveOptional"
                  :before-upload="beforeUploadOptional"
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                >
                  <a-button>
                    <upload-outlined />
                    Subir Archivo
                  </a-button>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="12">
        <a-card v-if="formState.typeForm == 2">
          <a-typography-title class="text-gray-8" :level="4">
            Mensaje a colaborador
          </a-typography-title>
          <a-form-item
            has-feedback
            label="Escribe mensaje para colaborador"
            name="message"
          >
            <a-textarea
              v-model:value="formState.messageColaborador"
              placeholder="Escribe aquí..."
              :rows="8"
            />
          </a-form-item>
        </a-card>
      </a-col>
    </a-row>
    <a-row>
      <a-form-item class="mt-4">
        <a-button
          size="large"
          class="px-4"
          type="primary"
          block
          @click="showModal"
          :disabled="false"
        >Siguiente
        </a-button>
      </a-form-item>
    </a-row>
  </a-form>

  <a-modal
    v-model:visible="visible"
    :title="modalStyle.title"
    @ok="handleOk"
    centered
    :closable="!loading"
    :maskClosable="false"
  >
    <a-typography-title class="text-gray-4 fw-regular mb-2" :level="5">
      {{ modalStyle.text }}
    </a-typography-title>
    <template #footer>
      <a-row type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button @click="handleOk" :disabled="loading">
              {{ modalStyle.cancelText }}
            </a-button>
            <a-button
              key="submit"
              type="primary"
              @click="onSubmit"
              :loading="loading"
              >
              {{ modalStyle.confirmText }}
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </template>
  </a-modal>
  <template v-if="showSucess">
    <a-modal
      v-model:visible="showSucess"
      centered
      footer=""
      :maskClosable="false"
    >
      <a-result
        status="success"
        title="Solicitud enviada"
        :sub-title="modalStyle.successText"
      >
        <template #extra>
          <router-link
            class="justify-content-center d-flex"
            :to="{ name: 'AltaCargasMasivaDetail', params: { id: createdTicketId } }"
          >
            <a-button key="console" type="primary">Ver ticket</a-button>
          </router-link>
        </template>
      </a-result>
    </a-modal>
  </template>
</template>

<script>

// Components
import { UploadOutlined } from '@ant-design/icons-vue';
import PageHeader from '@/components/PageHeader.vue';
import { onMounted, reactive, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
// Store
import {
  GET_LOGGED_USER,
  FETCH_AM_COMPANIES,
  GET_AM_COMPANIES,
  FETCH_AM_INSURANCES,
  GET_AM_INSURANCES,
  FETCH_AM_DOCS,
  GET_AM_DOCS,
  // GET_AM_URL,
  // FETCH_AM_URL,
  POST_FILE_DEPENDENTS_INCORPORATION,
  URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE,
  FETCH_FILE_DEPENDENTS_INCORPORATION_URL,
  GET_FILE_DEPENDENTS_INCORPORATION_URL
} from '@/store/types';

// Utils
import openNotificationWithIcon from '@/utils/notifications';
import delay from '@/utils/delay';

const routes = [
  {
    path: '/altas-cargas',
    breadcrumbName: 'Altas cargas'
  },
  {
    breadcrumbName: 'Masiva'
  }
];

export default {
  name: 'AltaCargasMasiva',
  components: {
    'page-header': PageHeader,
    UploadOutlined: UploadOutlined
  },

  setup() {
    const store = useStore();
    //* Get Data
    const companiesData = ref([]);
    const dataOutput = ref('');
    const getCompanies = async () => {
      await store
        .dispatch(FETCH_AM_COMPANIES)
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `Error al cargar Empresas. ${err.response.data.message}`
          )
        );
      companiesData.value = await store.getters[GET_AM_COMPANIES];
    };
    getCompanies();

    const insurancesData = ref([]);
    const handleChangeCompany = async (value) => {
      await store
        .dispatch(FETCH_AM_INSURANCES, formState.company)
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `Error al cargar Compañías Aseguradoras. ${err.response.data.message}`
          )
        );
      const name = companiesData.value
        .map((e) => e)
        .find((f) => f.value == value);
      dataOutput.value = companyMessage.replace('$company', name.label);
      formState.messageColaborador = dataOutput.value;
      formState.insuranceCompany = null;
      if (formState.insuranceCompany == null) {
        formRef.value.resetFields(['insuranceCompany']);
      }
      formState.docs = [];
      documentsData.value = [];
      insurancesData.value = await store.getters[GET_AM_INSURANCES];
    };

    const documentURL = ref(null);
    const documentsData = ref([]);
    const handleChangeInsurance = async () => {
      if (!formState.insuranceCompany) return;
      if (!formState.company) return;
      await store
        .dispatch(FETCH_AM_DOCS, formState.insuranceCompany)
        .catch((err) =>
          openNotificationWithIcon(
            'error',
            'Error',
            `Error al cargar Formularios. ${err.response.data.message}`
          )
        );
      formState.docs = [];
      documentsData.value = await store.getters[GET_AM_DOCS];

      if (formState.typeForm == '1') {
        await store
          .dispatch(FETCH_FILE_DEPENDENTS_INCORPORATION_URL, {
            companyId: formState.company,
            insuranceCompanyId: formState.insuranceCompany
          })
          .catch((err) =>
            openNotificationWithIcon(
              'error',
              'Error',
              `Error al cargar Documento. ${err.response.data.message}`
            )
          );
        documentURL.value = null;
        documentURL.value = store.getters[GET_FILE_DEPENDENTS_INCORPORATION_URL];
      } else {
        try {
          console.log('else');
          const url = await store.dispatch(
            URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE,
            {
              companyId: formState.company,
              insuranceCompanyId: formState.insuranceCompany
            }
          );
          documentURL.value = null;
          documentURL.value = url;
        } catch (error) {
          console.error(error);
          openNotificationWithIcon(
            'error',
            'Error',
            'Error al cargar Documento.'
          );
        }
      }

      /*
      if (formState.typeForm == '1') {
        await store
          .dispatch(FETCH_AM_URL, {
            companyId: formState.company,
            insuranceCompanyId: formState.insuranceCompany
          })
          .catch((err) =>
            openNotificationWithIcon(
              'error',
              'Error',
              `Error al cargar Documento. ${err.response.data.message}`
            )
          );
        documentURL.value = null;
        documentURL.value = store.getters[GET_AM_URL];
      } else {
        try {
          console.log('else');
          const url = await store.dispatch(
            URL_MASSIVE_INCORPORATION_TO_EMPLOYEE_TEMPLATE,
            {
              companyId: formState.company,
              insuranceCompanyId: formState.insuranceCompany
            }
          );
          documentURL.value = null;
          documentURL.value = url;
        } catch (error) {
          console.error(error);
          openNotificationWithIcon(
            'error',
            'Error',
            'Error al cargar Documento.'
          );
        }
      }
      */
    };

    //* Form
    const formRef = ref();
    const formState = reactive({
      typeForm: '1',
      brokerId: store.getters[GET_LOGGED_USER].id,
      company: null,
      insuranceCompany: null,
      docs: [],
      message:
        'A través del presente correo solicitamos la siguiente alta de cargas masiva para los rut de cargas de colaboradores en el documento adjunto.',
      fileList: [],
      optional_files: [],
      messageColaborador: ''
    });

    const companyMessage =
      'Para poder recibir los beneficios de tu empresa $company es necesario que completes los siguientes pasos:';

    onMounted(() => {
      formState.messageColaborador = companyMessage.replace('$company ', '');
    });

    const loading = ref(false);

    //* Docs Logic
    const handleRemove = () => {
      formState.fileList = [];
    };

    const beforeUpload = (file) => {
      console.log('Archivo recibido en beforeUpload:', file);
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.fileList[0] = file;
      }
      return false;
    };

    // Eliminar un archivo para la subida de archivos opcionales
    const handleRemoveOptional = (file) => {
      const index = formState.optional_files.indexOf(file);
      const newFileList = formState.optional_files.slice();
      newFileList.splice(index, 1);
      formState.optional_files = newFileList;
    };

    // Agregar un archivo opcional al input
    const beforeUploadOptional = (file) => {
      if (formState.optional_files.length < 5) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 5) {
          message.error('El archivo excede el peso máximo 5MB');
        } else {
          formState.optional_files = [...formState.optional_files, file];
        }
      } else {
        message.error('Máximo archivos a subir es 5');
      }
      return false;
    };

    //* Modal
    const visible = ref(false);
    const showSucess = ref(false);
    const createdTicketId = ref(null);
    const modalStyle = reactive({
      title: '',
      text: '',
      cancelText: '',
      confirmText: '',
      successText: ''
    });

    const showModal = () => {
      formRef.value.validate().then(() => {
        visible.value = true;
        if (formState.typeForm === '1') {
          modalStyle.title = 'Alta de cargas masiva';
          modalStyle.text =
            '¿Estás seguro que deseas enviar una solicitud alta de cargas masiva con archivo?';
          modalStyle.cancelText = 'Volver atrás';
          modalStyle.confirmText = 'Confirmar';
          modalStyle.successText =
            'Su solicitud de alta de cargas masiva por archivo ha sido enviada con éxito';
        } else {
          modalStyle.title = 'Ejecutar movimiento';
          modalStyle.text =
            '¿Estás seguro que deseas enviar una solicitud alta de cargas masiva con formulario de incorporación?';
          modalStyle.cancelText = 'Cancelar';
          modalStyle.confirmText = 'Confirmar';
          modalStyle.successText =
            'Su solicitud de alta de cargas masiva por formulario ha sido enviada con éxito';
        }
      });
    };

    const handleOk = () => {
      visible.value = false;
      // formRef.value.resetFields();
    };

    //* Validations
    const rules = {
      company: [
        {
          required: true,
          message: 'Seleccione una empresa',
          trigger: 'change',
          type: 'number'
        }
      ],
      insuranceCompany: [
        {
          required: true,
          message: 'Seleccione una compañía aseguradora',
          trigger: 'change',
          type: 'number'
        }
      ],
      docs: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione al menos un formulario',
          trigger: 'change'
        }
      ],
      message: [
        {
          required: true,
          message: 'Ingrese un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      fileList: [
        {
          type: 'array',
          required: true,
          message: 'Adjunte un archivo',
          trigger: 'change'
        }
      ]
    };

    const insuranceCompanyFormType = computed(() => {
      return insurancesData.value.find(
        (company) => company.value === formState.insuranceCompany
      )?.formType;
    });

    //* Actions
    const onSubmit = async () => {
      console.log('Contenido de fileList antes del envío: ', formState.fileList);
      loading.value = true;
      try {
        const res = await store.dispatch(POST_FILE_DEPENDENTS_INCORPORATION, formState);
        console.log('Luego del submit el front recibió: ', res);
        createdTicketId.value = res.id;

        await delay(1000);

        openNotificationWithIcon(
          'success',
          'Correcto',
          'Ticket de alta de cargas masiva creado con éxito'
        );
        visible.value = false;
        showSucess.value = true;
        formRef.value.resetFields();
      } catch (error) {
        openNotificationWithIcon(
          'error',
          'Error',
          `Error al generar el ticket de alta de cargas masiva. ${error.response.data.message}`
        );
      }
      loading.value = false;
      visible.value = false;
    };

    watch(() => formState.fileList, (newFileList) => {
      const files = [...newFileList];

      if (files.length > 0) {
        console.log('Detalles del archivo subido:', files[0]);
        console.log('Nombre:', files[0].name);
        console.log('Tipo:', files[0].type);
        console.log('Tamaño:', files[0].size);
      } else {
        console.log('No hay archivos en fileList.');
      }
    });

    return {
      //* Variables
      routes,
      dataOutput,
      companyMessage,
      companiesData,
      insurancesData,
      documentsData,
      documentURL,
      modalStyle,
      visible,
      formRef,
      formState,
      rules,
      loading,
      showSucess,
      createdTicketId,
      insuranceCompanyFormType,
      //* Methods
      handleChangeCompany,
      handleChangeInsurance,
      handleRemove,
      beforeUpload,
      showModal,
      handleOk,
      onSubmit,
      handleRemoveOptional,
      beforeUploadOptional
    };
  }
};



</script>

<template>
  <div>
    <PageHeader title="Alta de cargas" :breadcrum-routes="page_header_routes">
      <template #tags>
        <a-tag :color="state_tag != 'CANCELADO'
            ? state_tag != 'EN RESOLICITUD'
              ? state_tag != 'EN CORREDORA'
                ? state_tag != 'EN ASEGURADORA'
                  ? 'processing'
                  : 'warning'
                : 'warning'
              : 'warning'
            : 'error'
          ">
          {{ state_tag }}
        </a-tag>
        <a-tag color="processing"> ID: {{ ticket_id }} </a-tag>
      </template>
      <template v-if="user_role != 'rrhh'" #buttons>
        <a-button v-if="state_tag != 'CANCELADO' && state_tag != 'EJECUTADO'" @click="openCancelModal"
          :loading="loading">
          Cancelar
        </a-button>
        <a-button class="ml-1" type="primary" @click="openReviewModal" :loading="loading"
          v-if="state_tag === 'EN CORREDORA'">
          Revisado
        </a-button>
        <a-button class="ml-1" type="primary" @click="openFinishModal" :loading="loading"
          v-if="state_tag === 'EN ASEGURADORA'">
          Ejecutar
        </a-button>
      </template>
    </PageHeader>

    <StepperState v-bind="stepper_data" />

    <a-card class="mt-3">
      <a-row :gutter="16">
        <a-col :span="12">
          <TicketData v-bind="ticket_details">
            <template v-if="user_role != 'rrhh' &&
              (state_tag === 'EN CORREDORA' || state_tag === 'EN ASEGURADORA')
              " #action>
              <a-tooltip>
                <template #title>Editar</template>
                <a-button @click="openAltaEditModal">
                  <template #icon><edit-outlined /></template>
                </a-button>
              </a-tooltip>

              <AltaEditModal :values="alta_edit_object" :ticketId="Number(ticket_id)"
                @editedAltaColaborator="() => getTicketData()" ref="edit_modal_ref"
                :rentRequired="employee_rent_required" />
            </template>
          </TicketData>

          <a-card v-if="user_role != 'rrhh'" title="Casos asociados"
            bodyStyle="padding: 24px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;"
            :bordered="false">
            <div v-if="cases.length == 0">
              <a-typography-text>No existen casos</a-typography-text>
            </div>

            <a-row type="flex" justify="space-between" v-for="c in cases" :key="c.id">
              <a-col :span="6">
                <a-typography-text>{{ c.state }}</a-typography-text>
              </a-col>
              <a-col>
                <tags :stateId="c.tag" />
              </a-col>
              <a-col>
                <a-button type="link" @click="redirectCase(c)">Ir a caso</a-button>
              </a-col>
              <a-divider class="my-2" v-if="cases.length > 1" />
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <Records v-bind="records_data" />

          <a-table v-if="user_role != 'rrhh'" :row-selection="rowSelectionForm" :selections="true"
            :columns="columnsFiles" :data-source="files" :showHeader="false" :pagination="false"
            :loading="loadingFiles">
            <template #title>Archivos adjuntos</template>
            <template #options="{ text: options }">
              <span style="display: flex">
                <a-tooltip color="#05045D" title="Ver más">
                  <a-button type="link" :disabled="options.requestCounter > 0">
                    <eye-outlined @click="() => redirectToFile(options.seeMore)" />
                  </a-button>
                </a-tooltip>
                <a-tooltip color="#05045D" title="Resolicitar">
                  <a-button type="link" @click="() => openReapplyModal(options.fileId)" :disabled="!options.canRequest">
                    <mail-outlined />
                  </a-button>
                </a-tooltip>
                <a-tooltip color="#05045D" title="Historial" @click="() => openAttachedDocumentsHistoryModal(options.fileId)
                  ">
                  <a-button type="link">
                    <clock-circle-outlined />
                  </a-button>
                </a-tooltip>
              </span>
            </template>
          </a-table>

          <dependants-incorporations-table class="mt-2" />

          <Comments class="mt-2" v-if="user_role != 'rrhh'" :idToTicket="ticket_id" :comments="comments"
            @new_comment="getNewCommment" />
        </a-col>
      </a-row>
    </a-card>

    <attached-documents-history ref="attached_documents_history_ref" />

    <!-- Resolicitud de documento -->
    <a-modal v-model:visible="showReapplyDoc" centered @cancel="closeReapplyModal" title="Resolicitud de documento"
      :closable="!loading_reapply" :maskClosable="!loading_reapply">
      <h3><b>Escribe un mensaje para el colaborador</b></h3>
      <p>
        Recordatorio Nombre:
        {{ employee_data?.employee?.fullname }}
      </p>

      <a-textarea v-model:value="reapply_text" placeholder="Escribe aquí el mensaje para el colaborador"
        :autoSize="{ minRows: 5, maxRows: 5 }" />

      <template #footer>
        <a-button key="back" @click="closeReapplyModal" :disabled="loading_reapply">
          Cancelar
        </a-button>
        <a-button key="submit" type="primary" :loading="loading_reapply" :disabled="reapplyButtonBlocked"
          @click="documentReapply(id_reapply_document)">
          Enviar
        </a-button>
      </template>
    </a-modal>

    <!-- Modal Success -->
    <a-modal v-model:visible="showSucess" centered footer="" :title="success_title">
      <a-result status="success" :title="success_message"> </a-result>
    </a-modal>

    <!-- Modal Error -->
    <a-modal v-model:visible="showError" centered footer="" :title="error_title">
      <a-result status="error" :title="error_message"> </a-result>
    </a-modal>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref, createVNode } from 'vue';
import {
  ClockCircleOutlined,
  EyeOutlined,
  MailOutlined,
  ExclamationCircleOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';
import AltaEditModal from '@/components/Tickets/AltaEditModal.vue';
import openNotificationWithIcon from '@/utils/notifications';
import AttachedDocumentsHistory from '@/components/AttachedDocumentsHistory.vue';
import notification from '@/utils/notifications';
import rutFormatter from '@/utils/Ruts';
import PageHeader from '@/components/PageHeader.vue';
import Tags from '@/components/Tags.vue';
import StepperState from '@/components/StepperState.vue';
import TicketData from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import Records from '@/components/Tickets/Records.vue';
import DateFormmater from '@/utils/dateFormatter';
import parseDate from '@/utils/dateFormatter';
import DependantsIncorporationsTable from '@/new-components/ticket/dependants-incorporation/dependants-incorporations-table.vue';
import {
  FETCH_TICKET,
  FETCH_TICKET_ID,
  FETCH_CASES,
  FETCH_EMPLOYEE_TICKET,
  FETCH_TICKET_COMMENTS_ID,
  FETCH_USER_BY_ID,
  POST_CANCEL_DEPENDENTS_INCORPORATION,
  POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION,
  POST_FINISH_DEPENDENTS_INCORPORATION,
  FETCH_FILE_BY_TICKET,
  PUT_REAPPLY_FILE,
  FETCH_TICKET_DEPENDANTS_INCORPORATIONS,
  FETCH_EMPLOYEE_COMPANY_RENT,
  FETCH_TICKET_INCOME
} from '@/store/types';
import moment from 'moment';

const store = useStore();
const route = useRoute();
const router = useRouter();
const ticket_id = route.params.id;
const loading = ref(false);
const employee_data = ref({});
const broker_name = ref('');
const showSucess = ref(false);
const success_title = ref('');
const success_message = ref('');
const showError = ref(false);
const error_title = ref('');
const error_message = ref('');
const user_role = ref('');

const openReviewModal = () => {
  Modal.confirm({
    title: () =>
      '¿Estás seguro que deseas enviar el alta de cargas a la compañía aseguradora?',
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => 'Sí',
    cancelText: () => 'No',
    onOk() {
      finishSelection();
    },
    onCancel() { }
  });
};

const openCancelModal = () => {
  Modal.confirm({
    title: () => '¿Estás seguro que deseas cancelar el alta de cargas?',
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => 'Sí',
    cancelText: () => 'No',
    onOk() {
      cancelTicket();
    },
    onCancel() { }
  });
};

const openFinishModal = () => {
  Modal.confirm({
    title: () => '¿Estás seguro que deseas ejecutar el alta de cargas?',
    icon: () => createVNode(ExclamationCircleOutlined),
    okText: () => 'Sí',
    cancelText: () => 'No',
    onOk() {
      finishTicket();
    },
    onCancel() { }
  });
};

const redirectToFile = (url) => {
  window.open(url, '_blank');
};

const attached_documents_history_ref = ref();
const openAttachedDocumentsHistoryModal = (id) => {
  attached_documents_history_ref.value.openModal(id);
};

// * Page Header
const page_header_routes = [
  {
    path: '/altas-cargas',
    breadcrumbName: 'Altas cargas'
  },
  {
    path: '#',
    breadcrumbName: 'Alta de cargas'
  }
];

const state_tag = computed(() => {
  const { states } = ticket_data.value;
  const map = {
    1: 'CREADO',
    7: 'EN CORREDORA',
    8: 'EN ASEGURADORA',
    3: 'EJECUTADO',
    4: 'CANCELADO',
    5: 'EJECUTADO'
  };
  if (files.value.some((f) => f.options.requestCounter > 0))
    return 'EN RESOLICITUD';
  if (states) {
    return map[states[states.length - 1].state.id];
  }
  return 'vacio';
});

// * Stepper State
const stepper_data = computed(() => {
  const data = [
    {
      index: 1,
      title: 'Creado',
      description: ''
    },
    {
      index: 7,
      title: 'En revisión corredora',
      description: ''
    },
    {
      index: 8,
      title: 'En revisión aseguradora',
      description: ''
    },
    {
      index: 3,
      title: 'Ejecutado',
      description: ''
    }
  ];
  let isCancelled = false;
  let current = 0;

  const { states } = ticket_data.value;

  const map = {
    1: 0,
    7: 1,
    8: 2,
    3: 3,
    5: 3
  };

  if (states) {
    states.forEach((state) => {
      const { id } = state.state;
      if (id != 4) {
        data[map[id]].description = parseDate(state.createdAt);
      } else {
        isCancelled = true;
        current = states.length - 2;
      }
    });

    if (!isCancelled) {
      current = states.length - 1;
    }
  }

  return {
    data,
    isCancelled,
    current
  };
});

// * Ticket data
const ticket_details = computed(() => {
  const { fullname, rut, email } = employee_data.value.employee;
  const { startDate, broker, company, insuranceCompany, policies } =
    ticket_data.value;

  return {
    title: 'Datos ticket',
    data: [
      {
        key: 'Ejecutivo',
        value: broker.name
      },
      {
        key: 'Fecha de inicio de vigencia',
        value: moment(startDate).format('DD/MM/YYYY')
      },
      {
        key: 'Nombre completo',
        value: fullname
      },
      {
        key: 'RUT',
        value: rutFormatter.formatRut(rut)
      },
      {
        key: 'Correo',
        value: email
      },
      {
        key: 'Empresa',
        value: company.businessName
      },
      {
        key: 'Aseguradora',
        value: insuranceCompany.businessName
      },
      {
        key: 'Pólizas',
        value: policies.map((p) => p.numberPolicy).join(', ')
      }
    ],
    loadTicket: false
  };
});

// * Comentarios
const comments = ref([]);

const getNewCommment = (value) => {
  comments.value.push({
    description: parseDate(value.description),
    title: value.title,
    author: broker_name.value,
    date: value.description
  });
};

const getCommentsByTicketId = async () => {
  const response_comments = await store
    .dispatch(FETCH_TICKET_COMMENTS_ID, ticket_id)
    .catch((e) => e.response);

  for (let i = 0; i < response_comments.data.length; i++) {
    const element = response_comments.data[i];
    const brokerName = await getBroker(response_comments.data[i].brokerId);
    comments.value.push({
      description: parseDate(element.createdAt),
      title: element.message,
      author: brokerName,
      date: element.createdAt
    });
  }
};

const getBroker = async (id) => {
  const response = await store.dispatch(FETCH_USER_BY_ID, id);
  user_role.value = response.data.body.role.tag;
  return response.data.body.name;
};

// * Historial
const records_data = computed(() => {
  const formatted_states = ticket_data.value.states.map((state) => ({
    title: state.state.tag,
    description: new Date(state.createdAt)
  }));

  const formatted_comments = comments.value.map((comment) => ({
    title: 'Nuevo comentario',
    description: new Date(comment.date)
  }));

  const records = [...formatted_states, ...formatted_comments];

  const ordered_records = records.sort((a, b) => a.description - b.description);

  return {
    title: 'Historial',
    loading: false,
    data: ordered_records.map((record) => ({
      title: record.title,
      description: DateFormmater(record.description)
    }))
  };
});

// * Documentos
const files = ref([]);
const loadingFiles = ref(true);

const getDocumentsByTicketId = async () => {
  loadingFiles.value = true;
  try {
    files.value.length = 0;

    const response_files = await store.dispatch(
      FETCH_FILE_BY_TICKET,
      ticket_id
    );

    for (let i = 0; i < response_files.data.length; i++) {
      const element = response_files.data[i];

      const obj = {
        key: i + 1,
        id: element.id,
        name: element.name,
        company: ticket_data.value.insuranceCompany.businessName,
        status: true,
        options: {
          seeMore: element.ticketFileUrl,
          fileId: element.id,
          canRequest:
            element.fileInsuranceCompanyId != null &&
            state_tag.value === 'EN REVISIÓN CORREDORA',
          requestCounter: element.requestCounter
        }
      };
      files.value.push(obj);
    }

    loadingFiles.value = false;
  } catch (error) {
    console.error(error);
    loadingFiles.value = false;
  }
};

// Casos

const cases = ref([]);

const getAllCasesByTicketId = async () => {
  cases.value = [];

  const response_cases = await store
    .dispatch(FETCH_CASES, ticket_id)
    .catch((e) => e.response);

  for (let i = 0; i < response_cases.data.length; i++) {
    const obj = {
      id: '',
      tag: '',
      state: '',
      typeCaseId: 0
    };

    const caso = response_cases.data[i];

    obj.id = caso.id;
    obj.tag = caso.states
      .sort((a, b) => {
        return b.id - a.id;
      })[0]
      .state.id.toString();
    obj.state = caso.typeCase.tag;
    obj.typeCaseId = caso.typeCaseId;

    cases.value.push(obj);
  }
};

const redirectCase = (value) => {
  const ticketId = value.id;

  const ticketType =
    value.state === 'Out of Time'
      ? 'fuera_de_tiempo'
      : value.state.toLowerCase();

  switch (ticketType) {
    case 'fuera de tiempo: alta individual de cargas':
      router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
      break;
  }
};

// Resolicitud de documentos
const showReapplyDoc = ref(false);
const id_reapply_document = ref();
const reapply_text = ref(
  'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:'
);
const loading_reapply = ref(false);

const openReapplyModal = (id_document) => {
  id_reapply_document.value = id_document;
  showReapplyDoc.value = true;
};

const closeReapplyModal = () => {
  id_reapply_document.value = 0;
  showReapplyDoc.value = false;
  reapply_text.value =
    'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:';
};

const documentReapply = async (id_document) => {
  loading_reapply.value = true;
  try {
    await store.dispatch(PUT_REAPPLY_FILE, {
      id: id_document,
      payload: {
        message: reapply_text.value
      }
    });

    showSucess.value = true;
    success_title.value = 'Mensaje enviado';
    success_message.value =
      'Su solicitud de documento a sido enviada con exito';
    reapply_text.value = '';
  } catch (error) {
    showError.value = true;
    error_title.value = 'Error';
    error_message.value = openNotificationWithIcon(
      'error',
      'Error',
      error.response.data
    );
  }
  loading_reapply.value = false;
  closeReapplyModal();
  getDocumentsByTicketId(ticket_id);
};

const reapplyButtonBlocked = computed(() => {
  const backup_text =
    'Estimado/a te informamos que recepcionamos tu formulario de incorporación y hemos detectado que se encuentra incompleto en la sección:';

  if (reapply_text.value != backup_text && reapply_text.value != '') {
    return false;
  }

  return true;
});

const columnsFiles = [
  {
    dataIndex: 'name'
  },
  {
    dataIndex: 'company'
  },
  {
    dataIndex: 'options',
    slots: { customRender: 'options' }
  }
];

const changeCheckbox = (selectedRowKeys, selectedRows) => {
  idCheckbox.value.length = 0;
  for (let i = 0; i < selectedRows?.length; i++) {
    const element = selectedRows[i];
    idCheckbox.value.push(element.key);
  }
};
const idCheckbox = ref([]);

const getCheckbox = () => {
  if (state_tag.value === 'EN CORREDORA') {
    return {
      disabled: false
    };
  }
  return {
    disabled: true
  };
};
const rowSelectionForm = computed(() => {
  if (state_tag.value === 'EN CORREDORA') {
    return {
      onChange: changeCheckbox,
      getCheckboxProps: getCheckbox,
      selectedRowKeys: idCheckbox.value
    };
  }
  return {
    onChange: changeCheckbox,
    getCheckboxProps: getCheckbox
  };
});

// * Formularios
const finishTicket = async () => {
  loading.value = true;
  try {
    await store.dispatch(POST_FINISH_DEPENDENTS_INCORPORATION, ticket_id);
    await getTicketData();
    openNotificationWithIcon(
      'success',
      'Correcto',
      'Alta de cargas ejecutado correctamente'
    );
  } catch (error) {
    openNotificationWithIcon(
      'error',
      'Error',
      'Ocurrió un error al ejecutar el alta de cargas. Por favor intentalo más tarde.'
    );
  }
  loading.value = false;
};

const finishSelection = async () => {
  loading.value = true;

  if (idCheckbox.value.length != files.value.length) {
    notification('error', 'Error', 'Revise y confirme los archivos adjuntos');
    loading.value = false;
    return;
  }

  try {
    await store.dispatch(
      POST_SEND_TO_INSURANCE_DEPENDENTS_INCORPORATION,
      ticket_id
    );
    await getTicketData();
    openNotificationWithIcon(
      'success',
      'Correcto',
      'Alta de cargas enviado a la aseguradora correctamente.'
    );
  } catch (error) {
    openNotificationWithIcon(
      'error',
      'Error',
      'Ocurrió un error al enviar a la aseguradora. Por favor intentalo más tarde.'
    );
  }
  loading.value = false;
};

const cancelTicket = async () => {
  loading.value = true;

  try {
    await store.dispatch(POST_CANCEL_DEPENDENTS_INCORPORATION, ticket_id);
    await getTicketData();
    openNotificationWithIcon(
      'success',
      'Correcto',
      'Alta de cargas cancelado correctamente.'
    );
  } catch (error) {
    openNotificationWithIcon(
      'error',
      'Error',
      'Ocurrió un error al cancelar el alta de cargas. Por favor intentalo más tarde.'
    );
  }
  loading.value = false;
};

// Obtención de data principal
const ticket_data = ref({});
const getTicketData = () => {
  store
    .dispatch(FETCH_TICKET_ID, ticket_id)
    .then((resp) => {
      const { data } = resp;
      ticket_data.value = data;

      getBroker(ticket_data.value.broker.id).then((resp) => {
        broker_name.value = resp;
      });

      getEmployeeIncome(
        ticket_data.value.employee.id,
        ticket_data.value.company.id
      );

      getDocumentsByTicketId();
    })
    .catch((err) => {
      console.log(err);
    });
};

const getEmployeeData = () => {
  store
    .dispatch(FETCH_EMPLOYEE_TICKET, ticket_id)
    .then((resp) => {
      employee_data.value = resp.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const alta_edit_object = computed(() => {
  return {
    startDate: ticket_data.value.startDate,
    firstName: ticket_data.value.employee.firstName,
    secondName: ticket_data.value.employee.secondName,
    firstSurname: ticket_data.value.employee.firstSurname,
    secondSurname: ticket_data.value.employee.secondSurname,
    birthdayDate: ticket_data.value.employee.birthdayDate,
    rut: ticket_data.value.employee.rut,
    email: ticket_data.value.employee.email,
    incomeAmount: income_amount.value
  };
});

const income_amount = ref();
const getEmployeeIncome = (employeeId, companyId) => {
  store
    .dispatch(FETCH_EMPLOYEE_COMPANY_RENT, {
      employeeId: employeeId,
      companyId: companyId
    })
    .then((resp) => {
      if (resp.data != null) {
        income_amount.value = resp.data.incomeAmount;
      } else {
        income_amount.value = 0;
      }
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.messsage);
    });
};

const employee_rent_required = ref(false);
const getRentRequirement = () => {
  store
    .dispatch(FETCH_TICKET_INCOME, ticket_id)
    .then((resp) => {
      employee_rent_required.value = resp.data.isIncomeRequired;
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.messsage);
    });
};

const edit_modal_ref = ref();
const openAltaEditModal = () => {
  edit_modal_ref.value.openModal();
};

onMounted(() => {
  store.dispatch(FETCH_TICKET, route.params.id);
  getTicketData();
  getEmployeeData();
  getCommentsByTicketId();
  getRentRequirement();
  getAllCasesByTicketId();
  store.dispatch(FETCH_TICKET_DEPENDANTS_INCORPORATIONS, route.params.id);
});
</script>

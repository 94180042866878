import config from '../config';

const appConfig = config();

const apiCompany = appConfig.company_client_prefix;
const { v1, v2 } = appConfig.apiVersion;
const network = (client) => ({
  // company Client
  apiCompanyClient: () => client.get(`${apiCompany}${v1}`),
  Company: () => client.get(`${apiCompany}${v1}/company`),
  CompanyFetchTableData: (payload) =>
    client.get(`${apiCompany}${v1}/company${payload}`),
  CompanyCreate: (payload) =>
    client.post(`${apiCompany}${v1}/company`, payload),
  EditCompany: (payload, id) =>
    client.patch(`${apiCompany}${v1}/company/${id}`, payload),
  deleteCompany: (payload) =>
    client.delete(`${apiCompany}${v1}/company/${payload}`),
  CompanyEdit: (payload, id) =>
    client.put(`${apiCompany}${v1}/groupCompany/${id}`, payload),
  CompanyDelete: (id) => client.delete(`${apiCompany}${v1}/groupCompany/${id}`),
  CompanyGroup: (payload) =>
    client.post(`${apiCompany}${v1}/groupCompany`, payload),
  CompanyById: (payload) => client.get(`${apiCompany}${v1}/company/${payload}`),
  // --> Obtener usuario por id y compañia id
  UserByCompanyId: (company_id, employee_id) =>
    client.get(
      `${apiCompany}${v1}/company/${company_id}/employees/${employee_id}`
    ),
  getCompanyEmailConfigurations: (companyId) =>
    client.get(
      `${apiCompany}${v2}/companies/${companyId}/email-configurations`
    ),
  patchCompanyEmailConfiguration: (companyId, emailConfigurationId, data) =>
    client.patch(
      `${apiCompany}${v2}/companies/${companyId}/email-configurations/${emailConfigurationId}`,
      data
    ),
  postCompanyEmailConfiguration: (companyId, data) =>
    client.post(
      `${apiCompany}${v2}/companies/${companyId}/email-configurations`,
      data
    ),

  // Nofification
  CompanyNotificationsById: (payload) =>
    client.get(`${apiCompany}${v1}/company/${payload}/notification-schedules`),
  InsuranceCompanyNotificationsById: (payload) =>
    client.get(
      `${apiCompany}${v1}/insuranceCompany/${payload}/notification-schedules`
    ),
  CompanyPutNotificationsById: (payload, id_company, id_notification) =>
    client.put(
      `${apiCompany}${v1}/company/${id_company}/notification-schedules/${id_notification}`,
      payload
    ),
  InsuranceCompanyPutNotificationsById: (
    payload,
    id_company,
    id_notification
  ) =>
    client.put(
      `${apiCompany}${v1}/insuranceCompany/${id_company}/notification-schedules/${id_notification}`,
      payload
    ),

  // company Executives
  companyGetExecutives: (payload) =>
    client.get(`${apiCompany}${v1}/company/${payload}/executive`),
  companyGetExecutive: ({ companyId, executiveId, params }) => {
    if (executiveId) {
      return client.get(
        `${apiCompany}${v1}/company/${companyId}/executive/${executiveId}`
      );
    } else {
      return client.get(`${apiCompany}${v1}/company/${companyId}/executive`, {
        params
      });
    }
  },
  createCompanyExecutive: (payload, id) =>
    client.post(`${apiCompany}${v1}/company/${id}/executive`, payload),
  editExecutive: (payload, idCompany, idExecutive) =>
    client.put(
      `${apiCompany}${v1}/company/${idCompany}/executive/${idExecutive}`,
      payload
    ),

  // ---holding---
  holdingfetch: () => client.get(`${apiCompany}${v1}/holding`),
  holdingFetchTableData: (payload) =>
    client.get(`${apiCompany}${v1}/holding${payload}`),
  holding: (payload) => client.post(`${apiCompany}${v1}/holding`, payload),
  holdingEdit: (id, payload) =>
    client.patch(`${apiCompany}${v1}/holding/${id}`, payload),
  deleteHolding: (payload) =>
    client.delete(`${apiCompany}${v1}/holding/${payload}`),
  companiesByEmployee: (payload) =>
    client.get(`${apiCompany}${v1}/employee/companies${payload}`),
  excelEmployeeCompany: (payload) =>
    `${appConfig.baseURL}${apiCompany}${v1}/massiveRegistration/employee-to-excel/company/${payload}`,

  // Subholding
  subholdingFetch: () => client.get(`${apiCompany}${v1}/subHolding`),
  subholdingFetchTableData: (payload) =>
    client.get(`${apiCompany}${v1}/subHolding${payload}`),
  createSubHolding: (payload) =>
    client.post(`${apiCompany}${v1}/subHolding`, payload),
  editSubHolding: (id, payload) =>
    client.patch(`${apiCompany}${v1}/subHolding/${id}`, payload),
  deleteSubholding: (payload) =>
    client.delete(`${apiCompany}${v1}/subHolding/${payload}`),

  // Group Company
  companyInsuranceCompany: (payload) =>
    client.get(`${apiCompany}${v1}/policy/company/${payload}/insuranceCompany`),
  policityInsuranceCompany: (company, insurance, rut) =>
    client.get(
      `${apiCompany}${v1}/policy/company/${company}/insuranceCompany/${insurance}?rut=${
        rut ? rut : ''
      }`
    ),
  companyGroupCompany: (payload) =>
    client.get(`${apiCompany}${v1}/company/${payload}/groupCompany`),
  companyGroupPremiumCalc: (groupId, policyId) =>
    client.get(
      `${apiCompany}${v1}/groupCompany/${groupId}/policies/${policyId}/premium-calc`
    ),
  configureCompanyGroupPremiumCalc: (groupId, coverageId, payload) =>
    client.put(
      `${apiCompany}${v1}/groupCompany/${groupId}/coverages/${coverageId}/premium-calc`,
      payload
    ),
  employeeSummary: () => client.get(`${apiCompany}${v1}/employee/summary`),
  employeeCompanies: (payload) =>
    client.get(`${apiCompany}${v1}/employee/${payload}/companies`),

  employeeInsurance: (payload) =>
    client.get(
      `${apiCompany}${v1}/form/modification/individual/insurance-companies?rut=${payload}`
    ),
  getInsuranceCompanyFormType: (payload) =>
    client.get(
      `${apiCompany}${v2}/insurance-companies/${payload}/form-configuration`
    ),
  saveInsuranceCompanyFormType: (id, payload) =>
    client.put(
      `${apiCompany}${v2}/insurance-companies/${id}/form-configuration`,
      payload
    ),
  dataEmployee: (payload) =>
    client.get(`${apiCompany}${v1}/employee/${payload}`),
  currentEmployeePoliciesAndCoverages: (
    id,
    companyId,
    insuranceCompanyId,
    policyStatus,
    coverageStatus
  ) =>
    client.get(
      `${apiCompany}${v1}/employee/${id}/policies?companyId=${companyId}&insuranceCompanyId=${insuranceCompanyId}&policyStatus=${policyStatus}&coverageStatus=${coverageStatus}`
    ),
  patchDataEmployee: (id, payload) =>
    client.patch(`${apiCompany}${v1}/employee/${id}`, payload),
  saveEmployeeCompanyRent: (employeeId, payload) =>
    client.put(`${apiCompany}${v1}/employee/${employeeId}/income`, payload),
  employeeCompanyRent: (employeeId, companyId) =>
    client.get(
      `${apiCompany}${v1}/employee/${employeeId}/income?companyId=${companyId}`
    ),

  insuranceCompanyById: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/${payload}`),

  // --> Obtener formatos por compania aseguradora
  insuranceCompanyPayrollsById: (payload) =>
    client.get(
      `${apiCompany}${v1}/insuranceCompany/${payload}/payroll-formats`
    ),
  // --> Obtener compañias aseguradoras por id del holding
  insuranceCompanyByHoldingId: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/?holdingId=${payload}`),
  // --> Obtener compañias aseguradoras por id del subholding
  insuranceCompanyBySubholdingId: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/?subHoldingId=${payload}`),
  // --> Obtener compañias aseguradoras por id de la compañia
  insuranceCompanyByCompanyId: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/?companyId=${payload}`),
  // Mailbox Insurance company
  insuranceGetMailbox: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/${payload}/mailbox/`),
  insurancePostMailbox: (payload, id) =>
    client.post(`${apiCompany}${v1}/insuranceCompany/${id}/mailbox/`, payload),
  insurancePutMailbox: (payload, idCompany, idMailbox) =>
    client.put(
      `${apiCompany}${v1}/insuranceCompany/${idCompany}/mailbox/${idMailbox}`,
      payload
    ),

  // Manual Insurance company
  insuranceGetManual: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/${payload}`),
  insurancePostManual: (id, payload) =>
    client.post(
      `${apiCompany}${v1}/insuranceCompany/${id}/handbooks/`,
      payload
    ),
  insuranceDeleteManual: (id_insurance, id_handbook) =>
    client.delete(
      `${apiCompany}${v1}/insuranceCompany/${id_insurance}/handbooks/${id_handbook}`
    ),
  insurancePutManual: (id_insurance, id_handbook, payload) =>
    client.put(
      `${apiCompany}${v1}/insuranceCompany/${id_insurance}/handbooks/${id_handbook}`,
      payload
    ),
  getCompanyLogo: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/${payload}/logo`),
  saveCompanyLogo: (payload, id) =>
    client.put(`${apiCompany}${v1}/insuranceCompany/${id}/logo`, payload),
  getCompanyDigitalFormConsentTexts: (payload) =>
    client.get(`${apiCompany}${v1}/insuranceCompany/${payload}/consents`),
  putCompanyDigitalFormConsentTexts: (id, payload) =>
    client.put(`${apiCompany}${v1}/insuranceCompany/${id}/consents`, payload),
  companyByUserId: (payload) =>
    client.get(`${apiCompany}${v1}/groupCompany/getCompanyByUserId/${payload}`),
  policy: (payload) =>
    client.get(`${apiCompany}${v1}/groupCompany/${payload}/policy`),
  getPolicies: (payload) => client.get(`${apiCompany}${v1}/policy${payload}`),
  policyGroupCompany: (payload) =>
    client.get(`${apiCompany}${v1}/policy/${payload}/group-companies`),
  postPolicy: (payload) => client.post(`${apiCompany}${v1}/policy`, payload),
  getPolicy: (payload) => client.get(`${apiCompany}${v1}/policy/${payload}`),
  putPolicy: (policyId, payload) =>
    client.put(`${apiCompany}${v1}/policy/${policyId}`, payload),
  deletePolicy: (payload) =>
    client.delete(`${apiCompany}${v1}/policy/${payload}`),
  getPolicyCoverages: (payload) =>
    client.get(`${apiCompany}${v1}/policy/${payload}/coverages`),
  getCoverages: (payload) =>
    client.get(`${apiCompany}${v1}/coverage${payload}`),
  postCoverage: (payload) =>
    client.post(`${apiCompany}${v1}/coverage`, payload),
  putCoverage: (id_coverage, payload) =>
    client.put(`${apiCompany}${v1}/coverage/${id_coverage}`, payload),
  deleteCoverage: (payload) =>
    client.delete(`${apiCompany}${v1}/coverage/${payload}`),
  getCoverage: (payload) =>
    client.get(`${apiCompany}${v1}/coverage/${payload}`),
  getDependent: (payload) =>
    client.get(`${apiCompany}${v1}/employee/${payload}/dependents`),
  deleteDependent: (payload) =>
    client.delete(`${apiCompany}${v1}/dependents/${payload}`),
  dependent: (payload) => client.post(`${apiCompany}${v1}/dependents`, payload),
  getDependents: (query) =>
    client.get(`${apiCompany}${v1}/dependents`, { params: query }),

  // mantenedor cargas
  updateEmployeeDependent: (employeeId, dependentId, payload) =>
    client.patch(
      `${apiCompany}${v1}/employee/${employeeId}/dependents/${dependentId}`,
      payload
    ),
  deleteEmployeeLoads: (employeeId, dependentId) =>
    client.delete(
      `${apiCompany}${v1}/employee/${employeeId}/dependents/${dependentId}`
    ),

  // Mailbox
  getCompanyMailbox: (payload) =>
    client.get(`${apiCompany}${v1}/company/${payload}/mailbox/`),
  postCompanyMailbox: (id, payload) =>
    client.post(`${apiCompany}${v1}/company/${id}/mailbox`, payload),
  putCompanyMailbox: (id_company, id_mailbox, payload) =>
    client.put(
      `${apiCompany}${v1}/company/${id_company}/mailbox/${id_mailbox}`,
      payload
    ),

  employeesByCompany: (payload, params) =>
    client.get(
      `${apiCompany}${v1}/employee/company/${payload}?params=${
        params ? params : ''
      }`
    ),
  // ---user---

  userCompanyClient: (payload) =>
    client.get(`${apiCompany}${v1}/user/`, payload),

  userCompanyClientupdate: (payload) =>
    client.patch(`${apiCompany}${v1}/user/`, payload),
  userStateClient: (payload) =>
    client.patch(`${apiCompany}${v1}/user/´${payload}´/active_user`),
  // ---auth---
  companyClientRegister: () => client.post(`${apiCompany}${v1}/auth/register`),
  companyClientLogin: () => client.post(`${apiCompany}${v1}/auth/login`),
  firstUserClient: () =>
    client.post(`${apiCompany}${v1}/auth/registerFirstTime`),
  // ---reset---
  ClientResetEmail: () => client.post(`${apiCompany}${v1}/reset`),
  ClientReset: (payload) =>
    client.post(`${apiCompany}${v1}/${payload}/${payload}`),

  // CONFIG POLICY INVOICE PAYMENT
  postConfigPolicy: (id, payload) =>
    client.post(
      `${apiCompany}${v1}/company/${id}/policy-configurations`,
      payload
    ),
  getPolicyInvoicePayment: () =>
    client.get(`${apiCompany}${v1}/policy-configuration/payments`),
  getCutOffs: () =>
    client.get(`${apiCompany}${v1}/policy-configuration/benefict-cutoffs`),
  getAllConfigPolicy: (company_id, params) =>
    client.get(
      `${apiCompany}${v1}/company/${company_id}/policy-configurations${params}`
    ),
  putConfigPolicy: (payload) =>
    client.put(
      `${apiCompany}${v1}/company/${payload.companyId}/policy-configurations/${payload.configId}`,
      payload
    ),
  deleteClientExecutiveInsuranceCompany: (payload) =>
    client.delete(
      `${apiCompany}${v1}/company/${payload.companyId}/executive/${payload.executiveId}`
    ),
  getCompanyEmployeeCoverage: (companyId, employeeId, queryParam) =>
    client.get(
      `${apiCompany}${v1}/company/${companyId}/employees/${employeeId}/coverages${
        queryParam ? queryParam : ''
      }`
    ),
  companiesStockDeregistration: (id, payload) =>
    client.post(
      `${apiCompany}${v2}/companies/${id}/stock-deregistration`,
      payload
    ),
  companyPoliciesConfiguration: (id, params) =>
    client.get(`${apiCompany}${v2}/companies/${id}/policies${params}`),
  // mantenedor de perfil colaborador
  patchEmployeeCoverage: (idEmployeeCoverage, payload) =>
    client.patch(
      `${apiCompany}${v2}/employee-coverages/${idEmployeeCoverage}/cancel`,
      payload
    ),
  deleteEmployeeCoverage: (idEmployeeCoverage) =>
    client.delete(
      `${apiCompany}${v2}/employee-coverages/${idEmployeeCoverage}`
    ),
  patchEmployeeCoverageRequestDate: (id, payload) =>
    client.patch(`${apiCompany}${v2}/employee-coverages/${id}`, payload),
  getAllBrokers: () => client.get(`${apiCompany}${v2}/brokers`),
  getBrokerByCompany: (payload) =>
    client.get(`${apiCompany}${v1}/company/${payload}/brokers`),
  createBrokerToCompany: (id, payload) =>
    client.post(`${apiCompany}${v1}/company/${id}/brokers`, payload),
  deleteBrokerToCompany: (idCompany, companyBrokerId) =>
    client.delete(
      `${apiCompany}${v1}/company/${idCompany}/brokers/${companyBrokerId}`
    ),
  getBajaStockFormat: (companyId, insuranceCompanyId) => {
    return `${appConfig.baseURL}${apiCompany}${v2}/companies/${companyId}/stock-deregistration/download-format?insurance=${insuranceCompanyId}`;
  },
  uploadExcelToBajaStock: (idCompany, payload) =>
    client.post(
      `${apiCompany}${v2}/companies/${idCompany}/stock-deregistration`,
      payload
    )
});

export default network;

<template>
  <div>
    <a-row justify="space-between" align="middle">
      <a-col :span="8">
        <a-space direction="vertical">
          <a-typography-title :level="4">Datos colaborador</a-typography-title>
        </a-space>
      </a-col>
    </a-row>
    <a-row class="mb-4">
      <a-col :span="24">
        <div>
          <a-card :body-style="'padding:0;'" :bordered="false">
            <a-descriptions class="" bordered>
              <a-descriptions-item label="RUT" :span="3">
                {{ rutToolBox.formatRut(employee_data.rut) }}
              </a-descriptions-item>
              <a-descriptions-item label="Nombre completo" :span="3">
                {{ employee_data.fullname }}
              </a-descriptions-item>
              <a-descriptions-item label="Grupos" :span="3">
                {{ employee_data.groupCompanies.map((g) => g.name).join(',') }}
              </a-descriptions-item>
              <a-descriptions-item label="Fecha de nacimiento" :span="3">
                {{
                  employee_data.birthdayDate
                    ? dateFormatter(employee_data.birthdayDate).split(' ')[0]
                    : 'Sin información'
                }}
              </a-descriptions-item>
              <a-descriptions-item label="Correo" :span="3">
                {{ employee_data.email }}
              </a-descriptions-item>
              <a-descriptions-item label="Fecha creación" :span="3">
                {{ formater(employee_data?.createdAt).substring(0, 10) }}
              </a-descriptions-item>
              <a-descriptions-item label="Fecha actualización" :span="3">
                {{ formater(employee_data?.updatedAt).substring(0, 10) }}
              </a-descriptions-item>
              <a-descriptions-item label="Estado" :span="3">
                <a-tag :color="getColorByState(employee_data?.status)">
                  {{ getTagText(employee_data?.status).toUpperCase() }}
                </a-tag>
              </a-descriptions-item>
            </a-descriptions>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <a-row justify="space-between" align="middle">
      <a-col :span="8">
        <a-space direction="vertical">
          <a-typography-title :level="4">Coberturas</a-typography-title>
        </a-space>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" align="middle" class="mb-2">
      <a-col :span="12">
        <a-radio-group :value="type_selection" @change="handleChange">
          <a-radio-button style="margin: 1px; z-index: 0" value="Vigente"
            >Vigente</a-radio-button
          >
          <a-radio-button value="No vigente">No Vigente</a-radio-button>
        </a-radio-group>
      </a-col>
    </a-row>

    <profile-collaborator-table
      :columns="columns"
      title="Coberturas"
      :data="employeeCoverages"
      :type-selection="type_selection"
      @patchedEmployecoverage="fetchCoverageEmployee"
    />

    <a-row justify="space-between" align="middle" class="mt-3">
      <a-col :span="8">
        <a-space direction="vertical">
          <a-typography-title :level="4">Cargas</a-typography-title>
        </a-space>
      </a-col>
    </a-row>
    <profile-collaborator-table
      :columns="columnsCoverage"
      title="Cargas"
      :data="cargas"
      @patchedEmployeeDependent="fetchEmployeeDependents"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  FETCH_EMPLOYEE,
  GET_FULL_NAME,
  GET_DEPENDENTS,
  FETCH_DEPENDENTS,
  FETCH_USER_BY_COMPANY,
  GET_USER_BY_COMPANY,
  GET_COVERAGE_EMPLOYEE,
  FETCH_COVERAGE_EMPLOYEE
} from '@/store/types';
import getTagColor from '@/utils/getTagColor';
import parseDate from '@/utils/dateFormatter';
import rutToolBox from '@/utils/Ruts';
import CollaboratorProfileTableCyC from '@/components/clients/stock/CollaboratorProfileTableCyC.vue';
import getTagText from '@/utils/getTagText';
import notification from '@/utils/notifications';
import dateFormatter from '@/utils/dateFormatter';

export default {
  name: 'ProfileCollaborator',
  emit: ['Poliza'],
  components: {
    'profile-collaborator-table': CollaboratorProfileTableCyC
  },
  setup() {
    const formater = parseDate;
    const store = useStore();
    const route = useRoute();
    const type_selection = ref('');
    const { id, colaborador } = route.params;

    const name = computed(() => store.getters[GET_FULL_NAME]);
    const employeeCoverages = computed(
      () => store.getters[GET_COVERAGE_EMPLOYEE]
    );
    const cargas = computed(() => store.getters[GET_DEPENDENTS]);

    const employee_data = computed(() => store.getters[GET_USER_BY_COMPANY]);

    // * Colores por estado
    const getColorByState = (value) => {
      return getTagColor(value);
    };

    const columns = [
      {
        title: 'Aseguradora',
        dataIndex: 'insuranceCompany.businessName',
        key: 'insuranceCompany.businessName'
      },
      {
        title: 'N° Póliza',
        dataIndex: 'policy.numberPolicy',
        key: 'policy.numberPolicy'
      },
      {
        title: 'Grupo',
        dataIndex: 'groupCompany.name',
        key: 'groupCompany.name'
      },
      {
        title: 'Cobertura',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Vigencia',
        dataIndex: 'vigencia',
        key: 'vigencia'
      },
      {
        title: 'Acción',
        dataIndex: 'id',
        key: 'id',
        slots: {
          customRender: 'action'
        }
      }
    ];
    const columnsCoverage = [
      {
        title: 'Carga',
        dataIndex: 'id',
        key: 'id'
      },
      {
        title: 'RUT',
        dataIndex: 'rut',
        key: 'rut'
      },
      {
        title: 'Nombre completo',
        dataIndex: 'fullName',
        key: 'fullName'
      },
      {
        title: 'Fecha de nacimiento',
        dataIndex: 'birthdayDate',
        key: 'birthdayDate'
      },
      {
        title: 'Vigencia',
        dataIndex: 'vigencia',
        key: 'vigencia'
      },
      {
        title: 'Acción',
        dataIndex: 'id',
        key: 'id',
        width: '173px',
        slots: {
          customRender: 'action'
        }
      }
    ];
    const handleChange = async (val) => {
      type_selection.value = val.target.value;
      if (
        type_selection.value == 'Vigente' &&
        employeeCoverages.value.filter((f) => f.status == 'ACTIVE')
      ) {
        employeeCoverages.value.filter((f) => f.status == 'ACTIVE');
        return await store.dispatch(FETCH_COVERAGE_EMPLOYEE, {
          companyId: id,
          employeeId: colaborador,
          queryParam: '?status=ACTIVE'
        });
      } else if (
        type_selection.value == 'No vigente' &&
        employeeCoverages.value.filter((f) => f.status == 'INACTIVE')
      ) {
        employeeCoverages.value.filter((f) => f.status == 'INACTIVE');
        return await store.dispatch(FETCH_COVERAGE_EMPLOYEE, {
          companyId: id,
          employeeId: colaborador,
          queryParam: '?status=INACTIVE'
        });
      }
    };

    const fetchCoverageEmployee = () => {
      store
        .dispatch(FETCH_COVERAGE_EMPLOYEE, {
          companyId: id,
          employeeId: colaborador,
          queryParam: '?status=ACTIVE'
        })
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
        });
    };

    const fetchEmployeeDependents = () => {
      store
        .dispatch(FETCH_DEPENDENTS, route.params.colaborador)
        .catch((err) => {
          notification('error', 'Error', err.response.data.message);
        });
    };

    onMounted(() => {
      store.dispatch(FETCH_EMPLOYEE, route.params.colaborador);
      store.dispatch(FETCH_USER_BY_COMPANY, {
        company_id: route.params.id,
        employee_id: route.params.colaborador
      });
      fetchCoverageEmployee();
      fetchEmployeeDependents();

      type_selection.value = 'Vigente';
    });

    return {
      // current_tab,
      formater,
      columnsCoverage,
      getColorByState,
      cargas,
      columns,
      name,
      employeeCoverages,
      employee_data,
      rutToolBox,
      getTagText,
      type_selection,
      handleChange,
      fetchCoverageEmployee,
      fetchEmployeeDependents,
      dateFormatter
    };
  }
};
</script>

<style></style>

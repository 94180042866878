<template>
  <div>
    <page-header :breadcrumRoutes="routes" title="Formulario alta individual" />

    <a-form name="form" layout="vertical" ref="formRef" :model="formState" :rules="form_rules">
      <a-row type="flex" class="mb-2" :gutter="20">
        <a-col flex="auto">
          <a-card>
            <a-row>
              <a-form-item label="Envío de información a" required name="typeform">
                <a-radio-group name="radioGroup" v-model:value="formState.typeform">
                  <a-radio value="1">Colaborador</a-radio>
                  <a-radio value="2">Compañía</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
        <a-col flex="auto">
          <a-card>
            <a-row>
              <a-form-item label="Inicio de vigencia" required name="validity_date">
                <a-date-picker placeholder="DD-MM-AAAA" v-model:value="formState.validity_date" :format="DATE_FORMAT" />
              </a-form-item>
            </a-row>
          </a-card>
        </a-col>
      </a-row>

      <a-card class="mt-3">
        <a-row>
          <a-col>
            <a-typography-title class="text-gray-8" :level="4">
              Datos colaborador
            </a-typography-title>
          </a-col>
        </a-row>
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <!-- RUT -->
            <a-form-item ref="rut" label="RUT" name="rut" has-feedback>
              <a-input placeholder="RUT" maxLength="12" v-model:value="formState.rut" @keyup="formatRuts" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="firstName" label="Primer nombre" name="firstName" has-feedback>
              <a-input placeholder="Primer nombre" v-model:value="formState.firstName" @keydown.space.prevent
                :maxlength="40" :disabled="collaborator_selects_disabled" :loading="collaborator_selects_disabled" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="secondName" label="Segundo nombre" name="secondName" has-feedback>
              <a-input placeholder="Segundo nombre" v-model:value="formState.secondName" @keydown.space.prevent
                :maxlength="40" :disabled="collaborator_selects_disabled" :loading="collaborator_selects_disabled" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="firstSurname" label="Primer apellido" name="firstSurname" has-feedback>
              <a-input placeholder="Primer apellido" v-model:value="formState.firstSurname" @keydown.space.prevent
                :maxlength="40" :disabled="collaborator_selects_disabled" :loading="collaborator_selects_disabled" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="secondSurname" label="Segundo apellido" name="secondSurname" has-feedback>
              <a-input placeholder="Segundo apellido" v-model:value="formState.secondSurname" @keydown.space.prevent
                :maxlength="40" :disabled="collaborator_selects_disabled" :loading="collaborator_selects_disabled" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="email" label="Correo" name="email" has-feedback>
              <a-input placeholder="Correo electrónico" v-model:value="formState.email"
                :disabled="collaborator_selects_disabled" :loading="collaborator_selects_disabled" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-row>
          <a-col>
            <a-typography-title class="text-gray-8" :level="4">
              Datos corporativos
            </a-typography-title>
          </a-col>
        </a-row>
        <a-row :gutter="16" type="flex">
          <a-col :span="8">
            <a-form-item ref="company" label="Empresa" name="company" has-feedback>
              <a-select :disabled="inputs_lock_state[0].disabled" placeholder="company" show-search
                optionFilterProp="label" v-model:value="formState.company" :options="companies">
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item ref="insurance" label="Aseguradora" name="insurance" has-feedback>
              <a-select :disabled="inputs_lock_state[2].disabled" :loading="inputs_lock_state[2].loading"
                placeholder="insurance" v-model:value="formState.insurance" :options="insurance_companies">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-card>

      <a-card class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Pólizas y coberturas
        </a-typography-title>
        <div style="display: flex; flex-direction: column; gap: 16px">
          <a-row :gutter="16" v-if="
            valid_rut &&
            formState.rut !== '' &&
            employeeId !== '' &&
            formState.company !== '' &&
            formState.insurance !== ''
          ">
            <a-col :span="8">
              <a-button @click="openPolicyCoverage" id="coverages_and_policies">
                Coberturas vigentes
                <a-tag color="geekblue" class="ml-2 mr-0">{{
                  current_coverages.length
                  }}</a-tag>
              </a-button>
              <policy-coverages ref="policy_coverages_modal_ref" v-model="policy_status"
                :loading="loading_policy_coverages" :currentCoverages="current_coverages"
                :notCurrentCoverages="not_current_coverages" />
            </a-col>
          </a-row>
          <a-row :gutter="16" align="middle">
            <a-col :span="8">
              <a-form-item ref="currentPolicy" label="Pólizas" name="currentPolicy" has-feedback>
                <a-select :disabled="inputs_lock_state[3].disabled" :loading="inputs_lock_state[3].loading"
                  v-model:value="formState.currentPolicy" :options="company_polycies" mode="multiple"
                  @change="handleAddPolicy" allowClear>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row v-for="(policy, index) in formState.policies" :key="index" align="middle" :gutter="16">
            <a-col :span="8">
              <a-form-item :name="['policies', index, 'groupCompanies']"
                :label="`Grupos de póliza ${policy.numberPolicy}`" style="display: flex">
                <a-select v-model:value="policy.groupCompanies" :options="policy.groupOptions" mode="multiple"
                  allowClear>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col>
              <a-button danger @click="handleDeletePolicy(policy.policyId)"><template #icon>
                  <DeleteOutlined />
                </template></a-button>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="8">
              <template v-if="
                formState.typeform == 1 &&
                formState.policies.length > 0 &&
                insurance_form_type !== 'DIGITAL_FORM'
              ">
                <a-form-item name="documents" :label="insurance_companies.find(
                  (x) => x.value == formState.insurance
                )?.label
                  ">
                  <a-checkbox-group v-model:value="formState.documents">
                    <div v-for="d in insurance_documents" :key="d.value">
                      <a-checkbox :value="d.value">
                        {{ d.label }}
                      </a-checkbox>
                    </div>
                  </a-checkbox-group>
                </a-form-item>
              </template>
              <template v-if="
                formState.typeform == 2 &&
                formState.insurance !== null &&
                formState.insurance !== ''
              ">
                <a-row :gutter="[24, 8]" type="flex">
                  <a-col>
                    <a-form-item name="file_list" :label="insurance_companies.find(
                      (x) => x.value == formState.insurance
                    )?.label
                      ">
                      <a-upload :file-list="formState.file_list" :multiple="true" :remove="handleRemove"
                        :before-upload="beforeUpload">
                        <a-button>
                          <upload-outlined></upload-outlined>
                          Subir archivo
                        </a-button>
                      </a-upload>
                    </a-form-item>
                  </a-col>
                </a-row>
              </template>
            </a-col>
          </a-row>
        </div>
      </a-card>

      <Transition>
        <a-card class="mt-3" v-if="formState.typeform == 1">
          <a-row :gutter="32">
            <a-col :span="12">
              <a-typography-title class="text-gray-8" :level="4">
                Mensaje a colaborador
              </a-typography-title>
              <a-form-item ref="message" label="Escribe mensaje para colaborador" name="message" has-feedback>
                <a-textarea v-model:value="formState.message" placeholder="Escribe aquí..." :rows="8"
                  :maxlength="3000" />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-row :gutter="[24, 8]" type="flex">
                <a-form-item name="optional_files" label="Archivos opcionales">
                  <a-upload :file-list="formState.optional_files" :multiple="true" :remove="handleRemoveOptional"
                    :before-upload="beforeUploadOptional" accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx">
                    <a-button>
                      <upload-outlined></upload-outlined>
                      Subir archivo
                    </a-button>
                  </a-upload>
                </a-form-item>
              </a-row>
            </a-col>
          </a-row>
        </a-card>
      </Transition>

      <a-card v-if="formState.typeform == 2" class="mt-3">
        <a-typography-title class="text-gray-8" :level="4">
          Cargas
        </a-typography-title>
        <div>
          <a-empty v-if="formState.dependents.length === 0" description="No has agregado cargas" />
          <template v-for="(dependent, dependentIndex) in formState.dependents" :key="dependentIndex">
            <a-row type="flex" justify="space-between" align="middle">
              <a-col>
                <a-typography-title :level="5" style="margin: 0">Carga {{ dependentIndex + 1 }}</a-typography-title>
              </a-col>
              <a-col>
                <a-button danger size="small" @click="() => handleDeleteDependent(dependentIndex)">Eliminar</a-button>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16" style="margin-top: 16px">
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'rut']" label="Rut" :rules="[
                  { validator: rutValidator },
                  { required: true, message: 'El rut es obligatorio.' }
                ]">
                  <a-input :value="dependent.rut" @change="(e) =>
                      (dependent.rut = rutHelper.formatRut(e.target.value))
                    " />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'dateOfBirth']" label="Fecha de nacimiento" :rules="{
                  required: true,
                  validator: checkDate,
                  trigger: 'change'
                }">
                  <a-input v-model:value="dependent.dateOfBirth" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16">
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'firstName']" label="Primer nombre" :rules="{
                  required: true,
                  message: 'El primer nombre es obligatorio.'
                }">
                  <a-input v-model:value="dependent.firstName" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'secondName']" label="Segundo nombre">
                  <a-input v-model:value="dependent.secondName" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16">
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'firstSurname']" label="Apellido paterno" :rules="{
                  required: true,
                  message: 'El primer apellido es obligatorio.'
                }">
                  <a-input v-model:value="dependent.firstSurname" />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'secondSurname']" label="Apellido materno">
                  <a-input v-model:value="dependent.secondSurname" />
                </a-form-item>
              </a-col>
            </a-row>
            <a-row type="flex" :gutter="16">
              <a-col :span="12">
                <a-form-item :name="['dependents', dependentIndex, 'relationship']" label="Relación" :rules="{
                  required: true,
                  message: 'El tipo de relación es obligatorio.'
                }">
                  <a-select v-model:value="dependent.relationship" :options="relationOptions"
                    placeholder="Ingrese relación">
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </div>
        <a-row type="flex" justify="start">
          <a-button @click="handleAddDependent">Agregar carga</a-button>
        </a-row>
      </a-card>

      <a-form-item class="mt-2">
        <a-row type="flex" class="justify-content-end">
          <a-col>
            <a-button size="large" class="px-4" type="primary" @click="onSubmitForm" block>Siguiente
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <!-- Modal mostrando informacion de lo que escribio el usuario -->
    <a-modal v-model:visible="resume_modal.visibility" title="Resumen de alta individual" @cancel="closeResumeModal"
      :closable="!resume_modal.boton_loading" :maskClosable="false">
      <template #footer>
        <a-row type="flex" justify="end">
          <a-col>
            <a-space>
              <a-button @click="closeResumeModal" :disabled="resume_modal.boton_loading">
                Cancelar
              </a-button>
              <a-button key="submit" type="primary" @click="createAlta" :loading="resume_modal.boton_loading">
                Enviar
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>

      <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5">
        Revisa que los datos ingresados esten correctos.
      </a-typography-title>
      <a-descriptions class="mb-4" bordered>
        <a-descriptions-item label="Inicio de vigencia" :span="3">
          {{ resume_modal.data.date }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions class="mb-4" title="Datos corporativos" bordered>
        <a-descriptions-item label="Aseguradora" :span="3">
          {{ resume_modal.data.insurer }}
        </a-descriptions-item>
        <a-descriptions-item label="Empresa" :span="3">
          {{ resume_modal.data.company }}
        </a-descriptions-item>
        <a-descriptions-item label="Pólizas" :span="3">
          {{ resume_modal.data.policies }}
        </a-descriptions-item>
        <a-descriptions-item label="Formularios" :span="3" v-if="
          formState.typeform == 1 && resume_modal.data.documents.length > 0
        ">
          <a-typography-text v-for="(d, index_d) in resume_modal.data.documents" :key="index_d">
            <p>{{ d }}</p>
          </a-typography-text>
        </a-descriptions-item>
        <a-descriptions-item label="Formularios" :span="3" v-else-if="resume_modal.data.files.length > 0">
          <a-typography-text v-for="(f, index_f) in resume_modal.data.files" :key="index_f">
            <p>{{ f }}</p>
          </a-typography-text>
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions title="Datos colaborador" class="mb-4" bordered>
        <a-descriptions-item label="Nombre" :span="3">
          {{ `${resume_modal.data.name} ${resume_modal.data.last}` }}
        </a-descriptions-item>
        <a-descriptions-item label="RUT" :span="3">
          {{ resume_modal.data.rut }}
        </a-descriptions-item>
        <a-descriptions-item label="Correo" :span="3">
          {{ resume_modal.data.email }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions :title="`Carga ${dependentIndex + 1}`" class="mb-4"
        v-for="(dependent, dependentIndex) in resume_modal.data.dependents" :key="dependent.rut" bordered>
        <a-descriptions-item label="RUT" :span="3">
          {{ dependent.rut }}
        </a-descriptions-item>
        <a-descriptions-item label="Nombre" :span="3">
          {{ dependent.firstName }} {{ dependent.firstSurname }}
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>

    <!-- Modal sucess -->
    <template v-if="show_sucess">
      <a-modal v-model:visible="show_sucess" centered footer="" :maskClosable="false">
        <a-result centered status="success" title="Ticket creado"
          sub-title="Tu ticket de alta individual ha sido creado con éxito.">
          <template #extra>
            <router-link class="justify-content-center d-flex" :to="{ name: 'AltasDetalle', params: { id: ticketId } }">
              <a-button key="console" type="primary">Ver ticket</a-button>
            </router-link>
          </template>
        </a-result>
      </a-modal>
    </template>
    <template v-if="showError">
      <a-modal v-model:visible="showError" centered footer="" :maskClosable="false">
        <a-result centered status="error" title="Error"
          sub-title="No se ha podido generar el ticket. Asegúrate de que exista un ticket de alta generado previamente.">
          <template #extra>
            <a-button type="primary" @click="() => handleOk()">Cerrar</a-button>
          </template>
        </a-result>
      </a-modal>
    </template>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { message } from 'ant-design-vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import moment from 'moment';
import emailValidator from '@/utils/emailValidator';
import { DeleteOutlined } from '@ant-design/icons-vue';
import {
  FETCH_COMPANIES,
  FETCH_GROUP_COMPANIES,
  FETCH_INSURANCE_COMPANIES,
  FETCH_INSURANCE_COMPANY_POLICY,
  FETCH_INSURANCE_COMPANIES_DOCUMENTS,
  FETCH_EMPLOYEE_DATA_BY_RUT,
  FETCH_CURRENT_EMPLOYEE_COVERAGE,
  GET_COMPANIES,
  GET_GROUP_COMPANIES,
  GET_INSURANCE_COMPANIES,
  GET_INSURANCE_COMPANY_POLICY,
  GET_LOGGED_USER,
  GET_INSURANCE_COMPANIES_DOCUMENTS,
  GET_EMPLOYEE_DATA,
  POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY,
  POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR,
  ACTION_CURRENT_EMPLOYEE_COVERAGE
} from '@/store/types';
import parseDate from '@/utils/dateFormatter';
import PageHeader from '@/components/PageHeader.vue';
import rutHelper from '@/utils/Ruts';
import notification from '@/utils/notifications';
import Rut from '@/utils/Ruts';
import EmployeePolicyAndCoverages from '@/components/altas/EmployeePolicyAndCoverages.vue';

const routes = [
  {
    path: '/altas',
    breadcrumbName: 'Altas'
  },
  {
    breadcrumbName: 'Individual'
  }
];

export default {
  name: 'AltaIndividual',
  components: {
    'upload-outlined': UploadOutlined,
    'page-header': PageHeader,
    'policy-coverages': EmployeePolicyAndCoverages,
    DeleteOutlined
  },
  setup() {
    const DATE_FORMAT = 'DD-MM-YYYY';

    const router = useRouter();

    const formRef = ref();
    const ticketId = ref('');
    const formState = reactive({
      typeform: '1',
      validity_date: '',
      firstName: '',
      secondName: '',
      firstSurname: '',
      secondSurname: '',
      rut: '',
      email: '',
      company: '',
      insurance: '',
      message:
        'Para poder recibir los beneficios de tu empresa es necesario que completes los siguientes pasos:',
      currentPolicy: [],
      policies: [],
      documents: [],
      file_list: [],
      optional_files: [],
      dependents: []
    });
    const showSucess = ref(false);

    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };

    const handleAddDependent = () => {
      formState.dependents.push({
        rut: '',
        firstName: '',
        secondName: '',
        firstSurname: '',
        secondSurname: '',
        relationship: '',
        dateOfBirth: null
      });
    };

    const handleDeleteDependent = (dependentIndex) => {
      formState.dependents = formState.dependents.filter(
        (_, idx) => idx !== dependentIndex
      );
    };

    const handleAddPolicy = () => {
      formState.currentPolicy.forEach((policyId) => {
        const policy = company_polycies.value.find((p) => p.value == policyId);
        const foundPolicy = formState.policies.find(
          (p) => p.policyId == policyId
        );
        if (!foundPolicy) {
          const newPolicy = {
            numberPolicy: policy.label,
            policyId,
            groupCompanies: [],
            groupOptions: policy.groupOptions
          };
          formState.policies = [...formState.policies, newPolicy];
        }
      });
      formState.policies = formState.policies.filter((p) =>
        formState.currentPolicy.includes(p.policyId)
      );
    };

    const handleDeletePolicy = (policyId) => {
      formState.policies = formState.policies.filter(
        (p) => p.policyId !== policyId
      );
      formState.currentPolicy = formState.currentPolicy.filter(
        (p) => p !== policyId
      );
    };

    const rutValidator = (_, value) => {
      const isValid = rutHelper.validateRut(value);
      if (value && !isValid) return Promise.reject('RUT inválido.');

      if (formState.dependents.filter((d) => d.rut === value).length > 1)
        return Promise.reject('RUT repetido.');

      return Promise.resolve();
    };

    const employeeId = ref('');

    const inputs_lock_state = reactive([
      {
        id: 1,
        disabled: false,
        loading: false,
        name: 'company'
      },
      {
        id: 2,
        disabled: true,
        loading: false,
        name: 'group'
      },
      {
        id: 3,
        disabled: true,
        loading: false,
        name: 'insurance'
      },
      {
        id: 4,
        disabled: true,
        loading: false,
        name: 'policy'
      }
    ]);

    const inputStatesHeriarchy = (id) => {
      /* funcion para activar y desactivar inputs dependiendo de la jerarquia */
      const index = inputs_lock_state.findIndex((obj) => obj.id === id);
      let last_id;

      if (index >= 0) {
        inputs_lock_state[index].disabled = false;

        if (inputs_lock_state[index + 1] != undefined) {
          inputs_lock_state[index + 1].disabled = false;
          last_id = inputs_lock_state[index + 1].id;
        }
      }

      for (let index = last_id; index < inputs_lock_state.length; index++) {
        inputs_lock_state[index].disabled = true;
      }
    };

    // Validador de rut bien escrito
    const checkRut = async (_, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        return Promise.resolve();
      }
      return Promise.reject('RUT no válido');
    };

    // Validador de correo bien escrito
    const checkEmail = async (_, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }
      if (emailValidator(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    const checkPolicies = async () => {
      if (formState.policies.length > 0) {
        return Promise.resolve();
      }
      return Promise.reject('Añadir al menos una póliza');
    };

    // Reglas del formulario
    const form_rules = {
      validity_date: [
        {
          required: true,
          message: 'Seleccione una fecha',
          trigger: 'change',
          type: 'object'
        }
      ],
      firstName: [
        {
          required: true,
          message: 'Ingrese primer nombre',
          trigger: 'change'
        }
      ],
      firstSurname: [
        {
          required: true,
          message: 'Ingrese primer apellido',
          trigger: 'change'
        }
      ],
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ],
      email: [
        {
          required: true,
          validator: checkEmail,
          trigger: 'change'
        }
      ],
      company: [
        {
          required: true,
          message: 'Seleccione una empresa',
          trigger: 'change',
          type: 'string'
        }
      ],
      group: [
        {
          required: true,
          message: 'Seleccione un grupo',
          trigger: 'change',
          type: 'string'
        }
      ],
      insurance: [
        {
          required: true,
          message: 'Seleccione una aseguradora',
          trigger: 'change',
          type: 'string'
        }
      ],
      message: [
        {
          required: true,
          message: 'Escriba un mensaje',
          trigger: 'change',
          type: 'string'
        }
      ],
      currentPolicy: [
        {
          required: true,
          validator: checkPolicies,
          trigger: 'change'
        }
      ],
      documents: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione un documento',
          trigger: 'change'
        }
      ],
      file_list: [
        {
          type: 'array',
          required: true,
          message: 'Agrege un documento',
          trigger: 'change'
        }
      ],
      policies: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione al menos una póliza',
          trigger: 'change'
        }
      ]
    };

    // cuando se seleccione 'Envio de informacion a', dependiendo de que se escoge, dejamos el otro valor en vacio
    watch(
      () => formState.typeform,
      (newVal) => {
        // Si selecciono Colaborador, dejo vacio los archivos que se suben
        if (newVal === '1') {
          formState.file_list = [];
        } else {
          // De lo contrario los documentos selecciionables como checkbox los dejo vacio
          formState.documents = [];
        }
      }
    );

    // Presionar el boton de siguiente
    const onSubmitForm = async () => {
      try {
        const resp = await formRef.value.validate();
        openResumeModal(resp);
      } catch (error) {
        console.log('error', error);
        formRef.value.scrollToField(error.errorFields[0].name[0]);
      }
    };

    // Crear ticket de alta a compañia
    const createAlta = async () => {
      resume_modal.boton_loading = true;

      try {
        const formData = new FormData();
        formData.append('companyId', formState.company);
        formData.append('brokerId', store.getters[GET_LOGGED_USER].id);
        formData.append('insuranceCompanyId', formState.insurance);
        formData.append('startDate', moment(formState.validity_date).format());
        formData.append('firstName', formState.firstName);
        if (formState.secondName != '' && formState.secondName != null) {
          formData.append('secondName', formState.secondName);
        }
        formData.append('firstSurname', formState.firstSurname);
        if (formState.secondSurname != '' && formState.secondSurname != null) {
          formData.append('secondSurname', formState.secondSurname);
        }
        formData.append('rut', rutHelper.normalizeRut(formState.rut));
        formData.append('email', formState.email);
        formState.documents.forEach((e, i) => {
          formData.append(`filesInsurance[${i}]`, e);
        });
        formState.policies.forEach((e, i) => {
          formData.append(
            `policies[${i}]`,
            JSON.stringify({
              policyId: e.policyId,
              groupCompanies: e.groupCompanies
            })
          );
        });

        if (formState.typeform == 2) {
          formState.file_list.forEach((file) => {
            formData.append('file', file);
          });
          formState.dependents.forEach((d, i) => {
            const dateOfBirth = moment(
              d.dateOfBirth.replace(/[-*]/g, '/'),
              'DD/MM/YYYY'
            );
            formData.append(
              `dependents[${i}]`,
              JSON.stringify({
                ...d,
                rut: rutHelper.normalizeRut(d.rut),
                dateOfBirth
              })
            );
          });

          const resp = await store.dispatch(
            POST_ALTA_INDIVIDUAL_TICKET_TO_COMPANY,
            formData
          );
          ticketId.value = resp.id;
          show_sucess.value = true;
        } else {
          formData.append('message', formState.message);
          formState.optional_files.forEach((e) => {
            formData.append('file', e);
          });

          const resp = await store.dispatch(
            POST_ALTA_INDIVIDUAL_TICKET_TO_COLLABORATOR,
            formData
          );
          ticketId.value = resp.id;
          showSucess.value = true;
          show_sucess.value = true;
        }
        formRef.value.resetFields();
      } catch (error) {
        console.error(error);
        showSucess.value = false;
        const errorMsg = error.response?.data?.message;
        if (errorMsg) {
          notification('error', 'Error', error.response.data.message);
        } else {
          notification('error', 'Error', 'Error al crear ticket');
        }
      }

      resume_modal.boton_loading = false;
      closeResumeModal();
    };

    // * Subida de archivos
    const file_list = ref([]);
    const uploading = ref(false);

    // Eliminar un archivo de la lista de archivos
    const handleRemove = (file) => {
      const index = formState.file_list.indexOf(file);
      const newFileList = formState.file_list.slice();
      newFileList.splice(index, 1);
      formState.file_list = newFileList;
    };

    // Eliminar un archivo para la subida de archivos opcionales
    const handleRemoveOptional = (file) => {
      const index = formState.optional_files.indexOf(file);
      const newFileList = formState.optional_files.slice();
      newFileList.splice(index, 1);
      formState.optional_files = newFileList;
    };

    // Agregar un archivo opcional al input
    const beforeUploadOptional = (file) => {
      if (formState.optional_files.length < 5) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 5) {
          message.error('El archivo excede el peso máximo 5MB');
        } else {
          formState.optional_files = [...formState.optional_files, file];
        }
      } else {
        message.error('Máximo archivos a subir es 5');
      }
      return false;
    };

    // Metodo que se llama para subir al archivo, sirve para validar el peso del archivo
    const beforeUpload = (file) => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('El archivo excede el peso máximo 5MB');
      } else {
        formState.file_list = [...formState.file_list, file];
      }
      return false;
    };

    // Subida de archivos al backend
    const handleUpload = async (id) => {
      const formData = new FormData();
      file_list.value.forEach((file) => {
        formData.append('file', file);
      });
      formData.append('ticketId', id);
    };

    // * Obtencion de data principal
    // Vuex
    const store = useStore();

    // Obteniendo Grupo de companias
    const getGroupCompanies = async (id) => {
      inputs_lock_state[1].disabled = true;
      inputs_lock_state[1].loading = true;
      try {
        await store.dispatch(
          FETCH_GROUP_COMPANIES,
          `?company=${id}&policyStatus=Vigente`
        );
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[1].loading = false;
      inputStatesHeriarchy(1);
    };

    // Obteniendo Aseguradoras
    const getInsuranceCompanies = async (id_company) => {
      inputs_lock_state[2].disabled = true;
      inputs_lock_state[2].loading = true;
      try {
        await store.dispatch(FETCH_INSURANCE_COMPANIES, {
          id_company
        });
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[2].loading = false;
      inputStatesHeriarchy(2);
    };

    // Obteniendo documentos por compa;ia aseguradora
    const getInsuraceDocuments = async (id_insurance) => {
      inputs_lock_state[3].disabled = true;
      inputs_lock_state[3].loading = true;
      try {
        await store.dispatch(FETCH_INSURANCE_COMPANIES_DOCUMENTS, id_insurance);
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[3].loading = false;
      inputStatesHeriarchy(3);
    };

    const getPolicies = async (rut, id_company, id_insurance) => {
      inputs_lock_state[3].disabled = true;
      inputs_lock_state[3].loading = true;
      try {
        await store.dispatch(FETCH_INSURANCE_COMPANY_POLICY, {
          rut,
          id_company,
          id_insurance
        });
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
      inputs_lock_state[3].loading = false;
      inputStatesHeriarchy(3);
    };

    // Obtengo los datos de la persona por rut
    const collaborator_selects_disabled = ref(false);

    const getEmployeeDataByRut = (rut) => {
      /*
      Este tiene mas logica, al momento de obtener los datos de la persona por rut
      voy a rellenar inmediatamente los selectores que tienen la data
      */
      collaborator_selects_disabled.value = true;
      store
        .dispatch(FETCH_EMPLOYEE_DATA_BY_RUT, rutHelper.normalizeRut(rut))
        .then(() => {
          const {
            id,
            firstName,
            secondName,
            firstSurname,
            secondSurname,
            email
          } = store.getters[GET_EMPLOYEE_DATA];
          employeeId.value = id;
          formState.firstName = firstName;
          formState.secondName = secondName;
          formState.firstSurname = firstSurname;
          formState.secondSurname = secondSurname;
          formState.email = email;
        })
        .finally(() => {
          collaborator_selects_disabled.value = false;
        });
    };

    // * Reactividad de seleccion
    // Si selecciono companias
    watch(
      () => formState.company,
      (newId) => {
        formState.insurance = ''; // Selector de group a vacio

        if (newId != '') {
          getGroupCompanies(newId);
        }
      }
    );

    // Si selecciono grupo companias
    watch(
      () => formState.company,
      (newId) => {
        formState.insurance = '';

        if (newId != '') {
          getInsuranceCompanies(formState.company);
        }
      }
    );

    // Si selecciono una compañia de seguros
    const insurance_form_type = ref();
    watch(
      () => formState.insurance,
      (newId) => {
        formState.policies = [];

        if (newId != '') {
          getInsuraceDocuments(newId);
        }

        const found = insurance_companies.value.find(
          (insurance) => insurance.value === newId
        );
        if (found) {
          insurance_form_type.value = found.formType;
        }
      }
    );

    // Para la seleccion de polizas, necesito que el usuario, tenga rellenado en el formulario
    // # Rut
    // # Compañia
    // # Aseguradora

    const debounce = (cb, delay = 1000) => {
      let timeout;

      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          cb(...args);
        }, delay);
      };
    };

    const updateDebouceRut = debounce((text) => {
      getEmployeeDataByRut(text);
    }, 500);

    const valid_rut = computed(() => rutHelper.validateRut(formState.rut));

    watch(
      () => formState.rut,
      (newRut) => {
        if (newRut != '') {
          updateDebouceRut(newRut);
        }
      }
    );

    watch(
      () => [formState.rut, formState.company, formState.insurance],
      async ([newRut, newCompany, newInsurance]) => {
        if (Rut.validateRut(newRut) && newCompany != '' && newInsurance != '') {
          await getPolicies(newRut, newCompany, newInsurance);
        }
      }
    );

    // Lista de puntos a tener en cuenta cuando comencemos a llamar al endpoint de coberturas
    // [ ] Que exista un empleado encontrado en el sistema (employeeId.value)
    // [ ] Que el rut sea valido tambien
    // [ ] que el id de company exista
    // [ ] que el id de aseguradora exista
    /* 
    Entonces al momento de cualquier de estas opciones exista o cambie, se tiene que llamar al endpoint para obtener las coberturas
    pero tiene que hacerse 2 veces, tanto coberturas vigentes como no vigentes y guardar la data por separado
    por que asi voy a poder poner la cantidad de vigentes en el boton
    */

    const current_coverages = ref([]);
    const not_current_coverages = ref([]);

    const getCoveragesRefactored = (data) => {
      if (data.length > 0) {
        let indexCounter = 0;
        return data.flatMap((policy, index) =>
          policy.coverages.map((coverage) => {
            const new_coverage = {
              id: `# ${index + 1}`,
              key: indexCounter,
              policy_number: policy.numberPolicy,
              coverage: coverage.name
            };
            indexCounter += 1;
            return new_coverage;
          })
        );
      }
      return data;
    };

    watch(
      () => [
        employeeId.value,
        formState.rut,
        formState.company,
        formState.insurance,
        policy_status.value
      ],
      async ([newEmployeeId, newRut, newCompany, newInsurance]) => {
        if (
          newEmployeeId != '' &&
          newEmployeeId != undefined &&
          Rut.validateRut(newRut) &&
          newCompany != '' &&
          newInsurance != ''
        ) {
          loading_policy_coverages.value = true;
          const current = await getCurrentEmployeeCoverages({
            id: newEmployeeId,
            companyId: newCompany,
            insuranceCompanyId: newInsurance,
            policyStatus: 'Vigente',
            coverageStatus: 'ACTIVE'
          });
          current_coverages.value = getCoveragesRefactored(current);

          const not_current = await getCurrentEmployeeCoverages({
            id: newEmployeeId,
            companyId: newCompany,
            insuranceCompanyId: newInsurance,
            policyStatus: 'No Vigente',
            coverageStatus: 'INACTIVE'
          });

          not_current_coverages.value = getCoveragesRefactored(not_current);
          loading_policy_coverages.value = false;
        } else {
          store.dispatch(ACTION_CURRENT_EMPLOYEE_COVERAGE, []);
          current_coverages.value = [];
          not_current_coverages.value = [];
        }
      }
    );

    const loading_policy_coverages = ref(false);
    const getCurrentEmployeeCoverages = async (payload) => {
      try {
        const resp = await store.dispatch(
          FETCH_CURRENT_EMPLOYEE_COVERAGE,
          payload
        );
        return resp.data;
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
        loading_policy_coverages.value = false;
      }
    };

    const policy_status = ref('vigente');
    const policy_columns = [
      {
        title: 'Id póliza',
        dataIndex: 'id'
      },
      {
        title: 'N° póliza',
        dataIndex: 'policy_number'
      },
      {
        title: 'Cobertura',
        dataIndex: 'coverage'
      }
    ];

    // * Data para los inputs
    const companies = computed(() => {
      return store.getters[GET_COMPANIES].map((obj) => {
        return {
          label: obj.businessName,
          value: obj.id.toString()
        };
      });
    });

    const group_companies = computed(() => {
      return store.getters[GET_GROUP_COMPANIES].map((obj) => {
        return {
          label: obj.name,
          value: obj.id.toString()
        };
      });
    });

    const insurance_companies = computed(() => {
      return store.getters[GET_INSURANCE_COMPANIES].map((obj) => {
        return {
          label: obj.businessName,
          value: obj.id.toString(),
          formType: obj.insuranceCompanyForm
            ? obj.insuranceCompanyForm.type
            : 'PDF_FORM'
        };
      });
    });

    const insurance_documents = computed(() => {
      return store.getters[GET_INSURANCE_COMPANIES_DOCUMENTS].map((x) => {
        return {
          label: x.name,
          value: x.id
        };
      });
    });

    const company_polycies = computed(() => {
      return store.getters[GET_INSURANCE_COMPANY_POLICY].map((obj) => {
        return {
          label: `${obj.numberPolicy}`,
          value: obj.idPolicy.toString(),
          groupOptions: obj.groupCompanies.map((g) => {
            return {
              label: g.name,
              value: g.id.toString()
            };
          })
        };
      });
    });

    // * Modal de resumen
    const resume_modal = reactive({
      visibility: false,
      boton_loading: false,
      data: {
        date: '',
        insurer: '',
        company: '',
        group: '',
        policies: '',
        files: [],
        documents: [],
        rut: '',
        name: '',
        last: '',
        email: ''
      }
    });

    const openResumeModal = (data) => {
      // Obtengo la data y la trasnformo para enviarla al backend

      resume_modal.data.date = parseDate(data.validity_date).substring(0, 10);
      resume_modal.data.insurer = insurance_companies.value.find(
        (x) => x.value == data.insurance
      ).label;
      resume_modal.data.company = companies.value.find(
        (x) => x.value == data.company
      ).label;
      resume_modal.data.policies = formState.policies
        .map((p) => {
          const groups = p.groupOptions
            .filter((g) => p.groupCompanies.includes(g.value))
            .map((g) => g.label)
            .join(', ');
          return `${p.numberPolicy} (${groups})`;
        })
        .join(', ');

      if (formState.typeform == 1) {
        if (data.documents !== undefined) {
          resume_modal.data.documents = insurance_documents.value
            .map((x) => {
              return data.documents.includes(x.value) ? x.label : '';
            })
            .filter((el) => el !== '');
        } else {
          resume_modal.data.documents = [];
        }
      } else {
        resume_modal.data.files =
          data.file_list == undefined || data.file_list == []
            ? ''
            : data.file_list.map((x) => {
              return x.name;
            });
        const dependents = {};
        Object.keys(data).forEach((key) => {
          if (key.includes('dependents')) {
            const index = key.split(',')[1];
            const attr = key.split(',')[2];
            if (!dependents[index]) {
              dependents[index] = {};
            }
            dependents[index][attr] = data[key];
          }
        });
        resume_modal.data.dependents = Object.values(dependents);
      }

      resume_modal.data.rut = rutHelper.formatRut(data.rut);
      resume_modal.data.name = data.firstName;
      resume_modal.data.last = data.firstSurname;
      resume_modal.data.email = data.email;

      // Abro el modal
      resume_modal.visibility = true;
    };

    const closeResumeModal = () => {
      resume_modal.visibility = false;
    };

    // * Modal Coberturas y polizas
    const policy_coverages_modal_ref = ref();
    const openPolicyCoverage = () => {
      policy_coverages_modal_ref.value.openModal();
    };

    // * Modal success
    const show_sucess = ref(false);

    // ****** Llamada principal *****

    onMounted(async () => {
      await store.dispatch(FETCH_COMPANIES);
    });

    const showError = ref(false);

    const verTicket = () => {
      router.push({ name: 'AltasDetalle', params: { id: ticketId.value } });
    };
    const handleOk = () => {
      show_sucess.value = false;
      showError.value = false;
    };

    const relationOptions = [
      {
        label: 'Cónyuge',
        value: 'SPOUSE'
      },
      {
        label: 'Conviviente',
        value: 'COHABITANT'
      },
      {
        label: 'Hijo(a)',
        value: 'CHILD'
      },
      {
        label: 'Padre/Madre',
        value: 'PARENT'
      },
      {
        label: 'Nieto(a)',
        value: 'GRANDCHILD'
      },
      {
        label: 'Hermano(a)',
        value: 'SIBLING'
      }
    ];

    const checkDate = async (rule, value) => {
      if (!value) {
        return Promise.reject('La fecha de nacimiento es obligatoria.');
      }
      const date = moment(value.replace(/[-*]/g, '/'), 'DD/MM/YYYY', true);
      if (!date.isValid()) {
        return Promise.reject(
          'Fecha inválida. Se espera el formato DD/MM/YYYY o DD-MM-YYYY.'
        );
      }
      return Promise.resolve();
    };

    return {
      // Variables
      handleOk,
      showError,
      verTicket,
      formRef,
      showSucess,
      formState,
      form_rules,
      uploading,
      file_list,
      companies,
      group_companies,
      inputs_lock_state,
      insurance_companies,
      company_polycies,
      resume_modal,
      DATE_FORMAT,
      show_sucess,
      ticketId,
      insurance_documents,
      routes,
      collaborator_selects_disabled,
      policy_status,
      policy_columns,
      loading_policy_coverages,
      policy_coverages_modal_ref,
      employeeId,
      // Functions
      onSubmitForm,
      handleRemove,
      beforeUpload,
      handleUpload,
      parseDate,
      createAlta,
      closeResumeModal,
      formatRuts,
      handleRemoveOptional,
      beforeUploadOptional,
      getCurrentEmployeeCoverages,
      openPolicyCoverage,
      valid_rut,
      current_coverages,
      not_current_coverages,
      insurance_form_type,
      handleDeleteDependent,
      handleAddDependent,
      rutValidator,
      rutHelper,
      relationOptions,
      checkDate,
      handleAddPolicy,
      handleDeletePolicy
    };
  }
};
</script>

<template>
  <page-header
    :breadcrumRoutes="routes"
    title="Formulario de baja de cargas individual"
  />

  <a-card class="mb-2">
    <a-row>
      <a-col>
        <a-typography-title class="text-gray-8" :level="4"
          >Datos corporativos</a-typography-title
        >
      </a-col>
    </a-row>
    <a-form
      name="form"
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="rules"
    >
      <a-row :gutter="16" type="flex">
        <a-col :span="8">
          <a-form-item label="RUT titular" has-feedback name="rut">
            <a-input
              placeholder="15.342.423-6"
              :maxlength="12"
              v-model:value="formState.rut"
              @change="rutChange"
              @keyup="formatRuts"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Empresa" name="company" has-feedback>
            <a-select
              placeholder="Selecciona una empresa"
              v-model:value="formState.company"
              :options="dataCompany"
              :disabled="blockCompany"
              @change="loadInsurance"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Aseguradora" name="insurance" has-feedback>
            <a-select
              placeholder="Selecciona una aseguradora"
              v-model:value="formState.insurance"
              :options="dataInsurance"
              :disabled="blockInsurance"
              @change="loadPolicy"
            >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16" type="flex" class="mb-3">
        <a-col :span="8">
          <a-form-item label="Pólizas" name="policy" has-feedback>
            <a-select
              placeholder="Selecciona una póliza"
              v-model:value="formState.policy"
              :disabled="blockPolicy"
              :options="dataPolicy"
              mode="multiple"
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item
            label="Seleccionar fecha afecto"
            has-feedback
            name="affectDate"
          >
            <a-date-picker
              :format="dateFormat"
              placeholder="DD-MM-AAAA"
              :style="{ width: '100%' }"
              v-model:value="formState.affectDate"
            />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="Cargas" name="dependents" has-feedback>
            <a-select
              placeholder="Selecciona las cargas"
              v-model:value="formState.dependents"
              :options="dataDependents"
              mode="multiple"
            >
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-card>

  <a-row type="flex" class="justify-content-end">
    <a-col>
      <a-button
        size="large"
        class="px-4"
        type="primary"
        @click="formValidation"
      >
        Siguiente
      </a-button>
    </a-col>
  </a-row>

  <a-modal
    centered
    v-model:visible="visible"
    title="Resumen baja de cargas individual"
    @ok="handleOk"
    :closable="!loading"
    :maskClosable="false"
  >
    <template #footer>
      <a-row type="flex" justify="end">
        <a-col>
          <a-space>
            <a-button @click="handleOk" :disabled="loading">Cancelar</a-button>
            <a-button
              class="bg-blue-6"
              key="submit"
              type="primary"
              @click="onSubmit"
              :disabled="blockModal()"
              :loading="loading"
              >Enviar</a-button
            >
          </a-space>
        </a-col>
      </a-row>
    </template>
    <a-typography-title class="text-gray-8 fw-regular" :level="4">
      Datos colaborador
    </a-typography-title>
    <a-typography-title class="text-gray-8 fw-regular mb-4" :level="5"
      >Revisa que los datos ingresados esten correctos.
    </a-typography-title>
    <a-descriptions class="mb-4" bordered>
      <a-descriptions-item label="RUT" :span="3">
        {{ formState.rut ? formState.rut : '' }}
      </a-descriptions-item>
      <a-descriptions-item label="Empresa" :span="3">
        {{
          formState.company >= 1
            ? dataCompany.find((x) => x.value === formState.company).label
            : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Aseguradora" :span="3">
        {{
          formState.company >= 1
            ? dataInsurance.find((x) => x.value === formState.insurance).label
            : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Pólizas" :span="3">
        {{
          formState.company >= 1
            ? dataPolicy
                .filter((e) => {
                  return formState.policy.find((f) => f == e.value);
                })
                .map((e) => e.label)
                .join()
            : ''
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Cargas" :span="3">
        <ul style="margin: 0; padding: 0 0 0 8px">
          <li
            v-for="selectedDependentId in formState.dependents"
            :key="selectedDependentId"
          >
            {{
              dataDependents.find((d) => d.value == selectedDependentId)?.label
            }}
          </li>
        </ul>
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
  <template v-if="showSucess">
    <a-modal
      v-model:visible="showSucess"
      centered
      footer=""
      :maskClosable="false"
    >
      <a-result
        centered
        status="success"
        title="Ticket creado"
        :sub-title="
          createExclusionMsg ||
          'Tu ticket de baja de cargas individual ha sido creado con éxito.'
        "
      >
        <template #extra>
          <router-link
            class="justify-content-center d-flex"
            :to="{ name: 'BajaCargasDetalle', params: { id: id_ticket } }"
          >
            <a-button key="console" type="primary">Ver ticket</a-button>
          </router-link>
        </template>
      </a-result>
    </a-modal>
  </template>
  <template v-if="showError">
    <a-modal
      v-model:visible="showError"
      centered
      footer=""
      :maskClosable="false"
    >
      <a-result
        centered
        status="error"
        title="Error"
        :sub-title="
          createExclusionError ||
          'No se ha podido generar el ticket. Asegúrate de que exista un Ticket de Alta generado previamente.'
        "
      >
        <template #extra>
          <a-button type="primary" @click="() => handleOk()">Cerrar</a-button>
        </template>
      </a-result>
    </a-modal>
  </template>
</template>

<script>
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import moment from 'moment';
import { useStore } from 'vuex';
import PageHeader from '@/components/PageHeader.vue';
import {
  GET_LOGGED_USER,
  FETCH_COMPANY_BY_RUT,
  FETCH_INSURANCECOMPANY_BY_RUT,
  FETCH_POLICY_INSURANCE_COMPANY,
  FETCH_DEPENDENTS_WITH_PARAMS,
  POST_DEPENDENTS_EXCLUSION
} from '@/store/types';
import rutHelper from '@/utils/Ruts';

const routes = [
  {
    path: '/bajas-cargas',
    breadcrumbName: 'Bajas cargas'
  },
  {
    breadcrumbName: 'Formulario individual'
  }
];

export default {
  name: 'BajaIndividual',
  components: {
    'page-header': PageHeader
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      rut: '',
      company: null,
      insurance: null,
      policy: [],
      affectDate: '',
      dependents: []
    });
    const dateFormat = 'DD-MM-YYYY';
    const showError = ref(false);
    const showSucess = ref(false);
    const loading = ref(false);
    // Block Selects
    const blockCompany = ref(true);
    const blockInsurance = ref(true);
    const blockPolicy = ref(true);
    // Consumo Data
    const dataCompany = ref([]);
    const dataInsurance = ref([]);
    const dataPolicy = ref([]);
    const dataDependents = ref([]);
    const searchingRut = ref(false);

    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };

    // Validations
    const checkCompany = async (rule, value) => {
      if (!value) {
        const error_message = 'Seleccione una empresa';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };
    const checkInsurance = async (rule, value) => {
      if (!value) {
        const error_message = 'Seleccione una aseguradora';
        return Promise.reject(error_message);
      }
      return Promise.resolve();
    };

    // * RUT
    const valid_rut = ref(false);
    const checkRut = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        valid_rut.value = false;
        return Promise.reject(error_message);
      }
      if (!rutHelper.validateRut(value)) {
        valid_rut.value = false;
        return Promise.reject('RUT no válido');
      }
      const data = await getCompanyByRut(rutHelper.normalizeRut(value));
      await fetchDependents(rutHelper.normalizeRut(value));

      if (!data) {
        valid_rut.value = false;
        return Promise.reject('No se encuentra el RUT');
      }
      valid_rut.value = true;
      return Promise.resolve();
    };

    const fetchDependents = async (employeeRut) => {
      searchingRut.value = true;
      return await store
        .dispatch(FETCH_DEPENDENTS_WITH_PARAMS, {
          employeeRut: rutHelper.normalizeRut(employeeRut)
        })
        .then((dependents) => {
          dataDependents.value = dependents.map((d) => {
            return {
              value: d.id,
              label: d.rut
            };
          });
          blockCompany.value = false;
          searchingRut.value = false;
          return dependents;
        })
        .catch((error) => {
          console.error(error);
          searchingRut.value = false;
          valid_rut.value = false;
          // Valores de la empresa a vacios
          blockCompanySelect();
          // Valores de aseguradora a vacios
          blockInsuranceSelect();
          // Valores de las polizas a vacios
          blockPolicySelect();
        });
    };

    const getCompanyByRut = async (val) => {
      searchingRut.value = true;
      return await store
        .dispatch(FETCH_COMPANY_BY_RUT, rutHelper.normalizeRut(val))
        .then((response) => {
          dataCompany.value = response.data.map((a) => {
            return {
              value: a.id,
              label: a.businessName
            };
          });
          blockCompany.value = false;
          searchingRut.value = false;
          return response.data;
        })
        .catch((error) => {
          console.error(error);
          searchingRut.value = false;
          valid_rut.value = false;
          // Valores de la empresa a vacios
          blockCompanySelect();
          // Valores de aseguradora a vacios
          blockInsuranceSelect();
          // Valores de las polizas a vacios
          blockPolicySelect();
        });
    };

    // * Bloqueo de inputs

    const blockCompanySelect = () => {
      // formState.company = '';
      dataCompany.value.length = 0;
      blockCompany.value = true;
    };

    const blockInsuranceSelect = () => {
      // formState.insurance = '';
      dataInsurance.value.length = 0;
      blockInsurance.value = true;
    };

    const blockPolicySelect = () => {
      formState.policy = [];
      dataPolicy.value.length = 0;
      blockPolicy.value = true;
    };

    const rules = {
      company: [
        {
          required: true,
          validator: checkCompany,
          trigger: 'change'
        }
      ],
      insurance: [
        {
          required: true,
          validator: checkInsurance,
          trigger: 'change'
        }
      ],
      policy: [
        {
          type: 'array',
          required: true,
          message: 'Seleccione una póliza',
          trigger: 'change'
        }
      ],
      affectDate: [
        {
          required: true,
          message: 'Seleccione una fecha',
          trigger: 'change',
          type: 'object'
        }
      ],
      rut: [
        {
          required: true,
          validator: checkRut,
          trigger: 'change'
        }
      ]
    };

    const rutChange = () => {
      if (formState.company > 0) {
        formRef.value.resetFields(['company', 'insurance', 'policy']);
      }
    };

    const loadInsurance = async (value) => {
      try {
        formState.insurance = null;
        formState.policy = null;
        if (formState.insurance == null) {
          formRef.value.resetFields(['insurance', 'policy']);
          blockPolicy.value = true;
        }
        const response = await store.dispatch(FETCH_INSURANCECOMPANY_BY_RUT, {
          payload: rutHelper.normalizeRut(formState.rut),
          companyId: value
        });
        if (response.data.length == 0) {
          blockCompany.value = true;
          blockInsurance.value = true;
          blockPolicy.value = true;
          message.error(
            `El empleado ${rutHelper.normalizeRut(
              formState.rut
            )} no está vigente en la empresa`
          );
          blockInsurance.value = true;
        } else {
          dataInsurance.value = response.data.map((a) => {
            return {
              value: a.id,
              label: a.businessName
            };
          });
          blockInsurance.value = false;
        }
      } catch (error) {
        blockInsurance.value = true;
        console.log(error);
      }
    };

    const loadPolicy = async () => {
      try {
        if (formState.policy.length > 0) {
          formRef.value.resetFields(['policy']);
        }
        if (dataPolicy.value.length > 0) {
          dataPolicy.value.splice(0);
        }
        if (formState.insurance) {
          formState.policy = [];
        }
        const response = await store.dispatch(FETCH_POLICY_INSURANCE_COMPANY, {
          company: formState.company,
          insurance: formState.insurance,
          rut: rutHelper.normalizeRut(formState.rut)
        });
        if (response.data) {
          dataPolicy.value = response.data.map((a) => {
            return {
              value: a.id,
              label: `${a.insuranceCompany.businessName} - ${a.numberPolicy}`
            };
          });
          blockPolicy.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    /* modal */
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      visible.value = false;
      showError.value = false;
    };

    const blockModal = () => {
      let blocked = true;
      if (
        formState.rut.length > 1 &&
        formState.company >= 1 &&
        formState.insurance >= 1 &&
        formState.affectDate !== ''
      ) {
        blocked = false;
      }
      return blocked;
    };

    // * Crear un ticket de alta
    const id_ticket = ref('');
    const store = useStore();
    const createExclusionError = ref('');
    const createExclusionMsg = ref('');

    const onSubmit = async () => {
      try {
        loading.value = true;
        const endDate = moment(formState.affectDate).startOf('day');
        endDate.toISOString();
        const resp = await store.dispatch(POST_DEPENDENTS_EXCLUSION, {
          rut: rutHelper.normalizeRut(formState.rut),
          brokerId: store.getters[GET_LOGGED_USER].id,
          companyId: formState.company,
          endDate,
          insuranceCompanyId: formState.insurance,
          policies: formState.policy.map((e) => e),
          dependents: formState.dependents
        });
        id_ticket.value = resp.ticket.id;
        showSucess.value = true;
        const successMsg = resp.message;
        if (successMsg) {
          createExclusionMsg.value = successMsg;
        }
        formRef.value.resetFields();
      } catch (error) {
        console.log(error);
        const errMsg = error.response?.data?.message;
        if (errMsg) {
          createExclusionError.value = errMsg;
        }
        loading.value = false;
        showError.value = true;
      }
      visible.value = false;
      loading.value = false;
    };

    // * Validacion de formulario
    const formValidation = () => {
      formRef.value
        .validate()
        .then(() => {
          showModal();
        })
        .catch((error) => {
          console.log('Error', error);
        });
    };

    return {
      // Variables
      routes,

      formRef,
      formState,
      visible,
      showError,
      showSucess,
      loading,
      dateFormat,
      blockCompany,
      blockInsurance,
      blockPolicy,
      rules,
      dataCompany,
      dataInsurance,
      dataPolicy,
      id_ticket,
      // Methods
      blockModal,
      handleOk,
      showModal,
      onSubmit,
      loadInsurance,
      rutChange,
      loadPolicy,
      formValidation,
      formatRuts,
      value1: ref(),
      dataDependents,
      createExclusionError,
      createExclusionMsg
    };
  }
};
</script>

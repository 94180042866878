<template>
  <a-row class="mb-4 mt-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <div>
        <a-steps :current="2" class="stepper-client">
          <a-step title="Datos" />
          <a-step title="Cargas" />
          <a-step title="Formularios" />
        </a-steps>
      </div>
    </a-col>
  </a-row>
  <a-row class="mb-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5">
        Carga tus documentos
      </a-typography-title>
      <a-typography-text class="text-gray-8">
        Descarga los formularios, rellénalos y cárgalos.
      </a-typography-text>
    </a-col>
  </a-row>

  <a-row class="pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-form layout="vertical">
        <a-collapse
          v-if="polizaData.some((p) => p.required)"
          v-model:activeKey="activeKey"
          class="mb-2"
        >
          <a-collapse-panel
            key="1"
            header="Seguros financiados por tu empleador"
          >
            <div v-for="(a, index) of polizaData" :key="index">
              <a-row
                v-if="a.required"
                class="my-3"
                type="flex"
                justify="space-between"
              >
                <a-col>
                  <a-typography-text class="text-gray-9">
                    {{ a.name }}
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-typography-text class="text-gray-9">
                    Grupo {{ a.groupName }}
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-typography-text class="text-gray-9">
                    Póliza N° {{ a.numberPolicy }}
                  </a-typography-text>
                </a-col>
              </a-row>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <a-collapse
          v-if="polizaData.some((p) => !p.required)"
          v-model:activeKey="activeKey1"
          class="mb-2"
        >
          <a-collapse-panel key="1" header="Seguros voluntarios">
            <div v-for="(a, index) of polizaData" :key="index">
              <a-row
                v-if="!a.required"
                class="my-3"
                type="flex"
                justify="space-between"
              >
                <a-col>
                  <a-typography-text class="text-gray-9">
                    {{ a.name }}
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-typography-text class="text-gray-9">
                    Grupo {{ a.groupName }}
                  </a-typography-text>
                </a-col>
                <a-col>
                  <a-typography-text class="text-gray-9">
                    Póliza N° {{ a.numberPolicy }}
                  </a-typography-text>
                </a-col>
              </a-row>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </a-form>
      <a-card :loading="loading" v-if="loading" title="">
        <a-typography-text class="fs-6 text-gray-8">
          no hay data
        </a-typography-text>
        <div class="text-center">
          <a-typography-paragraph class="text-gray-8 mt-2 mb-0">
            Puedes descargar el formulario en el siguiente link
          </a-typography-paragraph>
          <a-typography-link class="text-blue-6" href="" target="_blank">
            Sin formulario para descargar
          </a-typography-link>
        </div>
      </a-card>
      <div v-else v-for="(a, index) of dataDocs" :key="index">
        <a-card
          :loading="loading"
          :title="a.businessName"
          head-style=" font-weight: 600; font-size: 14px; color: #59636C;"
        >
          <template v-if="fileList[a.id].length == 0" #extra>
            <ExclamationCircleOutlined class="text-red-5" />
          </template>
          <a-typography-text class="fs-6 text-gray-8">
            {{ a.tag }}
          </a-typography-text>
          <a-row type="flex" justify="space-between">
            <a-col :span="24">
              <a-upload
                }
                name="file"
                list-type="text"
                :remove="onRemove"
                :customRequest="onUpload"
                :file-list="fileList[a.id]"
                :beforeUpload="onBeforeUpload"
                :multiple="false"
              >
                <a-button
                  class="mt-4 btn-client-secondary"
                  type="primary"
                  size="large"
                  block
                  :disabled="a.loading"
                  @click="onChange(a)"
                >
                  <UploadOutlined v-if="!a.loading" class="mr-2" />
                  {{ a.loading ? 'Cargando...' : 'Subir formulario' }}
                </a-button>
              </a-upload>
            </a-col>
          </a-row>

          <div class="text-center">
            <a-typography-paragraph class="text-gray-8 mt-2 mb-0">
              Puedes descargar el formulario en el siguiente link
            </a-typography-paragraph>
            <a-typography-link
              class="text-blue-6"
              :href="a.templateUrl"
              target="_blank"
            >
              Descargar formulario PDF
            </a-typography-link>
          </div>
        </a-card>
        <br />
      </div>
    </a-col>
  </a-row>

  <a-row
    :gutter="16"
    type="flex"
    justify="center"
    class="pb-4"
    style="width: 100%"
  >
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button class="" size="large" block @click="onPrevStep()"
        >Volver atrás</a-button
      >
    </a-col>
    <a-col :xs="10" :sm="6" :lg="4">
      <a-button
        class="btn-client"
        type="primary"
        size="large"
        block
        @click="onNextStep()"
        :disabled="!isCompletedFiles"
      >
        Siguiente
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, watch, reactive, onMounted } from 'vue';
import { computed } from 'vue';
import { message } from 'ant-design-vue';
import { useStore } from 'vuex';
import {
  UploadOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue';

import {
  GET_POLIZAS,
  FETCH_TICKET_ID,
  FETCH_INSURANCE_DOCUMENTS,
  POST_FILE_INSURANCE_COMPANY
} from '@/store/types';

export default {
  components: {
    ExclamationCircleOutlined,
    UploadOutlined
  },
  name: 'InsuredForm3',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const policy = ref();
    const dataDocs = ref({});
    const store = useStore();
    const dataFile = ref([]);
    const fileList = ref([]);
    const loading = ref(false);
    const stateFile = ref('');

    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });

    info.token = route.params.token;
    const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
    info.id = decodeToken.employeeId;
    info.ticketId = decodeToken.ticketId;

    const isCompletedFiles = computed(
      () =>
        Object.values(fileList.value).filter((e) => e[0]).length ===
        Object.keys(dataDocs.value).length
    );

    // TODO: Este método debería descargar cada formulario no un estático

    onMounted(async () => {
      await store.dispatch(FETCH_TICKET_ID, info.ticketId);
    });
    const polizaData = computed(() => store.getters[GET_POLIZAS]);

    const getSelectedDocs = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          FETCH_INSURANCE_DOCUMENTS,
          info.ticketId
        );
        dataDocs.value = response.data.map((a) => {
          return {
            id: a.id,
            businessName: a.insuranceCompany.businessName,
            tag: a.name,
            insuranceCompanyId: a.insuranceCompanyId,
            templateUrl: a.templateUrl,
            loading: false
          };
        });

        for (let index = 0; index < response.data.length; index++) {
          const currentResponse = response.data[index];
          const currentResponseId = currentResponse.id;
          const currentUrl = response.data[index].ticketFileUrl;
          if (currentUrl != null) {
            fileList.value[currentResponseId] = [
              {
                uid: `fuid-${currentResponseId}`,
                name: currentResponse.name,
                url: currentUrl
              }
            ];
          } else {
            fileList.value[currentResponseId] = [];
          }
        }
        loading.value = false;
      } catch (error) {
        console.log('error', error);
        loading.value = false;
      }
    };
    // setTimeout(() => {
    getSelectedDocs();
    // }, 1000);

    const onChange = (data) => {
      dataFile.value = data;
    };
    const onUpload = async (event) => {
      const fileIndex = dataDocs.value.findIndex(
        (f) => f.id == dataFile.value.id
      );
      dataDocs.value[fileIndex].loading = true;
      try {
        const currentFileInsuranceCompany = dataFile.value;
        const formData = new FormData();
        formData.append('file', event.file);
        formData.append(
          'fileInsuranceCompanyId',
          currentFileInsuranceCompany.id
        );
        const resp = await store.dispatch(POST_FILE_INSURANCE_COMPANY, {
          id: info.ticketId,
          payload: formData
        });

        if (resp) {
          const uploadedFile = resp;
          fileList.value[uploadedFile.fileInsuranceCompanyId] = [
            {
              uid: `fuid-${uploadedFile.fileInsuranceCompanyId}`, // currentResponse.id,\
              name: uploadedFile.name
              // url: currentUrl,
            }
          ];
          message.success('Documento subido exitosamente');
          dataDocs.value[fileIndex].loading = false;
        } else {
          dataDocs.value[fileIndex].loading = false;
          fileList.value[currentFileInsuranceCompany.id] = [];
        }
      } catch (error) {
        dataDocs.value[fileIndex].loading = false;
        message.error(`Falló la subida del documento: ${error.message}`);
      }
    };
    const onBeforeUpload = (file) => {
      const isValidFile =
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-word' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml' ||
        file.type === 'application/vnd.ms-excel';
      if (!isValidFile) {
        message.error('Formato de archivo no permitido');
        return false;
      }
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        message.error('Archivo excede el peso máximo 5MB');
        return false;
      }
    };

    const onRemove = (event) => {
      const fileListKeys = Object.keys(fileList.value);
      for (let index = 0; index < fileListKeys.length; index++) {
        const currentFileListKeys = parseInt(fileListKeys[index]);
        const fileListItem = fileList.value[currentFileListKeys];
        if (fileListItem.find((u) => u.uid == event.uid)) {
          fileList.value[currentFileListKeys] = [];
          break;
        }
      }
    };

    const onNextStep = () => {
      router.push({ name: 'Auth', params: { token: route.params.token } });
    };
    const onPrevStep = () => {
      router.go(-1);
    };

    const activeKey = ref(['1']);
    watch(activeKey, () => {});

    const activeKey1 = ref(['1']);
    watch(activeKey1, () => {});

    return {
      polizaData,
      onPrevStep,
      onChange,
      activeKey,
      activeKey1,
      isCompletedFiles,
      // Variables
      policy,
      fileList,
      dataDocs,
      // Methods
      onRemove,
      onBeforeUpload,
      onUpload,
      // recieveData,
      onNextStep,
      loading,
      dataFile,
      stateFile
    };
  }
};
</script>

<style>
div.ant-upload.ant-upload-select.ant-upload-select-text {
  background-color: white;
  border: unset;
}
</style>

export const SET_M_INDIVIDUAL = 'modificaciones/SET_M_INDIVIDUAL';
export const SET_M_MASIVA = 'modificaciones/SET_M_MASIVA';
export const SET_SELECT_FORM = 'modificaciones/SET_SELECT_FORM';
export const SET_INSURANCE = 'modificaciones/SET_INSURANCE';
export const SET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY =
  'modificaciones/SET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY';

export const FETCH_M_INDIVIDUAL = 'modificaciones/FETCH_M_INDIVIDUAL';
export const FETCH_M_MASIVA = 'modificaciones/FETCH_M_MASIVA';
export const FETCH_INSURANCE = 'modificaciones/FETCH_INSURANCE';

export const TYPE_FORM = 'modificaciones/TYPE_FORM';
export const POST_MODIFICACION_MASIVA =
  'modificaciones/POST_MODIFICACION_MASIVA';
export const POST_M_INDIVIDUAL = 'modificaciones/POST_M_INDIVIDUAL';
export const POST_EXECUTE_MODIFICATION_INDIVIDUAL =
  'modificaciones/POST_EXECUTE_MODIFICATION_INDIVIDUAL';
export const CANCEL_MODIFICACION_MASIVA =
  'modificaciones/CANCEL_MODIFICACION_MASIVA';
export const CANCEL_MODIFICACION_INDIVIDUAL =
  'modificaciones/CANCEL_MODIFICACION_INDIVIDUAL';
export const EJECUTAR_MODIFICACION_MASIVA =
  'modificaciones/EJECUTAR_MODIFICACION_MASIVA';

export const GET_TYPE_FORM = 'modificaciones/GET_TYPE_FORM';
export const GET_M_INDIVIDUAL = 'modificaciones/GET_M_INDIVIDUAL';
export const GET_M_MASIVA = 'modificaciones/GET_M_MASIVA';
export const GET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY =
  'modificaciones/GET_INSURANCE_BY_EMPLOYEE_GROUP_COMPANY';

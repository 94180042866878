<template>
  <a-row class="mb-4 mt-4 pb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <div>
        <a-steps :current="0" class="stepper-client">
          <a-step title="Datos" />
          <a-step title="Cargas" />
          <a-step title="Formularios" />
        </a-steps>
      </div>
    </a-col>
  </a-row>
  <a-row class="mb-3" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5"
        >Confirma tus datos</a-typography-title
      >
      <a-typography-text class="text-gray-8"
        >Confirma que tu primer nombre, apellido paterno, rut y correo
        electrónico estén correctos, si no presiona editar para
        modificarlos</a-typography-text
      >
    </a-col>
  </a-row>
  <a-row class="mb-4" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-card
        class="bordered-client"
        body-style="padding: 0.875rem 1.313rem !important;"
      >
        <a-row type="flex" justify="space-between" align="middle">
          <a-col>
            <a-row>
              <a-typography-text class="text-gray-8">
                Nombre: {{ employee_found.fullName }}
              </a-typography-text>
            </a-row>
            <a-row>
              <a-typography-text class="text-gray-8">
                Rut:
                {{
                  employee_found.rut
                    ? rutHelper.formatRut(employee_found.rut)
                    : ''
                }}
              </a-typography-text>
            </a-row>
            <a-row>
              <a-typography-text class="text-gray-8">
                Fecha de nacimiento:
                {{
                  employee_found.birthdayDate ? employee_found.birthdayDate : ''
                }}
              </a-typography-text>
            </a-row>
            <a-row>
              <a-typography-text class="text-gray-8">
                Email: {{ employee_found.email ? employee_found.email : '' }}
              </a-typography-text>
            </a-row>
          </a-col>
          <a-col style="align-self: flex-start">
            <a-button @click="showModal">
              <template #icon>
                <edit-outlined />
              </template>
              Editar
            </a-button>
            <a-modal
              v-model:visible="visible"
              @ok="handleOk"
              @cancel="handleModalCancel"
            >
              <a-typography-title class="text-gray-8 mb-3 pb-2" :level="5"
                >Editar datos</a-typography-title
              >

              <a-form
                ref="formRef"
                name="form"
                layout="vertical"
                :model="formState"
                :rules="rules"
              >
                <a-form-item label="Primer Nombre" name="name" has-feedback>
                  <a-input
                    v-model:value="formState.name"
                    placeholder="Andrea"
                  />
                </a-form-item>
                <a-form-item
                  label="Segundo Nombre"
                  name="secondName"
                  has-feedback
                >
                  <a-input
                    v-model:value="formState.secondName"
                    placeholder="Isabel"
                  />
                </a-form-item>
                <a-form-item
                  label="Primer Apellido"
                  name="surname"
                  has-feedback
                >
                  <a-input
                    v-model:value="formState.surname"
                    placeholder="González"
                  />
                </a-form-item>
                <a-form-item
                  label="Segundo Apellido"
                  name="secondSurname"
                  has-feedback
                >
                  <a-input
                    v-model:value="formState.secondSurname"
                    placeholder="Peréz"
                  />
                </a-form-item>
                <a-form-item
                  label="Fecha de nacimiento"
                  name="birthdayDate"
                  has-feedback
                >
                  <a-date-picker
                    :style="{ width: '100%' }"
                    :format="date_format"
                    v-model:value="formState.birthdayDate"
                    placeholder="Fecha de nacimiento"
                  />
                </a-form-item>
                <a-form-item label="RUT" name="rut" has-feedback>
                  <a-input
                    v-model:value="formState.rut"
                    placeholder="17.345.345-6"
                    :maxlength="12"
                    @keyup="formatRuts"
                  />
                </a-form-item>
                <a-form-item
                  label="Correo electrónico"
                  name="email"
                  has-feedback
                >
                  <a-input
                    v-model:value="formState.email"
                    placeholder="andrea@jumpitt.com"
                  />
                </a-form-item>
              </a-form>
            </a-modal>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>

  <a-row v-if="rent_required" class="mb-1" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <div>
        <a-form
          name="form"
          layout="vertical"
          ref="form_ref"
          :model="form_state"
          :rules="rent_rules"
        >
          <div>
            <a-typography-title class="text-gray-8" :level="5"
              >Ingresa tu renta</a-typography-title
            >
            <a-typography-text class="text-gray-8"
              >Por favor, indique el monto de su renta bruta. Si no lo conoce,
              déjelo vacío. Si es necesario, obtendremos esta información de
              Recursos Humanos.</a-typography-text
            >
            <a-form-item name="total_rent" class="mt-3">
              <a-input
                v-model:value="form_state.total_rent"
                @keyup="formatIncome"
                :maxlength="25"
                style="width: 100% !important"
              />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-col>
  </a-row>

  <a-row class="mb-4" type="flex" justify="center">
    <a-col :xs="20" :sm="16" :md="12" :lg="8">
      <a-typography-title class="text-gray-8" :level="5"
        >Sube tu cédula de identidad</a-typography-title
      >
      <a-typography-text class="text-gray-8"
        >Sube tu cédula de cada lado, presionando sobre cédula anverso y luego
        cédula reverso.
      </a-typography-text>
      <a-card
        class="bordered-client mt-3"
        body-style="padding: 1.125rem 1rem!important;"
      >
        <a-row
          type="flex"
          justify="space-between"
          :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }"
        >
          <a-col :span="12">
            <a-upload
              :key="1"
              name="fileupload"
              class="avatar-uploader"
              list-type="picture-card"
              :file-list="files.dniAnv"
              :remove="onRemove"
              :customRequest="onUploadAnverso"
              :disabled="loadingAnv"
              @preview="handlePreview"
              accept=".jpg,.jpeg,.png"
              @change="handleChange"
            >
              <a-card v-if="files.dniAnv == 1" :loading="loadingAnv">
                <img :src="prevAnverso" />
                <div class="">Cédula Anversos</div>
              </a-card>
              <a-card v-if="files.dniAnv == 0" :loading="loadingAnv">
                <img :src="prevAnverso" />
                <div class="ant-upload-text fs-6">Cédula Anverso</div>
              </a-card>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-col>

          <a-col :span="12">
            <a-upload
              name="avatar"
              class="avatar-uploader"
              list-type="picture-card"
              :file-list="files.dniRev"
              :remove="onRemoveTwo"
              :customRequest="onUploadReverso"
              :disabled="loadingRev"
              @preview="handlePreview"
              accept=".jpg,.jpeg,.png"
              @change="handleChangeTwo"
            >
              <a-card v-if="files.dniRev == 1" :loading="loadingRev">
                <img :src="prevReverso" />
                <div class="ant-upload-text fs-6">Cédula Reverso</div>
              </a-card>
              <a-card v-if="files.dniRev == 0" :loading="loadingRev">
                <img :src="prevReverso" />
                <div class="ant-upload-text fs-6">Cédula Reverso</div>
              </a-card>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
  <a-row
    type="flex"
    justify="center"
    style="width: 100%"
    class="pb-4"
    :gutter="[16, { xs: 8, sm: 16, md: 24 }]"
  >
    <a-col :xs="10" :sm="8" :md="6" :lg="4">
      <a-button class="mt-4" size="large" block @click="onPrevStep()"
        >Volver atrás</a-button
      >
    </a-col>
    <a-col :xs="10" :sm="8" :md="6" :lg="4">
      <a-button
        :loading="loading"
        class="mt-4 btn-client"
        type="primary"
        size="large"
        block
        @click="onNextStep"
      >
        Siguiente
      </a-button>
    </a-col>
  </a-row>
</template>

<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });
}
import { EditOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { ref, reactive, onMounted } from 'vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import moment from 'moment';
import rutHelper from '@/utils/Ruts';
import anverso from '@/assets/images/insured_form/anverso.svg';
import reverso from '@/assets/images/insured_form/reverso.svg';
import {
  POST_CARNET,
  FETCH_CARNET,
  PATCH_INSURED_EMPLOYEE,
  FETCH_EMPLOYEE,
  FETCH_TICKET_ID,
  PUT_EMPLOYEE_COMPANY_RENT,
  FETCH_EMPLOYEE_COMPANY_RENT,
  FETCH_TICKET_INCOME
} from '@/store/types';
import notification from '@/utils/notifications';

export default {
  name: 'InsuredForm1',
  components: {
    EditOutlined
  },
  setup() {
    const company_id = ref();
    const rent_required = ref();
    const employee_id = ref();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const loadingAnv = ref(false);
    const loadingRev = ref(false);
    const imageUrl = ref('');
    const files = reactive({
      dniAnv: [],
      dniRev: []
    });
    const info = reactive({
      id: '',
      ticketId: '',
      token: ''
    });

    try {
      info.token = route.params.token;
      const decodeToken = JSON.parse(atob(info.token.split('.')[1]));
      info.id = decodeToken.employeeId;
      info.ticketId = decodeToken.ticketId;
    } catch (e) {
      message.error('Hubo un problema con el token');
    }

    const getCarnetDni = async () => {
      loading.value = true;
      loadingAnv.value = true;
      loadingRev.value = true;
      try {
        const response = await store.dispatch(FETCH_CARNET, info.ticketId);
        if (response.data) {
          files.dniAnv = response.data
            .map((e, i) => ({
              ...e,
              uid: 1 + i,
              status: response.status == 200 ? 'done' : 'error'
            }))
            .filter((e) => e.name === 'Anverso');
          files.dniRev = response.data
            .map((e, i) => ({
              ...e,
              uid: 1 + i,
              status: response.status == 200 ? 'done' : 'error'
            }))
            .filter((e) => e.name === 'Reverso');
          loading.value = false;
          loadingAnv.value = false;
          loadingRev.value = false;
        } else {
          loading.value = false;
          loadingAnv.value = false;
          loadingRev.value = false;
          files.dniAnv = [];
          files.dniRev = [];
        }
      } catch (error) {
        console.log('error', error);
      }
    };
    getCarnetDni();
    const previewImage = ref('');
    const previewVisible = ref(false);
    const uploadedVal = computed(function () {
      return store.state.uploadedCI;
    });
    const formRef = ref();
    const formState = reactive({
      name: '',
      secondName: '',
      surname: '',
      secondSurname: '',
      birthdayDate: null,
      rut: '',
      email: ''
    });

    const employee_found = reactive({
      fullName: '',
      rut: '',
      email: '',
      birthdayDate: ''
    });

    const date_format = 'DD-MM-YYYY';
    const prevAnverso = ref(anverso);
    const prevReverso = ref(reverso);
    const form_ref = ref();
    const form_state = reactive({
      total_rent: null
    });
    const rent_rules = ref({
      total_rent: [
        {
          required: true,
          message: 'Ingrese total de renta',
          type: 'number'
        }
      ]
    });

    const formatIncome = () => {
      const numericInput = form_state.total_rent
        .toString()
        .replace(/[^0-9]/g, '');
      form_state.total_rent = `$ ${numericInput}`.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        '.'
      );
    };

    const parseIncome = () => {
      return form_state.total_rent
        .replace(' ', '')
        .replace('$', '')
        .replaceAll('.', '');
    };

    const getData = async () => {
      try {
        const employee_resp = await store.dispatch(FETCH_EMPLOYEE, info.id);
        const {
          firstName,
          secondName,
          firstSurname,
          secondSurname,
          rut,
          email,
          birthdayDate,
          id,
          fullname
        } = employee_resp.data;

        employee_id.value = id;
        employee_found.fullName = fullname;
        employee_found.rut = rut;
        employee_found.email = email;
        employee_found.birthdayDate =
          birthdayDate != null
            ? moment(birthdayDate, 'YYYY-MM-DD').format('DD-MM-YYYY')
            : null;

        formState.name = firstName;
        formState.secondName = secondName;
        formState.surname = firstSurname;
        formState.secondSurname = secondSurname;
        formState.birthdayDate =
          birthdayDate != null ? moment(birthdayDate, 'YYYY-MM-DD') : null;
        formState.rut = rut;
        formState.email = email;

        const ticket_response = await store.dispatch(
          FETCH_TICKET_ID,
          info.ticketId
        );
        company_id.value = ticket_response.data.company.id;

        const employee_rent_response = await store.dispatch(
          FETCH_EMPLOYEE_COMPANY_RENT,
          { employeeId: employee_id.value, companyId: company_id.value }
        );
        if (employee_rent_response.data != null) {
          form_state.total_rent = employee_rent_response.data.incomeAmount;
          formatIncome();
        }

        const income_ticket_response = await store.dispatch(
          FETCH_TICKET_INCOME,
          info.ticketId
        );
        rent_required.value = income_ticket_response.data.isIncomeRequired;

        if (rent_required.value) {
          rent_rules.value = {
            total_rent: [
              {
                required: true,
                message: 'Ingrese total de renta',
                type: 'string',
                trigger: 'change'
              }
            ]
          };
        } else {
          rent_rules.value = {};
        }
      } catch (error) {
        notification('error', 'Error', error.response.data.message);
      }
    };

    // VALIDATIONS

    const handleCancel = () => {
      previewVisible.value = false;
    };

    const checkRut = async (rule, value) => {
      if (!value) {
        const error_message = 'Ingrese RUT';
        return Promise.reject(error_message);
      }
      if (rutHelper.validateRut(value)) {
        return Promise.resolve();
      }
      return Promise.reject('RUT no válido');
    };

    const validateEmail = (mail) => {
      return String(mail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const checkEmail = async (rule, value) => {
      if (!value) {
        return Promise.reject('Ingrese correo electrónico');
      }
      if (validateEmail(value)) {
        return Promise.resolve();
      }
      return Promise.reject('Correo inválido');
    };

    // rules
    const rules = {
      name: [
        {
          required: true,
          message: 'Ingrese primer nombre',
          type: 'string',
          trigger: 'change'
        }
      ],
      surname: [
        {
          required: true,
          message: 'Ingrese primer apellido',
          type: 'string',
          trigger: 'change'
        }
      ],
      birthdayDate: [
        {
          required: true,
          message: 'Ingrese fecha de nacimiento',
          trigger: 'change',
          type: 'object'
        }
      ],
      rut: [
        {
          validator: checkRut,
          trigger: 'change'
        }
      ],
      email: [
        {
          validator: checkEmail,
          trigger: 'change'
        }
      ]
    };
    const onRemove = (file) => {
      const index = files.dniAnv.indexOf(file);
      const newFileList = files.dniAnv.slice();
      newFileList.splice(index, 1);
      files.dniAnv = newFileList;
    };
    const onRemoveTwo = (file) => {
      const index = files.dniRev.indexOf(file);
      const newFileList = files.dniRev.slice();
      newFileList.splice(index, 1);
      files.dniRev = newFileList;
    };

    const handleChange = ({ fileList: newFileList }) => {
      if (newFileList.status !== 'uploading') {
        files.dniAnv = newFileList;
      }
      if (newFileList.status === 'done') {
        files.dniAnv = newFileList;
        message.success(`${newFileList.name} file uploaded successfully`);
      } else if (newFileList.status === 'error') {
        message.error(`${newFileList.name} file upload failed.`);
      }
    };
    const handleChangeTwo = ({ fileList: newFileList }) => {
      if (newFileList.status !== 'uploading') {
        files.dniRev = newFileList;
      }
      if (newFileList.status === 'done') {
        files.dniRev = newFileList;
        message.success(`${newFileList.name} file uploaded successfully`);
      } else if (newFileList.status === 'error') {
        message.error(`${newFileList.name} file upload failed.`);
      }
    };

    const onUploadAnverso = ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('ticketId', info.ticketId);
      formData.append('name', 'Anverso');
      store
        .dispatch(POST_CARNET, formData)
        .then((resp) => {
          onSuccess('ok', resp);
          message.success('Anverso subido exitosamente');
        })
        .catch((error) => {
          onError(error);
          message.error(error.message);
        });
    };

    const onUploadReverso = ({ onSuccess, onError, file }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('ticketId', info.ticketId);
      formData.append('name', 'Reverso');
      store
        .dispatch(POST_CARNET, formData)
        .then((resp) => {
          onSuccess('ok', resp);
          message.success('Reverso subido exitosamente');
        })
        .catch((error) => {
          onError(error);
          message.error(error.message);
        });
    };

    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleModalCancel = () => {
      formRef.value.resetFields();
    };

    const handleOk = () => {
      formRef.value.validate().then((resp) => {
        const payload = resp;
        if (resp.secondName == null || resp.secondName == '') {
          delete payload.secondName;
        }

        if (resp.secondSurname == null || resp.secondSurname == '') {
          delete payload.secondSurname;
        }

        if (resp.birthdayDate == null || resp.birthdayDate == '') {
          delete payload.birthdayDate;
        } else {
          payload.birthdayDate = moment(payload.birthdayDate).format(
            'YYYY-MM-DD'
          );
        }

        store
          .dispatch(PATCH_INSURED_EMPLOYEE, {
            id: info.id,
            payload
          })
          .then((resp) => {
            if (resp) {
              notification('success', 'Correcto', 'Editado correctamente');
              getData();
            }
          })
          .catch((error) => {
            notification('error', 'Error', error.response.data.message);
          })
          .finally(() => {
            visible.value = false;
          });
      });
    };

    const onNextStep = () => {
      const errors = [];
      if (
        employee_found.birthdayDate === null ||
        employee_found.birthdayDate === ''
      ) {
        errors.push(
          'Presiona el botón "Editar" para completar tu fecha de nacimiento.'
        );
      }

      if (files.dniAnv.length === 0) {
        errors.push('Suba el anverso de tu cédula de identidad');
      }

      if (files.dniRev.length === 0) {
        errors.push('Suba el reverso de tu cédula de identidad');
      }

      if (errors.length > 0) {
        errors.forEach((error, index) => {
          setTimeout(() => {
            notification('error', 'Error', error);
          }, index * 100);
        });
      } else {
        if (
          (rent_required.value === true) &
          (form_state.total_rent != null && form_state.total_rent != '')
        ) {
          form_ref.value.validate().then(() => {
            store
              .dispatch(PUT_EMPLOYEE_COMPANY_RENT, {
                employeeId: employee_id.value,
                payload: {
                  companyId: company_id.value,
                  incomeAmount: parseIncome(form_state.total_rent)
                }
              })
              .then(() => {
                router.push({
                  name: 'insuredform2',
                  params: { token: route.params.token }
                });
              })
              .catch((error) => {
                notification('error', 'Error', error.response.data.message);
              });
          });
        } else {
          router.push({
            name: 'insuredform2',
            params: { token: route.params.token }
          });
        }
      }
    };

    const onPrevStep = () => {
      router.go(-1);
    };

    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    const handlePreviewReverso = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      reverso.value = file.url || file.preview;
    };
    const formatRuts = () => {
      formState.rut = rutHelper.formatRut(formState.rut);
    };

    onMounted(() => {
      getData();
    });

    return {
      parseIncome,
      formatIncome,
      files,
      onRemove,
      handleChange,
      handleChangeTwo,
      handleCancel,
      previewImage,
      onRemoveTwo,
      loading,
      imageUrl,
      anverso,
      reverso,
      // Variables
      visible,
      rules,
      formRef,
      formState,
      uploadedVal,
      prevAnverso,
      prevReverso,
      employee_found,
      // Methods
      showModal,
      handleOk,
      onNextStep,
      onPrevStep,
      onUploadAnverso,
      onUploadReverso,

      handlePreview,
      handlePreviewReverso,
      previewVisible,
      loadingAnv,
      loadingRev,
      rutHelper,
      formatRuts,
      date_format,
      handleModalCancel,
      company_id,
      employee_id,
      form_ref,
      form_state,
      rent_rules,
      rent_required
    };
  }
};
</script>

<style lang="less">
.ant-upload-list-item.ant-upload-list-item-undefined.ant-upload-list-item-list-type-picture-card {
  min-width: 100% !important;
}

.ant-upload-list-picture-card.ant-upload-list-item-info {
  width: 100%;
}

.ant-upload-list-item.ant-upload-list-item-done.ant-upload-list-item-list-type-picture-card {
  width: 100%;
}
</style>

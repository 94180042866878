<template>
  <div>
    <a-card>
      <a-row type="flex" justify="space-between" align="middle">
        <a-col>
          <a-typography-title class="text-blue-10 mb-0" :level="3">
            ¡Hola {{ username }}!
          </a-typography-title>
          <a-typography-paragraph class="text-gray-8">
            Aquí encontrarás un resumen de movimientos, altas, bajas,
            modificaciones y solicitudes.
          </a-typography-paragraph>
        </a-col>
        <a-col>
          <MainFilter />
        </a-col>
      </a-row>

      <a-typography-text class="fs-xl text-gray-8">Tus colaboradores</a-typography-text>

      <a-col>
        <a-row>
          <a-col justify="center" align="middle" class="p-3 employee-redirection"
            @click="() => employeeRedirection('ACTIVE')">
            <a-row align="middle" justify="center">
              <LockOutlined class="text-blue-6 fs-xl mr-2" />
              <a-typography-title class="mb-0 text-gray-9 fw-semibold ff-urbanist" :level="2">
                {{ employees_status.active ? employees_status.active : 0 }}
              </a-typography-title>
            </a-row>
            <a-tag color="green">Vigentes</a-tag>
          </a-col>
          <a-divider type="vertical" style="height: 3.9em" />
          <a-col justify="center" align="middle" class="p-3 employee-redirection"
            @click="() => employeeRedirection('PENDING')">
            <a-row align="middle" justify="center">
              <ClockCircleOutlined class="text-blue-6 fs-xl mr-2" />
              <a-typography-title class="mb-0 text-gray-9 fw-semibold ff-urbanist" :level="2">
                {{ employees_status.pending ? employees_status.pending : 0 }}
              </a-typography-title>
            </a-row>
            <a-tag color="orange">En proceso</a-tag>
          </a-col>
        </a-row>
      </a-col>
    </a-card>

    <a-card class="mt-3">
      <a-row type="flex" justify="space-between">
        <a-col>
          <div style="display: flex">
            <a-typography-title class="text-gray-8 ff-urbanist" :level="3">
              Resumen
            </a-typography-title>
            <a-month-picker v-if="dashboard_tab === 'cobranza_facturacion'" id="rrhh_range_picker" class="ml-4"
              v-model:value="date_filter" placeholder="Seleccionar periodo" />
          </div>
        </a-col>
        <a-col v-if="false">
          <a-radio-group v-model:value="dashboard_tab">
            <a-radio-button value="movimientos">Movimientos</a-radio-button>
            <a-radio-button value="cobranza_facturacion">
              Cobranza y facturación
            </a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>

      <MovementsSection v-if="dashboard_tab === 'movimientos'" />
      <CollectionAndBillingSection v-else />
    </a-card>
  </div>
</template>

<script setup>
// Importes
import { onMounted, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';
import notification from '@/utils/notifications';
import {
  FETCH_EMPLOYEE_SUMMARY,
  FETCH_ALL_DATA_DASHBOARD,
  GET_LOGGED_USER,
  GET_EMPLOYEE_SUMMARY,
  GET_DATE_FILTER_DASHBOARD,
  SELECT_FILTER,
  SELECTED_COMPANY,
  ALL_COMPANIES,
  SAVE_DATE_FILTER_DASHBOARD
} from '@/store/types';
import MainFilter from '@/components/dashboard/SelectFilter.vue';
import MovementsSection from '@/components/dashboard/sections/rrhh/RrhhMovementsSection.vue';
import CollectionAndBillingSection from '@/components/dashboard/sections/rrhh/RrhhCollectionAndBillingSection.vue';
import { LockOutlined, ClockCircleOutlined } from '@ant-design/icons-vue';

// Variables
const store = useStore();
const username = store.getters[GET_LOGGED_USER].name;
const router = useRouter();

// Estados de los empleados
const getEmployeesByCompanyIds = (query) => {
  store
    .dispatch(FETCH_EMPLOYEE_SUMMARY, query)
    .catch((error) =>
      notification('error', 'Error', error.response.data.message)
    );
};

const employees_status = computed(() => {
  const data = store.getters[GET_EMPLOYEE_SUMMARY];
  return data;
});

// Redireccion en click de colaboradoras
const employeeRedirection = (params) => {
  const query = {};
  if (params !== undefined) query.status = params;
  if (company_ids.value != '') query.company = company_ids.value;

  router.replace({
    name: 'Employees',
    query
  });
};

// Data del dashboard
const getDashboardData = (query) => {
  store
    .dispatch(FETCH_ALL_DATA_DASHBOARD, query)
    .catch((error) =>
      notification('error', 'Error', error.response.data.message)
    );
};

// Compañias seleccionadas
const company_ids = computed(() => {
  const selected_holding = store.getters[SELECT_FILTER].map((value) =>
    parseInt(value)
  );
  const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
    parseInt(value)
  );
  const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
    parseInt(value)
  );
  let company_ids = '';

  if (selected_holding.length > 0) {
    if (selected_comp.length > 0) {
      company_ids = selected_comp.join(',');
    }
    company_ids = all_companies.join(',');
  }

  if (selected_comp.length > 0 && selected_comp.length < all_companies.length) {
    company_ids = selected_comp.join(',');
  }

  return company_ids;
});

// Selector de tabs
const dashboard_tab = ref('movimientos');

// * Filtro de fechas
const date_filter = computed({
  get() {
    return store.getters[GET_DATE_FILTER_DASHBOARD];
  },
  set(value) {
    store.dispatch(SAVE_DATE_FILTER_DASHBOARD, value);
  }
});

// Obtencion de data principal
const companies_selected = computed(() => {
  const selected_holding = store.getters[SELECT_FILTER].map((value) =>
    parseInt(value)
  );
  const selected_comp = store.getters[SELECTED_COMPANY].map((value) =>
    parseInt(value)
  );
  const all_companies = store.getters[ALL_COMPANIES].map(({ value }) =>
    parseInt(value)
  );

  if (selected_holding.length > 0) {
    if (selected_comp.length > 0) {
      return selected_comp;
    }
    return all_companies;
  }

  if (selected_comp.length > 0 && selected_comp.length < all_companies.length) {
    return selected_comp;
  }

  return [];
});

watch(
  () => [companies_selected.value, date_filter.value],
  async (values) => {
    getMainData();
    const parameters = Object.fromEntries(new URLSearchParams(location.search));

    if (values[1] != null && values[1] != '') {
      parameters.period = moment(values[1], 'YYYY-MM').format('YYYY-MM');

      await router.replace({
        name: 'Dashboard',
        query: parameters
      });
    } else {
      delete parameters.period;
      await router.replace({
        name: 'Dashboard',
        query: parameters
      });
    }
  }
);

const getMainData = () => {
  const query_param =
    company_ids.value != '' ? `?company=${company_ids.value}` : '';
  getEmployeesByCompanyIds(query_param);
  getDashboardData(query_param);
};

onMounted(() => {
  getMainData();
});
</script>

<style>
#rrhh_range_picker span {
  margin-top: -14px;
}

.employee-redirection {
  border: 2px solid transparent;
}

.employee-redirection:hover {
  border: #1310a4 2px solid;
  border-radius: 5px;
  transition: border-color 0.5s ease;
  cursor: pointer;
}
</style>

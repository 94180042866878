<template>
  <div>
    <PageHeader title="Traspaso" :breadcrum-routes="page_header_routes">
      <template #tags>
        <a-tag :color="state_tag === 'CANCELADO'
            ? 'error'
            : state_tag === 'EJECUTADO'
              ? 'success'
              : 'warning'
          ">
          {{ state_tag }}
        </a-tag>
        <a-tag color="processing"> ID: {{ ticket_id }} </a-tag>
      </template>
      <template v-if="user_role != 'rrhh'" #buttons>
        <a-button v-if="state_tag != 'CANCELADO' && state_tag != 'EJECUTADO'" @click="openCancelModal"
          :loading="loading">
          Cancelar
        </a-button>
        <a-button class="ml-1" type="primary" @click="openExecuteModal" :loading="loading"
          v-if="state_tag === 'EN ASEGURADORA'">
          Ejecutar
        </a-button>
      </template>
    </PageHeader>

    <StepperState v-bind="stepper_data" />

    <a-card class="mt-3">
      <a-row :gutter="16">
        <a-col :span="12">
          <TicketData v-bind="ticket_details" />

          <a-card v-if="user_role != 'rrhh'" title="Casos asociados"
            bodyStyle="padding: 24px 24px 24px 24px; border: 1px solid #f0f0f0; border-top-width: 0px;"
            :bordered="false">
            <div v-if="cases.length == 0">
              <a-typography-text>No existen casos</a-typography-text>
            </div>

            <a-row type="flex" justify="space-between" v-for="c in cases" :key="c.id">
              <a-col :span="6">
                <a-typography-text>{{ c.state }}</a-typography-text>
              </a-col>
              <a-col>
                <tags :stateId="c.tag" />
              </a-col>
              <a-col>
                <a-button type="link" @click="redirectCase(c)">Ir a caso</a-button>
              </a-col>
              <a-divider class="my-2" v-if="cases.length > 1" />
            </a-row>
          </a-card>
        </a-col>
        <a-col :span="12">
          <Records v-bind="records_data" />

          <TicketData class="mt-3" title="Pólizas de origen" :data="originPolicies" />

          <TicketData title="Pólizas de destino" :data="destinyPolicies" />

          <Comments v-if="user_role != 'rrhh'" class="mt-3" :idToTicket="ticket_id" :comments="comments"
            @new_comment="getNewCommment" />
        </a-col>
      </a-row>
    </a-card>

    <CancelModal ref="transfer_modal_ref" @onCancelTransfer="cancelTransferTicker" />

    <ExecuteModal ref="execute_modal_ref" @onExecuteTransfer="executeTransferTicker" />
  </div>
</template>

<script setup>
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import notification from '@/utils/notifications';
import rutFormatter from '@/utils/Ruts';
import PageHeader from '@/components/PageHeader.vue';
import StepperState from '@/components/StepperState.vue';
import TicketData from '@/components/Tickets/TicketData.vue';
import Comments from '@/components/Tickets/Comments.vue';
import Records from '@/components/Tickets/Records.vue';
import CancelModal from '@/components/transfer/TransferCancelModal.vue';
import ExecuteModal from '@/components/transfer/TransferExecuteModal.vue';
import Tags from '@/components/Tags.vue';
import DateFormmater from '@/utils/dateFormatter';
import parseDate from '@/utils/dateFormatter';
import {
  FETCH_TICKET_ID,
  FETCH_EMPLOYEE_TICKET,
  CANCEL_TICKET_TRANSFER,
  EXECUTE_TICKET_TRANSFER,
  FETCH_CASES,
  FETCH_TICKET_COMMENTS_ID,
  FETCH_USER_BY_ID,
  FETCH_TRANSFER_INFORMATION,
  FETCH_TRANSFER_GROUPS,
  GET_LOGGED_USER
} from '@/store/types';

const store = useStore();
const route = useRoute();
const ticket_id = route.params.id;
const loading = ref(false);
const employee_data = ref({});
const broker_name = ref('');
const transfer_info = ref({});
const group_data = ref([]);

const user_role = computed(
  () => store.getters[GET_LOGGED_USER]?.roleName || 'rrhh'
);

// * Page Header
const page_header_routes = [
  {
    path: '#',
    breadcrumbName: 'Traspasos'
  }
];

const state_tag = computed(() => {
  const { states } = ticket_data.value;

  const map = {
    1: 'CREADO',
    7: 'EN CORREDORA',
    8: 'EN ASEGURADORA',
    3: 'EJECUTADO',
    4: 'CANCELADO',
    5: 'EJECUTADO' // Ver si se puede eliminar este
  };

  if (states) {
    return map[states[states.length - 1].state.id];
  }
  return 'vacio';
});

// * Stepper State
const stepper_data = computed(() => {
  const data = [
    {
      index: 1,
      title: 'Creado',
      description: ''
    },
    {
      index: 8,
      title: 'En revisión aseguradora',
      description: ''
    },
    {
      index: 3,
      title: 'Ejecutado',
      description: ''
    }
  ];
  let isCancelled = false;
  let current = 0;

  const { states } = ticket_data.value;

  const map = {
    1: 0,
    8: 1,
    3: 2,
    5: 2
  };

  if (states) {
    states.forEach((state) => {
      const { id } = state.state;
      if (id != 4) {
        data[map[id]].description = parseDate(state.createdAt);
      } else {
        isCancelled = true;
        current = states.length - 2;
      }
    });

    if (!isCancelled) {
      current = states.length - 1;
    }
  }

  return {
    data,
    isCancelled,
    current
  };
});

// * Ticket data
const ticket_details = computed(() => {
  const { firstName, firstSurname, rut, email } = employee_data.value.employee;
  const { startDate, broker, company } = ticket_data.value;

  return {
    title: 'Datos ticket',
    data: [
      {
        key: 'Broker',
        value: broker.name
      },
      {
        key: 'Inicio de vigencia',
        value: moment(startDate).format('DD/MM/YYYY')
      },
      {
        key: 'Nombre',
        value: `${firstName} ${firstSurname}`
      },
      {
        key: 'RUT',
        value: rutFormatter.formatRut(rut)
      },
      {
        key: 'Correo',
        value: email
      },
      {
        key: 'Empresa',
        value: company.businessName
      },
      {
        key: 'Grupos origen',
        value: group_data.value.oldGroupCompanies?.map((g) => g.name).join(', ')
      },
      {
        key: 'Grupos destino',
        value: group_data.value.newGroupCompanies?.map((g) => g.name).join(', ')
      }
    ],
    loadTicket: false
  };
});

// * Comentarios
const comments = ref([]);

const getNewCommment = (value) => {
  comments.value.push({
    description: parseDate(value.description),
    title: value.title,
    author: broker_name.value,
    date: value.description
  });
};

const getCommentsByTicketId = async () => {
  const response_comments = await store
    .dispatch(FETCH_TICKET_COMMENTS_ID, ticket_id)
    .catch((e) => e.response);

  for (let i = 0; i < response_comments.data.length; i++) {
    const element = response_comments.data[i];
    const brokerName = await getBroker(response_comments.data[i].brokerId);
    comments.value.push({
      description: parseDate(element.createdAt),
      title: element.message,
      author: brokerName,
      date: element.createdAt
    });
  }
};

const getBroker = async (id) => {
  const response = await store.dispatch(FETCH_USER_BY_ID, id);
  return response.data.body.name;
};

// * Historial
const records_data = computed(() => {
  const formatted_states = ticket_data.value.states.map((state) => ({
    title: state.state.tag,
    description: new Date(state.createdAt)
  }));

  const formatted_comments = comments.value.map((comment) => ({
    title: 'Nuevo comentario',
    description: new Date(comment.date)
  }));

  const records = [...formatted_states, ...formatted_comments];

  const ordered_records = records.sort((a, b) => a.description - b.description);

  return {
    title: 'Historial',
    loading: false,
    data: ordered_records.map((record) => ({
      title: record.title,
      description: DateFormmater(record.description)
    }))
  };
});

// * Polizas de origen
const originPolicies = computed(() => {
  const { originPolicies } = transfer_info.value;

  const result = originPolicies.flatMap((policy) => {
    return [
      {
        key: 'Póliza',
        value: policy.numberPolicy
      },
      {
        key: 'Coberturas',
        value: policy.coverages
          .map((coverage) => `${coverage.name} (${coverage.groupName})`)
          .join(', ')
      }
    ];
  });

  return result;
});

// * Polizas de destino
const destinyPolicies = computed(() => {
  const { destinyPolicies } = transfer_info.value;

  const result = destinyPolicies.flatMap((policy) => {
    return [
      {
        key: 'Póliza',
        value: policy.numberPolicy
      },
      {
        key: 'Coberturas',
        value: policy.coverages
          .map((coverage) => `${coverage.name} (${coverage.groupName})`)
          .join(', ')
      }
    ];
  });

  return result;
});

// Casos
const router = useRouter();
const cases = ref([]);

const getAllCasesByTicketId = async () => {
  cases.value = [];

  const response_cases = await store
    .dispatch(FETCH_CASES, ticket_id)
    .catch((e) => e.response);

  for (let i = 0; i < response_cases.data.length; i++) {
    const obj = {
      id: '',
      tag: '',
      state: '',
      typeCaseId: 0
    };

    const caso = response_cases.data[i];

    obj.id = caso.id;
    obj.tag = caso.states
      .sort((a, b) => {
        return b.id - a.id;
      })[0]
      .state.id.toString();
    obj.state = caso.typeCase.tag;
    obj.typeCaseId = caso.typeCaseId;

    cases.value.push(obj);
  }
};

const redirectCase = (value) => {
  const ticketId = value.id;

  const ticketType =
    value.state === 'Out of Time'
      ? 'fuera_de_tiempo'
      : value.state.toLowerCase();

  switch (ticketType) {
    case 'fuera de tiempo: traspaso individual':
      router.push({ name: 'DetalleFueraTiempo', params: { id: ticketId } });
      break;
  }
};

// * Formularios
const executeTransferTicker = () => {
  loading.value = true;

  store
    .dispatch(EXECUTE_TICKET_TRANSFER, {
      id: ticket_id,
      payload: {
        endDate: moment(ticket_data.value.startDate).subtract(1, 'd').format()
      }
    })
    .then(() => {
      notification(
        'success',
        'Correcto',
        'Ticket de transferencia ejecutado con éxito'
      );
      getTicketData();
    })
    .catch((err) => {
      notification('error', 'Error', err.response.data.message);
    })
    .finally(() => {
      loading.value = false;
    });
};

// Modal de cancelacion
const transfer_modal_ref = ref();
const openCancelModal = () => {
  transfer_modal_ref.value.openModal();
};

// Modal de Ejecucion
const execute_modal_ref = ref();
const openExecuteModal = () => {
  execute_modal_ref.value.openModal();
};

const cancelTransferTicker = () => {
  loading.value = true;

  store
    .dispatch(CANCEL_TICKET_TRANSFER, {
      id: ticket_id,
      payload: {
        insuranceCompanyId: ticket_data.value.insuranceCompany.id
      }
    })
    .then(() => {
      notification(
        'success',
        'Correcto',
        'Ticket de transferencia cancelado con éxito'
      );
      getTicketData();
    })
    .catch((err) => {
      notification('error', 'Error', err.response.data.message);
    })
    .finally(() => {
      loading.value = false;
    });
};

// Obtención de data principal
const ticket_data = ref({});
const getTicketData = () => {
  store
    .dispatch(FETCH_TICKET_ID, ticket_id)
    .then((resp) => {
      const { data } = resp;
      ticket_data.value = data;

      getBroker(ticket_data.value.broker.id).then((resp) => {
        broker_name.value = resp;
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

const getTransferInformation = () => {
  store
    .dispatch(FETCH_TRANSFER_INFORMATION, ticket_id)
    .then((resp) => {
      const {
        oldPolicies,
        oldCoverageGroupCompanies,
        newPolicies,
        newCoverageGroupCompanies
      } = resp.data;

      const originPolicies = oldPolicies.map((pol) => {
        return {
          ...pol,
          coverages: oldCoverageGroupCompanies
            .filter((cgc) => cgc.coverage.policyId === pol.id)
            .map((cgc) => ({
              ...cgc.coverage,
              groupName: cgc.groupCompany.name
            }))
        };
      });

      const destinyPolicies = newPolicies.map((pol) => {
        return {
          ...pol,
          coverages: newCoverageGroupCompanies
            .filter((cgc) => cgc.coverage.policyId === pol.id)
            .map((cgc) => ({
              ...cgc.coverage,
              groupName: cgc.groupCompany.name
            }))
        };
      });

      transfer_info.value = {
        originPolicies,
        destinyPolicies
      };
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    });
};

const getEmployeeData = () => {
  store
    .dispatch(FETCH_EMPLOYEE_TICKET, ticket_id)
    .then((resp) => {
      employee_data.value = resp.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getTransferGroups = () => {
  store
    .dispatch(FETCH_TRANSFER_GROUPS, ticket_id)
    .then((resp) => {
      group_data.value = resp.data;
    })
    .catch((error) => {
      notification('error', 'Error', error.response.data.message);
    });
};

onMounted(() => {
  getTicketData();
  getEmployeeData();
  getCommentsByTicketId();
  getTransferInformation();
  getTransferGroups();
  getAllCasesByTicketId();
});
</script>
